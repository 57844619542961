/**
 *
 */

import React from "react";

import "./style.scss";

/* Component starts */
const CustomButton = ({
  children,
  className = "",
  size,
  variant = "primary",
  type = "button",
  disable = false,
  disabledClass = "",
  ...rest
}) => {
  /* Component variable declaration / object destructure starts */
  const settingSizes =
    size === "s"
      ? "font_xs btn_sm px-2"
      : size === "m"
      ? "font_s btn_md px-2"
      : size === "l"
      ? "font_m btn_lg"
      : size === "xl"
      ? "font_l"
      : "";

  const settingColors =
    variant === "primary"
      ? "bg_blue font_white"
      : variant === "red"
      ? "bg_red font_white"
      : variant === "outline"
      ? "outline font_blue"
      : variant === "darkgrey"
      ? "bg_darkgrey font_white"
      : variant === "outline-red"
      ? "outline-red font_red"
      : variant === "outline-white"
      ? "outline-white font_white"
      : variant === "yellow"
      ? "font_white yellow"
      : "";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <button
      type={type}
      disabled={disable}
      className={`custom-btn ${settingSizes} ${settingColors} ${className} ${
        disable ? disabledClass : ""
      }`}
      {...rest}
    >
      {children}
    </button>
  );
};
/* Component ends */

export default CustomButton;
