/**
 * @auther Abdul Ahad <abdulahad.ss@smartdatainc.net>
 * Slice to handle tenant
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { PLATFORM } from "../../platformUtil";
import GLOBAL from "../../constants/global";
import { fetchPropertyTenants } from "./propertySlice";
import {
  fetchUnitDetailByID,
  fetchUnitHistoryById,
  fetchUnits,
} from "./unitSlice";
import {
  fetchMaintenanceById,
  fetchMaintenanceListByProperty,
} from "./maintenanceSlice";
import { fetchTenant } from "./authSlice";

const initialState = {
  tenants: [],
  application: null,
  initial_agreement: null,
  contract: null,
  contracts: [],
  contract_renewal: null,
  status: "",
  sub_status: "",
  units: [],
  instalments: [],
  unit: null,
  onboardingDetails: null,
};

export const fetchInstallmentsByApplicationId = createAsyncThunk(
  "tenant/tenant/list_installments_by_application",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      payload = { ...payload, unit_id: getState().selection.unit_id };
      // dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/tenant_info/list_installments_by_application`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const rejectApplicationPostInitialAgreement = createAsyncThunk(
  "move_out/initial_move_out/post_initial_agreement",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/move_out/initial_move_out",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const rejectTenantApplicationData = createAsyncThunk(
  "tenant/reject_application_data",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/tenant/reject_application_data/${application_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const resubmitApplicationData = createAsyncThunk(
  "tenant/resubmit_application_data",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/tenant/resubmit_application_data/${application_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenantUnitsNew = createAsyncThunk(
  "tenant/units_new",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      if (!payload) dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/tenant/units_new", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        return data.data.units;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const tenantCancelApplication = createAsyncThunk(
  "tenant/tenant_cancel_application",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL +
          "/tenant/tenant_cancel_application?application_id=" +
          payload?.application_id,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const inviteTransitionedTenant = createAsyncThunk(
  "tenant/invite_transitioned_tenant",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    payload = { ...payload };
    const { property_id } = payload;
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "contract_pdf") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/tenant/invite_tranitioned_tenant",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/tenant/invite_tranitioned_tenant",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchUnits({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const createTransitionedInvoicesByInstalments = createAsyncThunk(
  "tenant/create_invoices_by_installments",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/tenant/create_invoices_by_installments`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTransitionedTenantInvoices = createAsyncThunk(
  "tenant/tenant_info/get_list_by_application",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/tenant_info/get_list_by_application`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const tenantCancelApplicationAnytime = createAsyncThunk(
  "tenant/cancel_application_at_any_step",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant/cancel_application_at_any_step",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const searchTenant = createAsyncThunk(
  "tenant/search",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/tenant?" + payload);
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const inviteTenant = createAsyncThunk(
  "tenant/invite",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "contract_pdf") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/tenant/invite", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/tenant/invite",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchUnits({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const resendTenantMobileOtp = createAsyncThunk(
  "tenant/mobile/otp",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant/verify/mobile/resend",
        payload
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const tenantVerifyMobile = createAsyncThunk(
  "tenant/mobile/verify",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant/verify/mobile",
        payload
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const resendTenantEmailOtp = createAsyncThunk(
  "tenant/email/otp",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant/verify/email/resend",
        payload
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const tenantVerifyEmail = createAsyncThunk(
  "tenant/email/verify",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant/verify/email",
        payload,
        {
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenantUnits = createAsyncThunk(
  "tenant/units",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      if (!payload) dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + "/tenant/units",
        // "http://localhost:3000/tenant/units",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data.units;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenantApplicationById = createAsyncThunk(
  "tenant/applicationById",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + "/tenant/application?application_id=" + payload,
        // `http://localhost:3000/tenant/application?application_id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenants = createAsyncThunk(
  "tenant/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/tenant/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateTenantAutomaticPaymentStatus = createAsyncThunk(
  "tenant/automatic_payment",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant/change_automatic_payment_status",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchTenant());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateTenantApplication = createAsyncThunk(
  "tenant/update_application",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const application_id = payload.applicationId;
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key.startsWith("img_") || key === "bank_statement") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(
            key,
            typeof payload[key] === "object"
              ? JSON.stringify(payload[key])
              : payload[key]
          );
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/tenant/application/" + application_id,
      //   {
      //     method: "PATCH",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "PATCH",
        url: "/tenant/application/" + application_id,
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        if (getState().auth.userRole === GLOBAL.USER_ROLE.TENANT) {
          await dispatch(fetchTenantUnits());
        } else {
          // await dispatch(fetchPropertyTenants({ property_id }));
        }
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const payTenantApplicationFee = createAsyncThunk(
  "tenant/pay/application_fee",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/tenant/application/${application_id}/fee`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const payTenantContract = createAsyncThunk(
  "tenant/pay/tenant_contract",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/tenant/contract/payment`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateTenantApplicationStatus = createAsyncThunk(
  "tenant/update_application_status",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/tenant/application/${application_id}/status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        // await dispatch(fetchPropertyTenants({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const createTenantInitialAgreement = createAsyncThunk(
  "tenant/initial_agreement_instalments",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/tenant/initial_agreement/installments`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        // await dispatch(fetchPropertyTenants({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateTenantInitialAgreement = createAsyncThunk(
  "tenant/update_initial_agreement",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id, property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key.startsWith("img_")) {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: "signature",
              type: "image/png",
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(
            key,
            typeof payload[key] === "object"
              ? JSON.stringify(payload[key])
              : payload[key]
          );
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/tenant/initial_agreement", {
      //   method: "PATCH",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "PATCH",
        url: "/tenant/initial_agreement",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        // await dispatch(fetchTenantApplicationById(application_id));
        if (getState().auth.userRole === GLOBAL.USER_ROLE.TENANT) {
          await dispatch(fetchTenantUnits());
        } else {
          // await dispatch(fetchPropertyTenants({ property_id }));
        }
        // dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateTenantInitialAgreementStatus = createAsyncThunk(
  "tenant/update_initial_agreement_status",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/tenant/initial_agreement/status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        // await dispatch(fetchPropertyTenants({ property_id }));
        await dispatch(fetchTenantApplicationById(application_id));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateTenantContract = createAsyncThunk(
  "tenant/update_contract",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const { application_id, property_id } = payload;
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key.startsWith("img_")) {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(
            key,
            typeof payload[key] === "object"
              ? JSON.stringify(payload[key])
              : payload[key]
          );
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(API_URL + "/tenant/contract", {
      //   method: "PATCH",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "PATCH",
        url: "/tenant/contract",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        if (getState().auth.userRole === GLOBAL.USER_ROLE.TENANT) {
          await dispatch(fetchTenantUnits());
        } else {
          // await dispatch(fetchPropertyTenants({ property_id }));
        }
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const createApplicationInvoice = createAsyncThunk(
  "tenant/create_application_invoice",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant/create_application_invoice",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenantContracts = createAsyncThunk(
  "tenant/get_contracts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/tenant/contracts`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateUnitMaintenanceStatus = createAsyncThunk(
  // Done
  "tenant/unit_maintenance/status",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id, type = null, maintenance_id = null } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/tenant/unit/maintenance`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        if (type === GLOBAL.MAINTENANCE.MOVE_IN) {
          await dispatch(fetchMaintenanceListByProperty({ type }));
          await dispatch(fetchMaintenanceById({ maintenance_id }));
        } else {
          if (getState().auth.userRole === GLOBAL.USER_ROLE.TENANT) {
            await dispatch(fetchTenantUnits());
          } else {
            // await dispatch(fetchPropertyTenants());
          }
          await dispatch(fetchTenantApplicationById(application_id));
        }
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const acceptUnitKey = createAsyncThunk(
  "tenant/accept_keys",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/tenant/key/accept`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTenantUnits());
        await dispatch(fetchTenantApplicationById(application_id));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateInvoiceUnderProcess = createAsyncThunk(
  "tenant/update_invoice_process_status",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/tenant/update_invoice_process_status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const rejectUnitKey_old = createAsyncThunk(
  "tenant/reject_keys",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/tenant/key/reject`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTenantUnits());
        await dispatch(fetchTenantApplicationById(application_id));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const rejectUnitKey = createAsyncThunk(
  "tenant/reject_keys",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item, item.name);
            }
          });
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "PATCH",
        url: "/tenant/key/reject",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const pmRespondTenantKeyRejection = createAsyncThunk(
  "tenant/key/rejection/request",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      // const { data } = await axios.patch(
      //   `${API_URL}/tenant/key/rejection/request`,
      //   payload,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      //     },
      //   }
      // );

      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "PATCH",
        url: "/tenant/key/rejection/request",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type, property_id }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const completeTransitionedTenantReview = createAsyncThunk(
  "tenant/transitioned/complete_review",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/tenant/transitionedapplication/${application_id}/fee`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTenantUnits());
        await dispatch(fetchTenantApplicationById(application_id));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const submitTenantApplicationWithoutFee = createAsyncThunk(
  "tenant/application/submit_for_review",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/tenant/application/${application_id}/submit_for_review`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTenantUnits());
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateTenantInstallmentPlan = createAsyncThunk(
  "tenant/update_installment_plan",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const { application_id, property_id } = payload;
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/tenant/initial_agreement/custom/installments`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTenantApplicationById(application_id));
        // await dispatch(fetchPropertyTenants({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const transitionedTenantInitialAgreement = createAsyncThunk(
  "tenant/transitioned_initial_agreement/installments",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/tenant/transitioned_initial_agreement/installments`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const vacantT2Unit = createAsyncThunk(
  "tenant/vaccant_t2_unit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/tenant/vaccant_t2_unit",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchUnitHistoryById(payload));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const cancelTenantInvitation = createAsyncThunk(
  "tenant/cancel_invitation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        API_URL + "/tenant/cancel_invitation/" + payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const resendTenantInvitation = createAsyncThunk(
  "tenant/resend_invitation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        API_URL + "/tenant/resend_invitation/" + payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenantApplicationByIdNew = createAsyncThunk(
  "tenant/applicationByIdNew",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + "/tenant/application_new?application_id=" + payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    clearInstalments: (state, action) => {
      state.instalments = [];
    },
    clearApplication: (state, action) => {
      state.application = null;
      state.initial_agreement = null;
      state.instalments = [];
    },
    clearOnboardApplication: (state, action) => {
      state.application = null;
      state.initial_agreement = null;
      state.contract = null;
      state.status = "";
      state.sub_status = "";
      state.unit = null;
      state.onboardingDetails = null;
    },
  },
  extraReducers: {
    [transitionedTenantInitialAgreement.fulfilled]: (state, action) => {
      state.instalments = action.payload.installments;
      state.application = action.payload;
    },
    [transitionedTenantInitialAgreement.rejected]: (state, action) => {
      state.instalments = [];
    },
    [fetchTenants.fulfilled]: (state, action) => {
      state.tenants = action.payload;
    },
    [fetchTenantUnits.fulfilled]: (state, action) => {
      state.units = action.payload;
    },
    [fetchTenantUnits.rejected]: (state, action) => {
      state.units = [];
    },
    [fetchTenantApplicationById.fulfilled]: (state, action) => {
      state.application = action.payload.application;
      state.initial_agreement = action.payload.initial_agreement;
      state.contract = action.payload.contract;
      state.status = action.payload.status;
      state.sub_status = action.payload.sub_status;
      state.unit = action.payload.unit;
      state.onboardingDetails = action.payload;
    },
    [fetchTenantApplicationById.rejected]: (state, action) => {
      state.application = null;
      state.initial_agreement = null;
      state.contract = null;
      state.status = "";
      state.sub_status = "";
      state.unit = null;
      state.onboardingDetails = null;
    },
    [createTenantInitialAgreement.fulfilled]: (state, action) => {
      state.instalments = action.payload.installments;
    },
    [updateTenantInstallmentPlan.fulfilled]: (state, action) => {
      state.instalments = action.payload.installments;
    },
    [fetchTenantContracts.fulfilled]: (state, action) => {
      state.contracts = action.payload.contracts;
      state.contract_renewal = action.payload.renewal_contract;
    },
    [fetchTenantContracts.rejected]: (state, action) => {
      state.contracts = [];
      state.contract_renewal = null;
    },
  },
});

export const { clearInstalments, clearApplication, clearOnboardApplication } =
  tenantSlice.actions;

export const getTenents = (state) => state.tenant.tenants;
export const getTenantUnits = (state) => state.tenant.units;
export const getTenantApplication = (state) => state.tenant.application;
export const getTenantInitialAgreement = (state) =>
  state.tenant.initial_agreement;
export const getUnit = (state) => state.tenant.unit;
export const getTenantContract = (state) => state.tenant.contract;
export const getTenantContracts = (state) => state.tenant.contracts;
export const getTenantContractRenewal = (state) =>
  state.tenant.contract_renewal;
export const getInstalments = (state) => state.tenant.instalments;
export const getStatus = (state) => state.tenant.status;
export const getSubStatus = (state) => state.tenant.sub_status;
export const getOnboardingDetails = (state) => state.tenant.onboardingDetails;

export default tenantSlice.reducer;
