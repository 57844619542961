/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import { ExcalmatoryYellow } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const OverviewItem = (props) => {
  /* Props destructuring starts */
  const { info, isLoading, hoveredTile, onHover, onHoverOut } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="overview-item-component-wrapper">
      <div className="box">
        {/* Background label */}
        {hoveredTile === info?.label && !isLoading && (
          <div
            className={
              userTheme === "dark"
                ? "background-label"
                : "lightbackground-label"
            }
          >
            Last Year
          </div>
        )}
        <div className="heading-wrapper">
          <p className="font_s fw_6 heading-text">
            {info?.label?.toUpperCase()}
            {info?.label !== "Service Work Order" && (
              <>
                <img
                  src={ExcalmatoryYellow}
                  alt="img"
                  className={`viewImg ${
                    isLoading && hoveredTile === info?.label ? "rotating" : ""
                  }`} // Add 'rotating' class based on loading state
                  onMouseEnter={() => onHover(info.label)} // Trigger fetch on hover
                  onMouseLeave={() => onHoverOut(info.label)} // Restore original data on hover out
                />
                <p class="tooltip-text">
                  {isLoading ? "Loading..." : "Last Year"}
                </p>
              </>
            )}
          </p>
        </div>

        {info?.msg !== "Not Available" ? (
          <>
            {
              info?.data?.mainLabel !== "" && (
                <>
                  <hr
                  // style={{ border: "2px solid #939393" }}
                  />
                  <div className="main-label">
                    {info?.data?.mainLabel !== "Revenue" ? (
                      <span className="font_s fw_4  label">
                        {info?.data?.mainLabel}
                      </span>
                    ) : (
                      <>
                        <span className="font_s fw_4  label">
                          {info?.data?.mainLabel}
                          <img
                            src={ExcalmatoryYellow}
                            alt="img"
                            className="viewImg"
                          />
                          <p class="tooltip-text">Current Year</p>
                        </span>
                      </>
                    )}
                    {info?.data?.type === "price" ? (
                      <span className="font_xxl fw_6  result">
                        {info?.data?.number?.toLocaleString("en-US")}{" "}
                        {info?.data?.currency}
                      </span>
                    ) : (
                      <span className="font_xxl fw_6 result">
                        {info?.data?.number?.toLocaleString("en-US")}
                      </span>
                    )}
                  </div>
                </>
              )
              // : (
              //   <>
              //     <hr />
              //     {info?.data?.subData?.map((item) => (
              //       <div className="main-label">
              //         <span className="font_s fw_4 font_blue">{item?.text}</span>
              //         <span className="font_xxl fw_6 font_blue">{item?.num}</span>
              //       </div>
              //     ))}
              //   </>
              // )
            }

            {info?.data?.perLabel && (
              <div className="main-label">
                <span className="font_s fw_4  label">
                  {info?.data?.perLabel}
                </span>
                <span className="font_xxl fw_6  result">
                  {Math.round(info?.data?.perValue)} %
                </span>
              </div>
            )}

            <hr style={{ border: "0.10px solid #939393", opacity: "0.25" }} />

            {/* {info?.label === "Spare Parts" && (
              <>
                <div className="spare-wrapper">
                  <div>
                    <p className="font_xs fw_3">Parts</p>
                    {info?.data?.subData?.map((data) => (
                      <div className="other-detail-info" key={data?.id}>
                        <span className="font_xs fw_3 font_grey">
                          {data?.text}{" "}
                        </span>
                        <span
                          className={`font_xs fw_6  ${
                            data?.color !== undefined
                              ? data?.color
                              : "font_yellow"
                          } `}
                        >
                          {data?.num}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div>
                    <p className="font_xs fw_3">Work</p>
                    {info?.data?.subData?.map((data, idx) => (
                      <div className="other-detail-info" key={idx}>
                        <span className="font_xxs fw_3 font_grey">
                          {data?.text}{" "}
                        </span>
                        <span className="font_xxs fw_6 font_yellow">
                          {data?.num}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )} */}

            {info?.data?.subData?.length <= 4 && (
              // info?.label !== "Spare Parts" &&
              <>
                {info?.data?.subData?.map((data, idx) => (
                  <div className="other-detail-info" key={idx}>
                    <span
                      className={`font_xs fw_3 label-data ${
                        data?.text === "New Today" &&
                        data?.color === "font_green" &&
                        userTheme === "dark" &&
                        "font_green"
                        // : "font_lightgrey"
                      } `}
                    >
                      {data?.text}{" "}
                    </span>
                    {data?.type === "price" ? (
                      <span
                        className={`font_xs fw_6 ${
                          userTheme === "light" && data?.color === "font_green"
                            ? "font_white"
                            : data?.color === "font_grey"
                            ? "font_orange"
                            : data?.color !== undefined
                            ? data?.color
                            : "font_yellow"
                        } `}
                      >
                        {data?.num?.toLocaleString("en-US")} {data?.currency}
                      </span>
                    ) : (
                      <span
                        className={`font_xs fw_6 ${
                          userTheme === "light" && data?.color === "font_green"
                            ? "font_white"
                            : data?.color === "font_grey"
                            ? "font_orange"
                            : data?.color !== undefined
                            ? data?.color
                            : "font_yellow"
                        } `}
                      >
                        {data?.num?.toLocaleString("en-US")}
                      </span>
                    )}
                  </div>
                ))}
              </>
            )}

            {info?.data?.subData?.length > 4 && (
              // info?.label !== "Spare Parts" &&
              <>
                {info?.data?.subData?.map((data, idx) => (
                  <div className="other-detail-info" key={idx}>
                    <span
                      className={`font_xs fw_3 ${
                        data?.text === "New Today" &&
                        data?.color === "font_green" &&
                        userTheme === "dark"
                          ? "font_green"
                          : "label-data"
                        // : "font_lightgrey"
                      } `}
                    >
                      {data?.text}{" "}
                    </span>
                    {data?.type === "price" ? (
                      <span
                        className={`font_xs fw_6 ${
                          userTheme === "light" && data?.color === "font_green"
                            ? "font_white"
                            : data?.color === "font_grey"
                            ? "font_orange"
                            : data?.color !== undefined
                            ? data?.color
                            : "font_yellow"
                        } `}
                      >
                        {data?.num?.toLocaleString("en-US")} {data?.currency}
                      </span>
                    ) : (
                      <span
                        className={`font_xs fw_6 ${
                          userTheme === "light" && data?.color === "font_green"
                            ? "font_white"
                            : data?.color === "font_grey"
                            ? "font_orange"
                            : data?.color !== undefined
                            ? data?.color
                            : "font_yellow"
                        } `}
                      >
                        {data?.num?.toLocaleString("en-US")}
                      </span>
                    )}
                  </div>
                ))}
              </>
            )}
          </>
        ) : (
          <span className="font_l fw_6 font_blue">Comming Soon!</span>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default OverviewItem;
