/**
 *
 */

import React, { useEffect, useState, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Toastify } from "../../../../setup/utils/toast";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  addProperty,
  fetchPropertyTypes,
  getPropertyTypes,
} from "../../../../setup/store/slices/propertySlice";

import {
  addCommunity,
  fetchCommunities,
  getCommunities,
} from "../../../../setup/store/slices/communitySlice";

import {
  getCountries,
  getCities,
  getStates,
  fetchCountries,
  fetchStatesByCountryId,
  fetchCitiesByStateId,
} from "../../../../setup/store/slices/countrySlice";
import { getUser, fetchUser } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
import ConfirmationModal from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

import "./style.scss";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import { showErrorAlert } from "../../../../setup/store/slices/globalAlertSlice";
import GLOBAL from "../../../../setup/constants/global";

/* Component starts */
const AddingPropertyStep = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  // useState
  const [partOfCommunity, setPartOfCommunity] = useState(false);
  const [existingCommunity, setExistingCommunity] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [modalDesc, setModalDesc] = useState("");
  const [showWarnModal, setShowWarnModal] = useState(false);
  const [newCommPayload, setNewCommPayload] = useState({});

  // useRef
  const communityImage = useRef(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux Selector
  const propertyTypes = useSelector(getPropertyTypes);
  const communities = useSelector(getCommunities);
  const user = useSelector(getUser);
  const countries = useSelector(getCountries);
  const states = useSelector(getStates);
  const cities = useSelector(getCities);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialFormValues = {
    property_name: "",
    community_name: "",
    address_line1: "",
    address_line2: "",
    communityImg: "",
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Loading all countries
  const loadCountries = async () => {
    await dispatch(fetchCountries());
  };

  // Loading all states according to selected country
  const loadStates = async () => {
    if (selectedCountry != null) {
      setSelectedState(null);
      setSelectedCity(null);
      await dispatch(
        fetchStatesByCountryId({ country_id: selectedCountry.id })
      );
    }
  };

  // Loading all cities according to selected state
  const loadCities = async () => {
    if (selectedState != null) {
      setSelectedCity(null);
      await dispatch(fetchCitiesByStateId({ state_id: selectedState.id }));
    }
  };

  // Validations if property is a part of new Community
  const validateNewCommunity = (error, values) => {
    /* Validation for Community Name starts */
    if (values.community_name.trim() === "") {
      error.community_name = "Community Name is required";
    }
    /* Validation for Community Name ends */
    /* ******************************************************************************* */
    /* Validation for Community Address 1 starts */
    if (values.address_line1.trim() === "") {
      error.address_line1 = "Address 1 is required";
    }
    /* Validation for Community Address 1 ends */
    /* ******************************************************************************* */
    /* Validation for Community Image starts */
    if (communityImage.current === null) {
      error.communityImg = "Community Photo is required";
    }
    /* Validation for Community Image ends */
    /* ******************************************************************************* */
  };

  // Validations if property is not a part of any Community
  const validateNewProperty = (error, values) => {
    /* Validation for Property Address 1 starts */
    if (values.address_line1.trim() === "") {
      error.address_line1 = "Address 1 is required";
    }
    /* Validation for Property Address 1 ends */
    /* ******************************************************************************* */
  };

  // Form validations
  const formValidation = (values) => {
    const error = {};
    /* Validation for Property Name starts */
    if (values.property_name.trim() === "") {
      error.property_name = "Property Name is required";
    }
    /* Validation for Property Name ends */
    /* ******************************************************************************* */

    // Validations if part of a Community
    if (partOfCommunity) {
      // Validations if not part of an existing Community
      if (!existingCommunity) {
        validateNewCommunity(error, values);
      }
    }
    // Validations if not part of any Community
    else {
      validateNewProperty(error, values);
    }
    return error;
  };

  // Adding new Community
  const addNewCommunity = async (values) => {
    // Creating or collecting payload data to be sent
    let payload = {
      community_name: values.community_name,
      address_line1: values.address_line1,
      address_line2: values.address_line2,
      city: selectedCity?.name || "",
      state: selectedState?.name || "",
      country: selectedCountry?.name || "",
      image: communityImage.current,
    };

    // Backend Response. Try, Catch
    try {
      dispatch(setLoading(true));
      const result = await dispatch(addCommunity(payload));

      // Handling success respose
      switch (result.meta.requestStatus) {
        case "fulfilled":
          addPropertyWithCommunity(
            {
              ...payload,
              property_name: values.property_name,
              community_id: result.payload._id,
            },
            result.payload
          );

          break;

        case "rejected":
          dispatch(setLoading(false));
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string"
                  ? result?.payload
                  : result?.payload?.data?.error?.message,
            })
          );
          navigate("/");
          // Toastify("error", result.payload);
          break;

        default:
      }
    } catch (e) {
      dispatch(setLoading(false));
      // Handling error respose
      console.error(e.message);
    }
  };

  // Adding property with community id
  const addPropertyWithCommunity = async (values, community) => {
    // Creating or collecting payload data to be sent
    let payload = {
      property_name: values.property_name,
      community_name: values.community_name,
      address_line1: values.address_line1,
      address_line2: values.address_line2,
      city: selectedCity?.name || "",
      state: selectedState?.name || "",
      country: selectedCountry?.name || "",
      community_id: values.community_id,
      property_type_id: selectedPropertyType._id,
      is_part_community: "Yes",
    };

    // Backend response. Try, Catch
    try {
      dispatch(setLoading(true));
      const result = await dispatch(addProperty(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case "fulfilled":
          navigate(`?property_id=${result.payload._id}`);
          if (community) {
            navigate(
              `?property_id=${result.payload._id}&community_id=${community._id}`
            );
          } else {
            navigate(
              `?property_id=${result.payload._id}&community_id=${selectedCommunity._id}`
            );
          }
          dispatch(setLoading(false));
          Toastify("success", "Property added successfully!.");
          onSuccess({
            under_community: true,
            property_id: result.payload._id,
            community_id: community._id,
          });
          break;

        case "rejected":
          // dispatch(setLoading(false));
          // Toastify("error", result.payload);
          dispatch(setLoading(false));
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string"
                  ? result?.payload
                  : result?.payload?.data?.error?.message,
            })
          );
          navigate("/");
          break;

        default:
      }
    } catch (e) {
      dispatch(setLoading(false));
      // Handling error response
      console.error(e.message);
    }
  };

  // Adding property without any community
  const addIndependentProperty = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      property_name: values.property_name,
      address_line1: values.address_line1,
      address_line2: values.address_line2,
      city: selectedCity?.name || "",
      state: selectedState?.name || "",
      country: selectedCountry?.name || "",
      property_type_id: selectedPropertyType._id,
      is_part_community: "No",
    };

    // Backend response Try, Catch
    try {
      dispatch(setLoading(true));
      const result = await dispatch(addProperty(payload));

      console.log(result, "[TEST_ING]", result.status);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(setLoading(false));
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string"
                  ? result?.payload
                  : result?.payload?.data?.error?.message,
            })
          );
          navigate("/");
          break;

        case GLOBAL.FULFILLED:
          navigate(`?property_id=${result.payload._id}`);
          dispatch(setLoading(false));
          Toastify("success", "Property added successfully!.");
          onSuccess({
            under_community: false,
            property_id: result.payload._id,
          });
          break;

        default:
      }
    } catch (e) {
      dispatch(setLoading(false));
      // Handling error response
      console.log(e);
    }
  };

  // Checking and confirming same community name
  const confirmSameCommName = () => {
    setShowWarnModal(false);
    addNewCommunity(newCommPayload);
  };

  // Handling on submit
  const handleSubmit = async (values) => {
    // If part of Community
    if (partOfCommunity) {
      // If community is existed
      if (existingCommunity) {
        addPropertyWithCommunity({
          ...values,
          community_id: selectedCommunity._id,
        });
      } else {
        const isSameCommNameExist = communities.filter(
          (item) => item?.community_name === values?.community_name
        )[0];

        if (!isSameCommNameExist) {
          addNewCommunity(values);
        } else {
          setModalDesc(
            "You already have a community with same name. Are you sure you want to proceed ?"
          );
          setNewCommPayload({ ...values });
          setShowWarnModal(true);
        }
      }
    } else {
      addIndependentProperty(values);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On initial load
  useEffect(() => {
    loadCountries();
    dispatch(fetchPropertyTypes());
    dispatch(fetchCommunities());
    dispatch(fetchUser());
  }, []);

  // Setting selected country after loading countries
  useEffect(() => {
    if (countries?.length > 0 && user) {
      const defaultCountry = countries?.filter(
        (country) => country?.name === user.country
      )[0];
      setSelectedCountry(defaultCountry);
    }
  }, [countries, user]);

  // Loading states accoring to country selected
  useEffect(() => {
    loadStates();
  }, [selectedCountry]);

  // Setting selected state after loading state
  useEffect(() => {
    if (
      states?.length > 0
      // && user
    ) {
      // let defaultState = states?.filter(
      //   (state) => state.name === user?.state
      // )[0];

      // setSelectedState(defaultState || states[0]);, user
      setSelectedState(states[0]);
    }
  }, [states]);

  // Loading cities accoring to state selected
  useEffect(() => {
    loadCities();
  }, [selectedState]);

  // Setting selected city after loading cities
  useEffect(() => {
    if (
      cities?.length > 0
      //&& user
    ) {
      // let defaultCity = cities?.filter((city) => city.name === user?.city)[0];

      // setSelectedCity(defaultCity || cities[0]); , user
      setSelectedCity(cities[0]);
    }
  }, [cities]);

  // Setting selected property types on default load of all property types
  useEffect(() => {
    if (propertyTypes != null) {
      setSelectedPropertyType(propertyTypes[0]);
    }
  }, [propertyTypes]);

  // Setting selected community on default load of all communities
  useEffect(() => {
    if (communities?.length > 0) {
      setSelectedCommunity(communities[0]);
    }
  }, [communities]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-property-wrapper-main">
      <h2 className="heading text-center font_xxl mb-5">Add Property</h2>
      <Formik
        initialValues={initialFormValues}
        validate={formValidation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-wrapper bg_grey">
              {/* Input for Property Name */}
              <div className="form-group">
                <CustomTextInput
                  name="property_name"
                  label="Property Name"
                  value={values.property_name}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.property_name}
                  errors={errors.property_name}
                />
              </div>

              {/* Input for Proprty Type */}
              <div className="form-group">
                <CustomSelectInput
                  name="property_type"
                  label="Property Type"
                  handleBlur={handleBlur}
                  onChange={handleChange}
                  valueExtractor={(item) => item?.name}
                  setValue={setSelectedPropertyType}
                  options={propertyTypes}
                  value={selectedPropertyType}
                  formikState={false}
                  emptyOption={false}
                />
              </div>

              {/* Radio select if it is part of Community or not */}
              {/* {communities?.length > 0 && ( */}
              <div className="form-group form-group-full">
                <p className="font_m font_grey w_100">
                  Is this a part of community ?
                </p>
                <CustomRadioInput
                  label="Yes"
                  onSelecting={() => {
                    setPartOfCommunity(true);
                  }}
                  isSelected={partOfCommunity === true}
                />
                <CustomRadioInput
                  label="No"
                  onSelecting={() => {
                    setPartOfCommunity(false);
                  }}
                  isSelected={partOfCommunity === false}
                />
              </div>
              {/* )} */}

              {/* If not part of any Community */}
              {!partOfCommunity && (
                <>
                  {/* Input for Property Adress Line 1 */}
                  <div className="form-group">
                    <CustomTextInput
                      name="address_line1"
                      label="Address Line 1"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values.address_line1}
                      touched={touched.address_line1}
                      errors={errors.address_line1}
                    />
                  </div>

                  {/* Input for Property Adress Line 2 */}
                  <div className="form-group">
                    <CustomTextInput
                      name="address_line2"
                      label="Address Line 2"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values.address_line2}
                      touched={touched.address_line2}
                      errors={errors.address_line2}
                      required={false}
                    />
                  </div>

                  {/* Input for Property Country */}
                  <div className="form-group">
                    {/* <CustomSelectInput
                      name="country"
                      label="Country"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      setValue={setSelectedCountry}
                      options={countries}
                      value={selectedCountry}
                      formikState={false}
                      emptyOption={false}
                    /> */}
                    <CustomTextInput
                      name="country"
                      label="Country"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={selectedCountry?.name}
                      touched={touched.country}
                      errors={errors.country}
                      required={true}
                      readOnly={true}
                    />
                  </div>

                  {/* Input for Property State */}
                  <div className="form-group">
                    <CustomSelectInput
                      name="state"
                      label="State"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      setValue={setSelectedState}
                      options={states}
                      value={selectedState}
                      formikState={false}
                      emptyOption={false}
                    />
                  </div>

                  {/* Input for Property City */}
                  <div className="form-group">
                    <CustomSelectInput
                      name="city"
                      label="City"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      setValue={setSelectedCity}
                      options={cities}
                      value={selectedCity}
                      formikState={false}
                      emptyOption={false}
                    />
                  </div>
                </>
              )}

              {/* If part of a Community */}
              {partOfCommunity && (
                <>
                  {/* Radio select if it is part of existing Community or not */}
                  {communities?.length > 0 && (
                    <div className="form-group form-group-full">
                      <p className="font_m font_grey w_100">
                        Part of an existing community or New community?
                      </p>
                      {/* {communities?.length > 0 && ( */}
                      <CustomRadioInput
                        label="Existing Community"
                        onSelecting={() => {
                          setExistingCommunity(true);
                        }}
                        isSelected={existingCommunity === true}
                      />
                      {/* )} */}
                      <CustomRadioInput
                        label="New Community"
                        onSelecting={() => {
                          setExistingCommunity(false);
                        }}
                        isSelected={existingCommunity === false}
                      />
                    </div>
                  )}

                  {existingCommunity ? (
                    /* Selecting from existing Community */
                    <>
                      <div className="form-group">
                        <CustomSelectInput
                          name="community"
                          label="Select Community"
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          valueExtractor={(item) => item?.community_name}
                          optionValueExtractor={(item) => item?._id}
                          setValue={setSelectedCommunity}
                          options={communities}
                          value={selectedCommunity}
                          formikState={false}
                          emptyOption={false}
                        />
                      </div>
                    </>
                  ) : (
                    /* Adding new Community */
                    <>
                      {/* Input for Community Name */}
                      <div className="form-group">
                        <CustomTextInput
                          name="community_name"
                          label="Community Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.community_name}
                          errors={errors.community_name}
                          touched={touched.community_name}
                        />
                      </div>

                      {/* Input for Community address 1 */}
                      <div className="form-group">
                        <CustomTextInput
                          name="address_line1"
                          label="Address Line 1"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values.address_line1}
                          touched={touched.address_line1}
                          errors={errors.address_line1}
                        />
                      </div>

                      {/* Input for Community address 2 */}
                      <div className="form-group">
                        <CustomTextInput
                          name="address_line2"
                          label="Address Line 2"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values.address_line2}
                          touched={touched.address_line2}
                          errors={errors.address_line2}
                          required={false}
                        />
                      </div>

                      {/* Input for Community country */}
                      <div className="form-group">
                        {/* <CustomSelectInput
                          name="country"
                          label="Country"
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          valueExtractor={(item) => item?.name}
                          setValue={setSelectedCountry}
                          options={countries}
                          value={selectedCountry}
                          formikState={false}
                          emptyOption={false}
                          // disabled={}
                        /> */}
                        <CustomTextInput
                          name="country"
                          label="Country"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={selectedCountry?.name}
                          touched={touched.country}
                          errors={errors.country}
                          required={true}
                          readOnly={true}
                        />
                      </div>

                      {/* Input for Community state */}
                      <div className="form-group">
                        <CustomSelectInput
                          name="state"
                          label="State"
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          valueExtractor={(item) => item?.name}
                          setValue={setSelectedState}
                          options={states}
                          value={selectedState}
                          formikState={false}
                          emptyOption={false}
                        />
                      </div>

                      {/* Input for Community city */}
                      <div className="form-group">
                        <CustomSelectInput
                          name="city"
                          label="City"
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          valueExtractor={(item) => item?.name}
                          setValue={setSelectedCity}
                          options={cities}
                          value={selectedCity}
                          formikState={false}
                          emptyOption={false}
                        />
                      </div>

                      {/* Input for Community image */}
                      <div className="form-group">
                        <CustomFileInput
                          name="communityImg"
                          label="Community Photo"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          values={values.communityImg}
                          files={communityImage}
                          touched={touched.communityImg}
                          errors={errors.communityImg}
                          formikState={false}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            {/* Submit and Cancel button wrapper */}
            <div className="button-wrapper">
              <CustomButton type="submit" size="l">
                Add
              </CustomButton>
              <TextButton
                type="button"
                onClick={onCancel}
                className="fw_6 w_100 font_m"
              >
                Cancel
              </TextButton>
            </div>
          </form>
        )}
      </Formik>

      {/* Modals */}
      <ConfirmationModal
        showModal={showWarnModal}
        description={modalDesc}
        title="Warning!"
        onConfirm={confirmSameCommName}
        onClose={() => setShowWarnModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default AddingPropertyStep;
