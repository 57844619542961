import { useEffect, useState, useRef } from "react";
import { fetchSetting } from "../store/slices/settingSlice";
import { useDispatch } from "react-redux";
import GLOBAL from "../constants/global";
import { showErrorAlert } from "../store/slices/globalAlertSlice";

const useReCaptcha = (props) => {
  let siteKey = props && props.hasOwnProperty("siteKey") ? props.siteKey : "";

  let version = props && props.hasOwnProperty("version") ? props.version : "v3";

  let type = props && props.hasOwnProperty("type") ? props.type : "";

  let placeholderElement =
    props && props.hasOwnProperty("placeholderElement")
      ? props.placeholderElement
      : null;

  let action = props && props.hasOwnProperty("action") ? props.action : "";

  let setLoading =
    props && props.hasOwnProperty("setLoading") ? props.setLoading : false;


  let setError =
    props && props.hasOwnProperty("setError") ? props.setError : null;


  let explicitRender =
    props && props.hasOwnProperty("explicitRender")
      ? props.explicitRender
      : true;

  const [recaptcha_initialized, setRecaptcha_initialized] = useState(false);
  const [recaptcha_token__v2, setRecaptcha_token__v2] = useState(null);
  const [widgetID, setWidgetID] = useState(null);

  const widgetElementID = useRef();

  const generate_recaptcha_token__v2 = () => {
    if (widgetID !== null && type === "invisible") {
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute(widgetID);
      });
    } else {
      console.error("Recaptcha site key not found");
    }
  };

  const recaptcha_init_controller = (key = "", id = "") => {
    if (version === "v3") {
      if (explicitRender) {
        return;
      } else {
      }
    } else if (version === "v2") {
      if (explicitRender) {
        return recaptcha_init__v2(key, id);
      } else {
      }
    }
  };

  const reset_recaptcha_widget = () => {
    if (window.grecaptcha && window.grecaptcha.ready) {
      if (window.grecaptcha && window.grecaptcha.reset && widgetID !== null) {
        setLoading(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha.reset(widgetID);
          setLoading(false);
        });
      }
    }
  };

  const onVerification_failed = () => {
    reset_recaptcha_widget();
    setError({ show: true, message: "Verification failed!" });
  };

  const onVerified_success = () => {
    return new Promise((resolve, reject) => {
      if (window.grecaptcha) {
        // alert();
        //return;
        if (window.grecaptcha && window.grecaptcha.getResponse) {
          return resolve(
            window.grecaptcha.getResponse(widgetElementID.current)
          );
        } else {
          return reject("Token not loaded yet");
        }
      } else {
        return reject("Recaptcha not defined yet");
      }
    }).then((token) => {
      setRecaptcha_token__v2(token);
    });
  };

  const render_recaptcha_widget = (key = "", id = "") => {
    const k = siteKey !== "" ? siteKey : key;

    if (window.grecaptcha && window.grecaptcha.ready) {
      window.grecaptcha.ready(() => {
        setWidgetID((prevWidgetID) => {
          if (prevWidgetID === null && window.grecaptcha.render) {
            return window.grecaptcha.render(placeholderElement.current, {
              sitekey: k,
              callback: onVerified_success,
              size: "invisible",
              action: action,
              "expired-callback": reset_recaptcha_widget,
              "error-callback": onVerification_failed,
            });
          } else {
            return prevWidgetID;
          }
        });
      });
    }
  };

  const recaptcha_init__v2 = (key = "", id = "") => {
    const script = document.createElement("script");

    script.onload = () => {
      if (typeof window.grecaptcha === "undefined") {
        window.grecaptcha = {};
      }
      window.grecaptcha.ready = function (cb) {
        if (typeof window.grecaptcha.render === "undefined") {
          const c = "___grecaptcha_cfg";
          window[c] = window[c] || {};
          (window[c]["fns"] = window[c]["fns"] || []).push(cb);
        } else {
          cb();
        }
      };
      useReCaptcha.captchaIntialized = true;
      setRecaptcha_initialized(true);
      console.log("Recaptcha loaded");
    };

    script.async = true;
    script.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?render=explicit"
    );
    document.body.appendChild(script);

    return script;
  };

  useEffect(() => {
    if (useReCaptcha.captchaIntialized) {
      setRecaptcha_initialized(true);
    }
  }, []);

  useEffect(() => {
    let html_elm_cleanUp;
    if (!useReCaptcha.captchaIntialized && !recaptcha_initialized) {
      html_elm_cleanUp = recaptcha_init_controller(siteKey, placeholderElement);
    }

    if (
      useReCaptcha.captchaIntialized &&
      recaptcha_initialized &&
      html_elm_cleanUp
    ) {
      return () => {
        html_elm_cleanUp.remove();
      };
    }
  }, [recaptcha_initialized]);

  return {
    recaptcha_initialized,
    render_recaptcha_widget,
    recaptcha_token__v2,
    setRecaptcha_token__v2,
    generate_recaptcha_token__v2,
    widgetID,
  };
};
useReCaptcha.captchaIntialized = false;
export default useReCaptcha;
