import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
// import React, { useState } from "react";
import { formatValue } from "react-currency-input-field";

import { getTimes } from "suncalc";
import SunCalc from "suncalc";
import GLOBAL from "../constants/global";

// const [autoTheme, setAutoTheme] = React.useState("");
let autoTheme;

export const captchaInit_config_string_v2 = () => {
  const script_callback = document.createElement("script");
  const script_captcha = document.createElement("script");
  script_callback.type = "text/javascript";
  
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
    /* you can also use 'auto' behaviour
       in place of 'smooth' */
  });
};

export const customDateFormat = (
  selected_date,
  offset_timezone,
  method = "date"
) => {
  const date = new Date(selected_date);
  if (method === "intl") {
    // Define the desired time zone, e.g., 'America/New_York'
    const options = {
      timeZone: "America/New_York",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Use Intl.DateTimeFormat to format the date
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const formattedDate = formatter.format(date);

    return formattedDate;
  } else {
    // Calculate the local time and adjust for the offset
    const localTime = date.getTime();
    const localOffset = date.getTimezoneOffset() * 60000;
    const utcTime = localTime + localOffset;
    const newTime = utcTime + offset_timezone * 60000;

    // Create a new date object with the new time
    const newDate = new Date(newTime);

    // Format the new date object
    const formattedDate = newDate;

    return formattedDate;
  }
};

export const customMoment = (
  date_time,
  format = "",
  canSetOffset = false,
  offset = 0
) => {
  try {
    const specifiedMoment = moment(date_time);
    let customizedMoment;
    if (typeof canSetOffset === "boolean") {
      if (canSetOffset) {
        specifiedMoment.utcOffset(offset);
      }
      customizedMoment = specifiedMoment.format(format);
      return customizedMoment;
    } else {
      throw new Error("Third argument should be of boolean type");
    }
  } catch (e) {
    console.error(e.message);
  }
};

export const formatCurrency = (val, currency = "") => {
  return formatValue({
    value: val.toString(),
    groupSeparator: ",",
    decimalSeparator: ".",
    prefix: currency ? getSymbolFromCurrency(currency) : "",
  });
};

export const joinAddress = (data) => {
  return Object.keys(data).map((d, index) =>
    data[d] !== "" && index !== 0 ? `, ${data[d]}` : `${data[d]}`
  );
};

export const isProperNumber = (value, dot = false) => {
  if (isNaN(Number(value)) || (!dot && value[value.length - 1] === ".")) {
    return false;
  } else {
    if (value[0] === "0" && value[1] !== undefined) {
      return false;
    } else {
      return true;
    }
  }
};

export const pathNamePopped = (pathname, popCount = 1) => {
  const pathnameArr = pathname.split("/");
  for (let i = 0; i < popCount; i++) {
    pathnameArr.pop();
  }
  return pathnameArr.join("/");
};

// TO GET THE UNIQUES VALUES FOR FILTERS
export const getUniqueValues = (data, col) => {
  let colData = data.map((d) => d[col]);
  colData = colData.map((d) => (typeof d === "string" ? d : d));
  let retVal = Array.from(new Set(colData)).filter(Boolean);
  return retVal;
};

// TO GET THE Days Difference between 2 Dates
export const getDaysDifference = (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(startDate);
  const secondDate = new Date(endDate);

  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

  return diffDays;
};

export function calculateDaysBetweenDates(startDate, endDate) {
  const hoursInDay = 24;
  const hoursInInterval = {
    "0-6": 0.25,
    "6-12": 0.5,
    "12-18": 0.75,
    "18-24": 1,
  };

  // Convert dates to UTC to avoid issues with timezones
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  // Calculate the difference in milliseconds
  const timeDifference = Math.abs(endDate - startDate);

  // Convert the difference to hours
  const hoursDifference = timeDifference / (1000 * 3600);

  // Calculate the number of complete days
  const completeDays = Math.floor(hoursDifference / hoursInDay);

  // Calculate the remaining hours after complete days
  const remainingHours = hoursDifference % hoursInDay;

  // Calculate the number of days within intervals
  let daysWithinIntervals = 0;
  for (let interval in hoursInInterval) {
    const [startHour, endHour] = interval.split("-").map(Number);
    if (startHour < endHour) {
      if (
        startHour * hoursInDay < remainingHours &&
        remainingHours <= endHour * hoursInDay
      ) {
        daysWithinIntervals += hoursInInterval[interval];
      }
    } else {
      if (
        startHour * hoursInDay < remainingHours ||
        remainingHours <= endHour * hoursInDay
      ) {
        daysWithinIntervals += hoursInInterval[interval];
      }
    }
  }

  return completeDays + daysWithinIntervals;
}
