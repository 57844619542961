/**
 * This component is a Modal that is shown on success
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
import GLOBAL from "../../../../setup/constants/global";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  WarningRoundedOutlineRed,
  XGreen,
} from "../../../../setup/constants/images";
import { fetchEmergencyContacts } from "../../../../setup/store/slices/emergencyContactSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const EmergencyContactModal = (props) => {
  /* Props destructuring starts */
  const { showModal, size = "lg", onClose, unit, contactsDetail } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);
  const [data, setData] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const property_id = unit?.property_id;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchEmergencyContacts = async () => {
    // Creating or collecting payload data to be sent
    const payload = { property_id, display_main_loader: false };
    console.log("Payload", payload);
    setLoading(true);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchEmergencyContacts(payload));
      console.log("Response", result);
      setLoading(false);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setData([]);
          setResponseStatus({
            status: "error",
            title: "Error!",
            description:
              typeof result?.payload === "string" ? result?.payload : "",
          });
          break;

        case GLOBAL.FULFILLED:
          setData(result.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (showModal) {
      handleFetchEmergencyContacts();
    }
  }, [showModal]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="emergency-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <div className="icon">
            <img src={XGreen} alt="Close-Icon" onClick={onClose} />
          </div>
          <p
            className={`font_xxl fw_6 text-center ${
              userTheme !== "dark" && "font_blue"
            } font_white mt-2`}
          >
            Emergency Numbers
          </p>
        </div>
        <div className="modal-body-custom">
          <p className="font_m fw_4 font_grey text-center">Departments</p>

          <div className="contacts-wrapper">
            {loading ? (
              <p className="font_blue text-center font_l fw_5">
                Loading. Please wait...
              </p>
            ) : responseStatus && responseStatus?.status === "error" ? (
              <p className="font_m text-center fw_5 font_red">
                Something went wrong!
              </p>
            ) : data?.length > 0 ? (
              data?.map((contact) => (
                <div
                  className={`dept-contact ${
                    userTheme !== "dark" && "otherTheme"
                  }`}
                  key={contact?._id}
                >
                  <div className={`font_m fw_4 font_grey `}>
                    {contact?.department}
                  </div>
                  <div className={`font_m fw_4 font_red number`}>
                    {contact?.countrycode} {contact?.mobile}
                  </div>
                </div>
              ))
            ) : (
              <p className="font_l text-center fw_4 font_yellow">
                No Emergency Contacts Found!
              </p>
            )}
          </div>

          <div className="message">
            <img src={WarningRoundedOutlineRed} alt="Warning" />
            <p className="font_m fw_4 font_red">
              Calling the emergency numbers is your own risk and
              <br /> responsibilities
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default EmergencyContactModal;
