/**
 *
 */

import React, { useState, useEffect, useRef, useCallback } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import useReCaptcha from "../../../../setup/global_utility_hooks/useReCaptcha";
/* Import configuration ends */

/* Import redux slices component starts */
import { captchaVerification } from "../../../../setup/store/slices/authSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import { fetchSetting } from "../../../../setup/store/slices/settingSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  SuccessIconOutlinedBlue,
  ErrorRoundedSmallFilledRed,
  SuccessRoundedSmallFilledGreen,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const HumanVerification = (props) => {
  /* Props destructuring starts */
  const {
    onVerifying = null,
    onVerified = null,
    onError = null,
    className = "",
    recaptchaAction = "",
    textClassName = "",
    version = "v3",
    siteKey = "",
    explicitRender = true,
    action = "",
    type = "",
    initiateVerificationButton = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    show: false,
    message: "Something went wrong!",
  });
  const [success, setSuccess] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const placeholderElement = useRef();
  const {
    recaptcha_initialized,
    recaptcha_token__v2,
    generate_recaptcha_token__v2,
    render_recaptcha_widget,
    setRecaptcha_token__v2,
    widgetID,
  } = useReCaptcha({
    version,
    explicitRender,
    siteKey,
    placeholderElement,
    type,
    action,
    setLoading,
    setError,
  });
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleReCaptchaVerify = async () => {
    if (!recaptcha_initialized) {
      console.log("ReCaptcha not loaded yet!");
      return;
    }
    setError({ show: false, message: "Something went wrong !" });
    // Backend response. Try, Catch
    try {
      onVerifying && onVerifying();
      setLoading(true);
      if (version === "v2") {
        await generate_recaptcha_token__v2();
      } else if (version === "v3") {
      }
      // console.log("Response", generatedToken);

      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleScoreGenerate = async (token) => {
    // Creating or collecting payload data to be sent
    const payload = {
      recaptchaAction,
      token,
      recaptchaAction: action,
    };

    // Backend response. Try, Catch
    setLoading(true);
    try {
      const result = await dispatch(captchaVerification(payload));
      setLoading(false);

      console.log("Response", result);

      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setError({
            show: true,
            message:
              typeof result?.payload === "string"
                ? result?.payload
                : "Verification Failed !",
          });
          onError && onError();
          // dispatch(
          //   showErrorAlert({
          //     title: "Error!",
          //     description:
          //       typeof result?.payload === "string" ? result?.payload : "",
          //   })
          // );
          break;

        case GLOBAL.FULFILLED:
          onVerified && onVerified();
          setSuccess(true);
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description:
          //       typeof result?.payload?.message === "string"
          //         ? result?.payload?.message
          //         : "",
          //   })
          // );
          break;
      }

      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (version === "v3") {
    } else if (version === "v2" && recaptcha_token__v2) {
      handleScoreGenerate(recaptcha_token__v2);
      setRecaptcha_token__v2(null);
    }
  }, [recaptcha_token__v2]);

  useEffect(() => {
    if (recaptcha_initialized && widgetID === null) {
      render_recaptcha_widget(siteKey);
    }
  }, [recaptcha_initialized, widgetID]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (version === "v2") {
    return (
      <div id="" className={`human-verification-wrapper ${className}`}>
        {explicitRender ? (
          <>
            {success ? (
              <div className="loading_text">
                <img
                  src={SuccessRoundedSmallFilledGreen}
                  className="success-verified"
                  alt="Verified"
                />
                <p className="font_m fw_4 font-dark-green success-text">
                  Verified
                </p>
              </div>
            ) : error.show ? (
              <div className={`loading_text`}>
                <img
                  src={ErrorRoundedSmallFilledRed}
                  className="success-verified"
                  alt="Verified"
                />
                <p className="font_m fw_4 font_red">{error.message}</p>
              </div>
            ) : (
              <>
                {recaptcha_initialized ? (
                  <>
                    <div ref={placeholderElement}></div>
                    {window.grecaptcha &&
                      window.grecaptcha.ready &&
                      window.grecaptcha.execute &&
                      window.grecaptcha.render && (
                        <>
                          {!loading && (
                            <>
                              {initiateVerificationButton ? (
                                <div
                                  className="transparent-wrapper"
                                  onClick={handleReCaptchaVerify}
                                >
                                  {initiateVerificationButton}
                                </div>
                              ) : (
                                <TextButton
                                  className={`font_m fw_4 font_blue ${textClassName} text-center text_height-1`}
                                >
                                  I am not a robot
                                </TextButton>
                              )}
                            </>
                          )}
                          {loading && (
                            <div className={`text_height-1 loading_text`}>
                              <div className="loadingio-spinner-rolling-nq4q5u6dq7r">
                                <div className="ldio-x2uulkbinbj">
                                  <div></div>
                                </div>
                              </div>
                              <p className="font_m fw_4 font-dark-green">
                                Verifying, please wait...
                              </p>
                            </div>
                          )}
                          {error.show && (
                            <div className={`loading_text`}>
                              <img
                                src={ErrorRoundedSmallFilledRed}
                                className="success-verified"
                                alt="Verified"
                              />
                              <p className="font_m fw_4 font_red">
                                {error.message}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                  </>
                ) : (
                  <div className={`text_height-1 loading_text`}>
                    <div className="loadingio-spinner-rolling-nq4q5u6dq7r">
                      <div className="ldio-x2uulkbinbj">
                        <div></div>
                      </div>
                    </div>
                    <p className="font_m fw_4 font-dark-green">
                      Connecting, Please wait...
                    </p>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div
            className="g-recaptcha-automatic-wrapper"
            data-sitekey="your_site_key"
            data-theme="light"
            data-size="normal"
            data-callback="verifyCallback"
            data-error-callback="errorCallback"
            data-expired-callback="expiredCallback"
          ></div>
        )}
      </div>
    );
  } else if (version === "v3") {
    return (
      <div id="" className={`human-verification-wrapper ${className}`}>
        {recaptcha_initialized ? (
          <>
            {!loading && (
              <TextButton
                className={`font_m fw_4 font_blue ${textClassName}`}
                onClick={handleReCaptchaVerify}
              >
                I am not a robot
              </TextButton>
            )}

            {loading && (
              <p className={`font_m fw_4 font_yellow`}>
                Verifying, please wait...
              </p>
            )}

            {error.show && (
              <p className={`font_m fw_4 font_red`}>Something went wrong !</p>
            )}

            {success && <p className={`font_m fw_4 font_green`}>Verified !</p>}
          </>
        ) : (
          <p className={`font_m fw_4 font_yellow`}>
            Connecting, Please wait...
          </p>
        )}
      </div>
    );
  }
};
/* Component ends */

export default HumanVerification;
