/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  createTenantInitialAgreement,
  updateTenantApplication,
} from "../../../../setup/store/slices/tenantSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";

import {
  fetchTenantViolationsListByProperty,
  getPropertyViolationsList,
} from "../../../../setup/store/slices/violationSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import PropertyDetail from "./sub-components/PropertyDetail";
import CreateInstallmentModal from "../../Tenant/CreateInstallmentModal/CreateInstallmentModal";
import Renewal from "./sub-components/Renewal";
import RentDetails from "../../Tenant/NewTenantPMReview/sub-component/RentDetails";
import Revision from "./sub-components/Revision";
import InitialAgreement from "../../Tenant/NewTenantPMReview/sub-component/InitialAgreement";
import ESignDocModal from "../../Tenant/ESignDocModal/ESignDocModal";
import FinalAgreement from "../../Tenant/NewTenantPMReview/sub-component/FinalAgreement";
import Pagination from "../../../ui/other/Pagination/Pagination";
import ContractRenewalStatus from "./sub-components/ContractRenewalStatus";
import BasicDetails from "../../Tenant/NewTenantPMReview/sub-component/BasicDetails";
import CommercialDetails from "../../Tenant/NewTenantPMReview/sub-component/CommercialDetails";
import IDVerification from "../../Tenant/NewTenantPMReview/sub-component/IDVerification";
import HouseholdMembers from "../../Tenant/NewTenantPMReview/sub-component/HouseholdMembers";
import Vehicles from "../../Tenant/NewTenantPMReview/sub-component/Vehicles";
import Pets from "../../Tenant/NewTenantPMReview/sub-component/Pets";
import RentalHistory from "../../Tenant/NewTenantPMReview/sub-component/RentalHistory";
import CancelRenewal from "./sub-components/CancelRenewal";
import RenewalFinalStep from "./sub-components/RenewalFinalStep";
import UnitDetails from "../../Tenant/NewTenantPMReview/sub-component/UnitDetails";
import Disability from "../../Tenant/NewTenantPMReview/sub-component/Disability";
import OutStandingInvoiceList from "./sub-components/OutStandingInvoiceList";
import MaintenanceDetails from "./sub-components/Maintenances";
import Causes from "./sub-components/Causes";
import ViolationList from "./sub-components/VoilationList";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ContractRenewal = (props) => {
  /* Props destructuring starts */
  const {
    fetchContractDetails,
    property,
    result,
    sequence,
    /* ****************************************** */
    application,
    applicationID,
    tenant_id,
    sequenceNumber = [],
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showInstallment, setShowInstallment] = useState(false);
  const [applicationUnderReview, setApplicationUnderReview] = useState();
  const [openInitAgreement, setOpenInitAgreement] = useState(false);
  const [openFinlAgreement, setOpenFinlAgreement] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const propertyData = useSelector(getPropertyDetail);
  const propertyViolations = useSelector(getPropertyViolationsList);
  const isOwner = localStorage.getItem("user_role") === "Owner";
  const isPM = localStorage.getItem("user_role") === "Property Manager";
  const isAcc = localStorage.getItem("user_role") === "Accountant";
  const isClient = localStorage.getItem("user_role") === "Client";
  const isLO = localStorage.getItem("user_role") === "Lease Officer";
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = propertyViolations?.data?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  //  On declaring installment plan for renewal
  const declareInstallment = async (payload) => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        createTenantInitialAgreement({ property_id: propertyId, ...payload })
      );

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          fetchContractDetails();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On qpproving application details
  const onAction = async (data) => {
    const payload = {
      info_status_update: true,
      applicationId: applicationID,
      property_id: propertyId,
      ...data,
    };
    try {
      const result = await dispatch(updateTenantApplication(payload));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          fetchContractDetails();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;

        default:
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    dispatch(
      fetchTenantViolationsListByProperty({
        property_id: propertyId,
        tenant_id: tenant_id,
        page: currentPage,
      })
    );
  }, [currentPage, propertyId]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="contract-renewal-detail-wrapper details-wrapper container_md">
      <Causes
        causes={
          result?.causes && result?.causes?.length > 0 ? result?.causes : null
        }
      />

      <div className="my-2">
        <UnitDetails
          details={result?.application?.unit}
          index="1"
          isRenew={true}
        />
      </div>

      {sequence.map((seq) => {
        switch (seq) {
          case "property":
            return <></>;

          case "basic_details":
            return (
              <>
                <BasicDetails
                  details={result?.basic_details}
                  index={sequenceNumber.indexOf("basic_details") + 2}
                  // TODO : Pending index number generate as per application
                  // index="1"
                  isRenew={true}
                />
              </>
            );

          case "commercial_details":
            return (
              <>
                <CommercialDetails
                  details={result?.commercial_details}
                  approval={
                    (isOwner || isPM || isLO) &&
                    result.application.status ===
                      GLOBAL.TENANT_APPLICATION_STATUS.PENDING_REVIEW
                  }
                  onAction={onAction}
                  index={sequenceNumber.indexOf("commercial_details") + 2}
                  // TODO : Pending index number generate as per application
                  // index="2"
                  isRenew={true}
                />
              </>
            );

          case "ids":
            return (
              <>
                <IDVerification
                  data={result?.ids}
                  approval={
                    (isOwner || isPM || isLO) &&
                    result?.application.status ===
                      GLOBAL.TENANT_APPLICATION_STATUS.PENDING_REVIEW
                  }
                  onAction={onAction}
                  index={sequenceNumber.indexOf("ids") + 2}
                  // TODO : Pending index number generate as per application
                  // index="2"
                />
              </>
            );

          case "household_members":
            return (
              <>
                <HouseholdMembers
                  data={result?.household_members}
                  approval={
                    (isOwner || isPM || isLO) &&
                    result?.application?.status ===
                      GLOBAL.TENANT_APPLICATION_STATUS.PENDING_REVIEW
                  }
                  onAction={onAction}
                  index={sequenceNumber.indexOf("household_members") + 2}
                  // TODO : Pending index number generate as per application
                  // index="3"
                />
              </>
            );

          case "disability":
            return (
              <>
                <Disability
                  data={result?.disability}
                  index={sequenceNumber.indexOf("disability") + 2}
                  // index={sequenceNumber.indexOf("disability") + 2}
                />
              </>
            );

          case "vehicles":
            return (
              <>
                <Vehicles
                  data={result?.vehicles}
                  approval={
                    (isOwner || isPM || isLO) &&
                    result?.application?.status ===
                      GLOBAL.TENANT_APPLICATION_STATUS.PENDING_REVIEW
                  }
                  onAction={onAction}
                  index={sequenceNumber.indexOf("vehicles") + 2}
                  // TODO : Pending index number generate as per application
                  // index="4"
                />
              </>
            );

          case "pets":
            return (
              <>
                <Pets
                  data={result?.pets}
                  approval={
                    (isOwner || isPM || isLO) &&
                    result?.application?.status ===
                      GLOBAL.TENANT_APPLICATION_STATUS.PENDING_REVIEW
                  }
                  onAction={onAction}
                  index={sequenceNumber.indexOf("pets") + 2}
                  // TODO : Pending index number generate as per application
                  // index="5"
                />
              </>
            );

          case "rental_history":
            return (
              <>
                <RentalHistory
                  data={result?.rental_history}
                  approval={
                    (isOwner || isPM || isLO) &&
                    result?.application?.status ===
                      GLOBAL.TENANT_APPLICATION_STATUS.PENDING_REVIEW
                  }
                  onAction={onAction}
                  index={sequenceNumber.indexOf("rental_history") + 2}
                  // TODO : Pending index number generate as per application
                  // index="6"
                />
              </>
            );

          case "violations":
            return (
              <>
                <ViolationList
                  data={result?.voilationDetail}
                  onAction={onAction}
                  index={sequenceNumber.indexOf("violations") + 2}
                />
              </>
            );

          case "outstanding_invoices":
            return (
              <>
                <OutStandingInvoiceList
                  data={result?.outStandingInvoices}
                  onAction={onAction}
                  index={sequenceNumber.indexOf("outstanding_invoices") + 2}
                  property={propertyData}
                  fetchContractDetails={fetchContractDetails}
                />
              </>
            );

          case "maintenances":
            return (
              <>
                <MaintenanceDetails
                  details={result?.maintenanceCount}
                  onAction={onAction}
                  index={sequenceNumber.indexOf("maintenances") + 2}
                />
              </>
            );

          case "renewal_pending_approval_message":
            return (
              <>
                <ContractRenewalStatus text="Pending Approval" />
              </>
            );

          case "move_out_initiated":
            return (
              <>
                <ContractRenewalStatus text="Move out initiated" />
              </>
            );

          case "renewal_pending_approval":
            return (
              <>
                <Renewal
                  setShowInstallment={setShowInstallment}
                  fetchContractDetails={fetchContractDetails}
                  moveout_payload={{
                    contract_id: result?.old_contract_id,
                    unit_id: result?.application?.unit?._id,
                    from: "contract_detail",
                  }}
                />
                <ContractRenewalStatus text="Pending Approval" />
              </>
            );

          case "oldTenantContract_details":
            return (
              <>
                <RentDetails
                  isOld={true}
                  canViewCheque={true}
                  property={property}
                  data={
                    result?.oldTenantContract_details
                      ?.oldContractsTenantInstallments
                  }
                  setShowSetInstallment={setShowInstallment}
                  application_id={applicationID}
                  instalmentPlan={
                    result?.oldTenantContract_details
                      ?.oldContractTenantInstallmentPlan
                  }
                  currency={
                    result?.installment_plan?.currency || property?.currency
                  }
                  depositData={
                    result?.oldTenantContract_details
                      ?.oldContractTenantInstallmentPlan?.deposit_data
                  }
                  getApplication={fetchContractDetails}
                  index={
                    sequenceNumber.indexOf("oldTenantContract_details") + 2
                  }
                />
              </>
            );

          case "installments":
            return (
              <>
                <RentDetails
                  isRenew={true}
                  data={result?.installments}
                  property={property}
                  setShowSetInstallment={setShowInstallment}
                  application_id={applicationID}
                  instalmentPlan={result?.installment_plan}
                  canEdit={
                    (isOwner || isPM || isLO) &&
                    result?.can_edit_installment_plan
                  }
                  canSend={
                    (isOwner || isPM || isLO) &&
                    result?.can_send_installment_plan
                  }
                  currency={
                    result?.installment_plan?.currency || property?.currency
                  }
                  canAttachCheque={
                    result?.can_attach_cheque &&
                    ((propertyData?.mgmt_model === 0 && isOwner) ||
                      (propertyData?.mgmt_model !== 0 && isAcc))
                  }
                  canViewCheque={result?.installment_plan?.cheque}
                  onUpdateChequeCallback={fetchContractDetails}
                  getApplication={fetchContractDetails}
                  depositData={result?.installment_plan?.deposit_data}
                  index={sequenceNumber.indexOf("installments") + 2}
                  // TODO : Pending index number generate as per application
                  // index="7"
                />
              </>
            );

          case "pending_tenant_approval":
            return (
              <>
                <ContractRenewalStatus text="Pending Tenant Approval" />
              </>
            );

          case "revision_requests":
            return (
              <>
                <Revision
                  data={result?.contract?.actions}
                  application_id={applicationID}
                  unit_id={result?.application?.unit?._id}
                  contract_id={result?.contract?._id}
                  fetchContractDetails={fetchContractDetails}
                  // index={sequenceNumber.indexOf("revision_requests") + 2}
                  // index={""}
                />
              </>
            );

          case "pending_revision_approval":
            return (
              <>
                <ContractRenewalStatus text="Revision request is under review" />
              </>
            );

          case "pending_acc_cheque_attach":
            return (
              <>
                <ContractRenewalStatus
                  text={
                    result.application.unit.property_id.mgmt_model === 0
                      ? "Pending Owner To Attach Cheque To Installments"
                      : "Pending Accountant To Attach Cheque To Installments"
                  }
                />
              </>
            );

          case "initial_agreement_review":
            return (
              <>
                <div className="initAgreementTemplate bg_grey">
                  <p className="font_m fw_5">Initial Agreement</p>
                  <div className="init-agreement-eye">
                    <img
                      onClick={() => {
                        setOpenInitAgreement(true);
                      }}
                      src={EyeFilledBlue}
                      alt=""
                      className="icon-m"
                    />

                    <ESignDocModal
                      showModal={openInitAgreement}
                      type="initial_agreement"
                      application_id={applicationID}
                      onCompleted={async () => {
                        dispatch(setLoading(true));
                        setTimeout(async () => {
                          console.log("Signed Successfully");
                          await fetchContractDetails();
                          navigate(-1);
                        }, 2000);
                        setOpenInitAgreement(false);
                      }}
                      onClose={() => {
                        setOpenInitAgreement(false);
                      }}
                    />
                  </div>
                </div>
              </>
            );

          case "pending_approver_sign":
            return (
              <>
                <ContractRenewalStatus
                  text={`Pending ${
                    ((isPM || isLO) && "Property Manager") ||
                    (isOwner && "Owner")
                  } Signature`}
                />
              </>
            );

          case "initial_agreement":
            return (
              <>
                <InitialAgreement
                  application={result?.application}
                  agreement={result?.application?.initial_agreement_pdf}
                  auditLog={result?.application?.initial_agreement_audit_logs}
                  index={sequenceNumber.indexOf("initial_agreement") + 2}
                  // TODO : Pending index number generate as per application
                  // index={1}
                />
              </>
            );

          case "pending_tenant_signature":
            return (
              <>
                <ContractRenewalStatus
                  //text="Pending Tenant Sign For Contract"
                  text="Pending Tenant Contract Signature"
                />
              </>
            );

          case "pending-contract-review":
            return (
              <>
                <div className="initAgreementTemplate bg_grey">
                  <p className="font_m fw_5">Final Agreement</p>
                  <div className="init-agreement-eye">
                    <img
                      onClick={() => {
                        setOpenFinlAgreement(true);
                      }}
                      src={EyeFilledBlue}
                      alt=""
                      className="icon-m"
                    />

                    <ESignDocModal
                      showModal={openFinlAgreement}
                      type="final_agreement"
                      application_id={applicationID}
                      onCompleted={async () => {
                        dispatch(setLoading(true));
                        setTimeout(async () => {
                          console.log("Signed Successfully");
                          await fetchContractDetails();
                        }, 2000);
                        setOpenFinlAgreement(false);
                      }}
                      onClose={() => {
                        setOpenFinlAgreement(false);
                      }}
                    />
                  </div>
                </div>
              </>
            );

          case "final_agreement":
            return (
              <>
                <FinalAgreement
                  agreement={result?.application?.final_agreement_pdf}
                  auditLog={result?.application?.final_agreement_audit_logs}
                  application={result?.application}
                  index={sequenceNumber.indexOf("final_agreement") + 2}
                  // TODO : Pending index number generate as per application
                  // index="9"
                />
              </>
            );

          case "pending_tenant_info":
            return (
              <>
                <ContractRenewalStatus text="Pending Tenant Information Update" />
              </>
            );

          case "approve_contract_renewal":
            return (
              <>
                <RenewalFinalStep
                  contract_id={result?.contract?._id}
                  application_id={applicationID}
                  can_resend_for_review={result?.can_resend_for_review}
                  can_approve_application={result?.can_approve_application}
                  fetchContractDetails={fetchContractDetails}
                  error_message={result?.error_message}
                  index={sequenceNumber.indexOf("basic_details") + 2}
                />
              </>
            );

          case "cancel_renewal":
            return (
              <>
                <CancelRenewal
                  contract_id={result?.old_contract_id}
                  unit_id={result?.application?.unit?._id}
                  fetchContractDetails={fetchContractDetails}
                />
              </>
            );

          default:
            return null;
        }
      })}

      <CreateInstallmentModal
        property={property}
        currency={result?.application?.unit?.currency}
        showModal={showInstallment}
        onClose={() => setShowInstallment(false)}
        application_id={applicationID}
        instalmentPlan={result?.installment_plan}
        declareInstallment={declareInstallment}
        startDate={result?.installment_plan?.start_date}
        isContractRenewal={true}
        target_rent_price={result?.application?.unit?.target_rent_price}
      />
    </div>
  );

  {
    /* Property Detail */
  }
  {
    /* <PropertyDetail /> */
  }
  return (
    <div className="contract-renewal-detail-wrapper container_md">
      {application && (
        <>
          <div>
            <ViolationList data={currentItemsList} />

            {currentItemsList?.length > 0 && (
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={propertyViolations?.totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
/* Component ends */

export default ContractRenewal;
