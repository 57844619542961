/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validEmail } from "../../../setup/utils/validations";
import { Toastify } from "../../../setup/utils/toast";
import GLOBAL from "../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../setup/store/slices/globalAlertSlice";
import {
  resendVerificationCode,
  verifyAccount,
  resendVerificationEmail,
  setToken,
  setUser,
  sendForgotPasswordOtp,
  verifyEmailOtp,
  signin,
  fetchUser,
  fetchTenant,
  setUserRole,
  changePassword,
} from "../../../setup/store/slices/authSlice";
// import { addMember } from "../../../setup/store/slices/teamSlice";
import { getAuthLoading } from "../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { ArrowPointRightBlack } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomTextInput from "../../../components/ui/input/CustomTextInput/CustomTextInput";
import CustomPasswordInput from "../../../components/ui/input/CustomPasswordInput/CustomPasswordInput";
import CustomButton from "../../../components/ui/button/CustomButton/CustomButton";
import TextButton from "../../../components/ui/button/TextButton/TextButton";
import ConfirmationOTPModal from "../../../components/ui/modal/ConfirmationOTPModal/ConfirmationOTPModal";
import ForgotPasswordModal from "../../../components/module/Auth/ForgotPasswordModal/ForgotPasswordModal";
import ChangePasswordModal from "../../../components/module/Auth/ChangePasswordModal/ChangePasswordModal";
/* Import local pages and component ends */

/* Component starts */
const SignInForm = (props) => {
  /* Props destructuring starts */
  const { setUserRoleOnLogin } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [errorWhileSubmiting, setErrorWhileSubmiting] = useState(null);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showEmailOTPModal, setShowEmailOTPModal] = useState(false);
  const [showPhoneOTPModal, setShowPhoneOTPModal] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [registeredPassword, setRegisteredPassword] = useState("");
  const [tempToken, setTempToken] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [showForgotPasswordOTPModal, setShowForgotPasswordOTPModal] =
    useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [forgotPasswordUserName, setForgotPasswordUserName] = useState("");
  const [forgotPasswordOTP, setForgotPasswordOTP] = useState("");

  const [tempPass, setTempPass] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux Selector
  const authLoading = useSelector(getAuthLoading);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialValues = {
    username: "",
    password: "",
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Error text if the required input field is empty
  const errorMsgForEmptyFields = (fieldName) => {
    switch (fieldName) {
      case "username":
        return "Email or Phone Number is required";
      case "password":
        return "Password is required";
      default:
        return "Invalid Input";
    }
  };

  // Input fields that are required are validated
  const requiredFieldsValidation = (errorFields, values) => {
    Object.keys(values).map((key) => {
      if (values[key] === "") {
        errorFields[key] = errorMsgForEmptyFields(key);
      }
    });
    return errorFields;
  };

  // Other form validations logics
  const otherFieldValidations = (errorFields, values) => {
    /* Validation for username starts */
    if (isNaN(+values.username) && !validEmail(values.username)) {
      errorFields.username = "Email is not valid";
    }
    /* Validation for username ends */
  };

  // On closing any Modal
  const onModalClose = () => {
    setShowEmailOTPModal(false);
    setShowPhoneOTPModal(false);
  };

  // On resending verification OTP sent to registered email
  const onResendEmailVerificationCode = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      email: registeredEmail,
      token: accessToken,
    };

    //Backend respose. Try, Catch
    try {
      const result = await dispatch(resendVerificationEmail(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          Toastify("error", result.payload);
          break;
        case "fulfilled":
          Toastify("success", result.payload.message);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On resending verification OTP sent to registered mobile number
  const onResendMobileVerificationCode = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      email: registeredEmail,
      password: tempPass,
    };

    //Backend respose. Try, Catch
    try {
      const result = await dispatch(resendVerificationCode(payload));
      switch (result.meta.requestStatus) {
        case "rejected":
          Toastify("error", result.payload);
          break;

        case "fulfilled":
          Toastify("success", result.payload.message);
          break;

        default:
      }
      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On verifying OTP sent to registered email
  const onVerifyEmail = async (otp) => {
    if (otp.length != 6) {
      Toastify("error", "Invalid OTP");
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      otp: otp,
      token: accessToken,
      jwt: tempToken,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(verifyEmailOtp(payload));
      setShowEmailOTPModal(false);
      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          // Toastify("error", result.payload);
          // Toastify("error", "Invalid email verification code provided, please try again");
          dispatch(
            showErrorAlert({
              title: "Error",
              description: "Invalid email verification code provided, please try again",
            })
          );
          break;

        case "fulfilled":
          onSignIn({
            username: registeredEmail,
            password: registeredPassword,
          });
          Toastify("success", result.payload.message);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
    }
  };

  // On verifying OTP sent to registered mobile number
  const onVerifyMobile = async (otp) => {
    if (otp.length !== 6) {
      Toastify("error", "Enter valid OTP");
      return;
    }

    // Creating or gathering payload data to be sent
    const payload = {
      otp: otp,
      email: registeredEmail,
      password: registeredPassword,
    };

    // Backend Response. Try, Catch
    try {
      const result = await dispatch(verifyAccount(payload));
      setShowPhoneOTPModal(false);
      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          // Toastify("error", result.payload);
          // Toastify("error", "Invalid mobile verification code provided, please try again");
          dispatch(
            showErrorAlert({
              title: "Error",
              description:
                "Invalid mobile verification code provided, please try again",
            })
          );
          break;
        case "fulfilled":
          Toastify("success", "Mobile number successfully verified");
          onSignIn({
            username: registeredEmail,
            password: registeredPassword,
          });
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // Form validations
  const formValidation = (values) => {
    const errorFields = {};
    requiredFieldsValidation(errorFields, values);
    otherFieldValidations(errorFields, values);
    return errorFields;
  };

  // Handling on signing in
  const onSignIn = async (values) => {
    setErrorWhileSubmiting(null);

    // Creating or gathering payload data to be sent
    const payload = {
      username: values.username.toLowerCase(),
      password: values.password,
    };

    setTempPass(values?.password);

    // Backend Response. Try, Catch
    try {
      const result = await dispatch(signin(payload));
      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          setErrorWhileSubmiting(result.payload.error.message);
          setRegisteredEmail(result.payload.data.email);
          setRegisteredPassword(values.password);

          if (result.payload.data?.code === "MOBILE_NOT_VERIFIED") {
            setShowPhoneOTPModal(true);
          } else if (result.payload.data?.code === "EMAIL_NOT_VERIFIED") {
            setAccessToken(result.payload.data.token);
            setTempToken(result.payload.data.token);
            setShowEmailOTPModal(true);
          }
          break;

        case "fulfilled":
          // await dispatch(setToken(result.payload.token));
          // await dispatch(setUser(result.payload.user));
          localStorage.setItem(
            "user_data",
            JSON.stringify(result.payload.user)
          );
          localStorage.setItem("user_token", result.payload.token);
          localStorage.setItem("user_role", result.payload.user.role_id.name);
          dispatch(setUserRole(result.payload.user.role_id.name));
          // setUserRoleOnLogin(result.payload.user.role_id);
          if (result.payload.user.role_id.name === "Tenant") {
            await dispatch(fetchTenant());
            navigate("/tenant/home");
          } else {
            await dispatch(fetchUser());
            navigate("/");
          }
          break;

        default:
      }
    } catch (e) {
      // Handling error response
      console.error(e?.message);
    }
  };

  const confirmEmailForgotPassword = async (username) => {
    // Creating or collecting payload data to be sent
    const payload = { username };
    setForgotPasswordUserName(username);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(sendForgotPasswordOtp(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description:
          //       typeof result?.payload?.message === "string"
          //         ? result?.payload?.message
          //         : "",
          //   })
          // );
          setShowForgotPasswordOTPModal(true);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const confirmOTPForgotPassword = async (otp) => {
    setForgotPasswordOTP(otp);
    setShowForgotPasswordOTPModal(false);
    setShowChangePasswordModal(true);
  };

  const confirmChangePassword = async (values) => {
    const { newPassword, newPasswordConfirm } = values;
    const payload = {
      password: newPassword,
      username: forgotPasswordUserName,
      otp: forgotPasswordOTP,
    };
    // Backend response. Try, Catch
    try {
      const result = await dispatch(changePassword(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: "Password changed successfully!",
            })
          );
          setShowChangePasswordModal(false);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      {/* Sign in form */}
      <Formik
        onSubmit={onSignIn}
        initialValues={initialValues}
        validate={formValidation}
        validateOnBlur={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* Input for Username */}
            <div className="form-group">
              <CustomTextInput
                name="username"
                type="text"
                label="Email / Phone Number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.username}
                touched={touched.username}
                errors={errors.username}
              />
            </div>

            {/* Input for Password */}
            <div className="form-group">
              <CustomPasswordInput
                name="password"
                type="password"
                showPasswordValidationErr={false}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.password}
                errors={errors.password}
                touched={touched.password}
                label="Password"
              />
            </div>

            <div className="forgot-password-wrapper text-end">
              <TextButton onClick={() => setShowForgotPasswordModal(true)}>
                Forgot Password ?
              </TextButton>
            </div>

            {/* If any error occured from backend while submitting */}
            {errorWhileSubmiting && (
              <p className="error-while-submitting font_m font_red text-center w_100">
                {errorWhileSubmiting}
              </p>
            )}

            {/* Submit button and Sign in loading */}
            {authLoading ? (
              <p className="sigining-in font_m font_grey text-center">
                Signing you in
              </p>
            ) : (
              <div className="submit-button-wrapper">
                <CustomButton className="custom-btn-2" type="submit" size="l">
                  Sign In
                  <img
                    src={ArrowPointRightBlack}
                    className="arrow icon"
                    alt=""
                  />
                </CustomButton>
              </div>
            )}
          </form>
        )}
      </Formik>

      {/* Modals */}
      {/* Mobile verification Modal  */}
      <ConfirmationOTPModal
        showModal={showPhoneOTPModal}
        title="Enter Confirmation Code"
        size="lg"
        description="Enter the OTP that has been sent to your registered mobile number"
        onVerify={onVerifyMobile}
        onCancel={onModalClose}
        onResend={onResendMobileVerificationCode}
      />

      {/* Email verification Modal  */}
      <ConfirmationOTPModal
        showModal={showEmailOTPModal}
        title="Enter Confirmation Code"
        size="lg"
        description="Enter the OTP that has been sent to your registered email address"
        onVerify={onVerifyEmail}
        onCancel={onModalClose}
        onResend={onResendEmailVerificationCode}
      />

      <ConfirmationOTPModal
        showModal={showForgotPasswordOTPModal}
        title="Enter Confirmation Code"
        size="lg"
        description="Enter the OTP that has been sent to your registered email address"
        onVerify={confirmOTPForgotPassword}
        onCancel={() => setShowForgotPasswordOTPModal(false)}
        // onResend={onResendEmailVerificationCode}
      />

      {/* Forgot Password Modal */}
      <ForgotPasswordModal
        showModal={showForgotPasswordModal}
        onClose={() => setShowForgotPasswordModal(false)}
        callBack={confirmEmailForgotPassword}
      />

      <ChangePasswordModal
        showModal={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
        onSubmit={confirmChangePassword}
      />
    </div>
  );
};
/* Component ends */

export default SignInForm;
