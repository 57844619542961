import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices starts */
import {
  fetchUser,
  getUser,
  validateUserInvitation,
} from "../../setup/store/slices/authSlice";
import { getAuthLoading } from "../../setup/store/slices/unpersistedSlice";
import { showErrorAlert } from "../../setup/store/slices/globalAlertSlice";
import {
  fetchDGRNTEterms,
  getSetting,
} from "../../setup/store/slices/settingSlice";
/* Import redux slices ends */

/* Import local pages and component starts */
import SignUpForm from "./local-components/SignUpForm";
import TermsConditionSignupModal from "../../components/module/Auth/TermsConditionSignupModal/TermsConditionSignupModal";
/* Import local pages and component ends */

/* Import image and SVG starts */
import {
  LogoWhite,
  BgShadeSignUp,
  SignUpLogo,
  DGRNTEtext,
  ArrowPointRightBlack,
  WarningRoundedOutlineYellow,
} from "../../setup/constants/images";
/* Import image and SVG ends */

import "./style.scss";

/* Component starts */
const SignUpTeamInvite = () => {
  /* Component states declaration starts */
  const [userCopy, setUserCopy] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const [isInviteLoaded, setIsInviteLoaded] = useState(false);
  const [validateInvite, setValidateInvite] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [setting, setSetting] = useState({ terms: "", about: "" });
  /* Component states declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { encodedToken } = useParams();

  // Redux Selector
  const user = useSelector(getUser);
  const authLoading = useSelector(getAuthLoading);
  // const setting = useSelector(getSetting);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const loggedInUserData =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));
  const loggedInUserRole = localStorage.getItem("user_role");
  const loggedInUserToken = localStorage.getItem("user_token");
  const userLoggedIn =
    loggedInUserData && loggedInUserRole && loggedInUserToken;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchUser = async () => {
    const result = await dispatch(fetchUser());
    setLoaded(true);
    switch (result.meta.requestStatus) {
      case GLOBAL.REJECTED:
        setUserCopy(null);
        break;

      case GLOBAL.FULFILLED:
        setUserCopy(result.payload.user);
        break;
    }
  };

  const checkInviteValidation = async (token) => {
    const result = await dispatch(validateUserInvitation({ token: token }));
    console.log(result, "RES");
    setIsInviteLoaded(true);
    switch (result.meta.requestStatus) {
      case GLOBAL.REJECTED:
        setModalDesc(result.payload);
        setValidateInvite(result.payload);
        setShowErrorModal(true);
        break;

      case GLOBAL.FULFILLED:
        setValidateInvite(result.payload);
        break;
    }
  };

  const handleFetchTermsConditions = async () => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchDGRNTEterms());

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.FULFILLED:
          setSetting(result.payload);
          break;

        default:
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    // handleFetchUser();
    (async () => {
      await handleFetchTermsConditions();
    })();
  }, []);

  useEffect(() => {
    // if (loaded) {
    if (userLoggedIn) {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description:
            "Please log out from the existing user first. Then click on that same invitation link sent on email.",
        })
      );
      navigate("/");
    } else {
    }
    // }
  }, [userLoggedIn]);

  useEffect(() => {
    if (encodedToken) {
      checkInviteValidation(encodedToken);
    }
  }, [encodedToken]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  // If user is logged in then navigating back
  // if (user) {
  //   return <Navigate to="/" />;
  // }
  if (
    isInviteLoaded &&
    !userLoggedIn &&
    validateInvite?.message === "Valid invitation."
  ) {
    return (
      <div className="sign-up-team-wrapper">
        <div className="bgShade">
          <img src={BgShadeSignUp} alt="" />
        </div>
        <div className="image">
          <div className="inner-wrapper">
            <div className="text-logo">
              <img src={SignUpLogo} className="logo-icon" alt="" />
              <img src={DGRNTEtext} alt="" />
            </div>
            <h2 className="heading font-darkgreen fw_6">
              Start your journey with us
            </h2>
            <h3 className="content font-darkgreen">
              Discover the power of collaboration and create lasting
              relationships that transcend boundaries
            </h3>
          </div>
        </div>
        <div className="form-wrapper">
          <div
            className="blur-blue"
            style={{ top: "7%", left: "35%", zIndex: "10" }}
          ></div>
          <div
            className="blur-cherry"
            style={{ top: "-3%", right: "5%", zIndex: "10" }}
          ></div>
          <div
            className="blur-blue"
            style={{ top: "75%", left: "97%", zIndex: "10" }}
          ></div>
          <div
            className="blur-cherry"
            style={{ top: "90%", right: "50%", zIndex: "10" }}
          ></div>
          <div className="logo-wrapper">
            {/* <img src={LogoWhite} alt="Logo" /> */}
            <h1 className="big-h1 font-darkgreen">Sign Up Team</h1>
          </div>

          <SignUpForm setShowTerms={setShowTerms} />
          {/* {!authLoading && (
          <div className="footer text-center">
            <Link className="font_m" to="/signin">
              Return back to Sign In
            </Link>
          </div>
        )} */}
        </div>
        <TermsConditionSignupModal
          title="Terms And Conditions"
          showModal={showTerms}
          onClose={() => setShowTerms(false)}
          data={setting?.terms}
        />
      </div>
    );
  } else if (
    isInviteLoaded &&
    !userLoggedIn &&
    (validateInvite === "Invitation link expired." ||
      validateInvite ===
        "Your invitation has been expired. Please request new invitation.")
  ) {
    return (
      <div className="signin-outer-wrapper-valid">
        <div className="valid-wrapper container_sm">
          <img src={WarningRoundedOutlineYellow} alt="Alert" />
          <p className="font_xl fw_5 font_red">{validateInvite}</p>

          <div className="footer text-center">
            <Link className="font_m back-link" to="/signin">
              Return back to Sign In
            </Link>
          </div>
        </div>
      </div>
    );
  }
};
/* Component ends */

export default SignUpTeamInvite;
