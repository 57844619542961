/**
 *
 */

import React, { useState, useEffect, useRef, useCallback } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
/* Import configuration ends */

/* Import redux slices component starts */
import { captchaVerification } from "../../../../setup/store/slices/authSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import { fetchSetting } from "../../../../setup/store/slices/settingSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

/* Component starts */
const HumanVerification = (props) => {
  /* Props destructuring starts */
  const {
    setTokenValue = null,
    setVerified = null,
    onVerifying = null,
    onSuccess = null,
    onError = null,
    className = "",
    recaptchaAction = "",
    textClassName = "",
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [token, setToken] = useState();
  const [score, setScore] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    show: false,
    message: "Something went wrong!",
  });
  const [success, setSuccess] = useState(false);
  const [adminSettings, setAdminSettings] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      recaptchaAction,
    };

    // Backend response. Try, Catch
    try {
      setLoading(true);
      const generatedToken = await executeRecaptcha(recaptchaAction);
      payload.token = generatedToken;
      const result = await dispatch(captchaVerification(payload));
      setLoading(false);

      console.log("Response", result);

      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setToken(generatedToken);
          setTokenValue && setTokenValue(generatedToken);
          onVerifying && onVerifying();
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description:
          //       typeof result?.payload?.message === "string"
          //         ? result?.payload?.message
          //         : "",
          //   })
          // );
          break;
      }

      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  }, [executeRecaptcha]);

  const onSubmit = (e) => {
    e.preventDefault();
    /* do something like submit a form and then refresh recaptcha */

    // setRefreshReCaptcha((r) => !r);
  };

  // You can use useEffect to trigger the verification as soon as the component being loaded
  // useEffect(() => {
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className={`human-verification-wrapper ${className}`}>
      {!loading && (
        <TextButton
          className={`font_m fw_5 font_blue ${textClassName}`}
          onClick={handleReCaptchaVerify}
        >
          I am a human
        </TextButton>
      )}

      {loading && (
        <p className={`font_m fw_5 font_yellow`}>Verifying, please wait...</p>
      )}

      {error.show && (
        <p className={`font_m fw_5 font_red`}>Something went wrong !</p>
      )}

      {success && <p className={`font_m fw_5 font_green`}>Verified !</p>}
    </div>
  );
};
/* Component ends */

export default HumanVerification;
