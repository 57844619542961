/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CustomCheckInput = (props) => {
  /* Props destructuring starts */
  const {
    name,
    handleChange,
    value,
    setValue,
    customOnChange,
    variant = 1,
    display = "inlineblock",
    disabled = false,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onChange = (e) => {
    handleChange && handleChange(e);
    setValue && setValue(!value);
    customOnChange && customOnChange(!value, e);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div
      className={`custom-check-input-component-wrapper d_${display} v_middle`}
    >
      <label className={`variant-${variant}`}>
        <input
          name={name}
          type="checkbox"
          onChange={onChange}
          value={value}
          checked={value}
          disabled={disabled}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};
/* Component ends */

export default CustomCheckInput;
