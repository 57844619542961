import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  validEmail,
  validateAtleastOneNumber,
  containsSpecialChar,
} from "../../../setup/utils/validations";
import { Formik } from "formik";
import GLOBAL from "../../../setup/constants/global";
import { useTranslation } from "react-i18next";
import { Toastify } from "../../../setup/utils/toast";

/* Import configuration ends */

/* Import redux slices starts */
import {
  resendVerificationCode,
  signup,
  verifyAccount,
  verifyEmailOtp,
} from "../../../setup/store/slices/authSlice";
import {
  fetchCountries,
  fetchStatesByCountryId,
  fetchCitiesByStateId,
  getCountries,
  getStates,
  getCities,
  setCities,
} from "../../../setup/store/slices/countrySlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../setup/store/slices/masterSlice";
import { getAuthLoading } from "../../../setup/store/slices/unpersistedSlice";
import { showErrorAlert } from "../../../setup/store/slices/globalAlertSlice";
import { setLoading } from "../../../setup/store/slices/unpersistedSlice";
/* Import redux slices ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { ArrowPointRightBlack } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomPhoneInput from "../../../components/ui/input/CustomPhoneInput/CustomPhoneInput";
import CustomTextInput from "../../../components/ui/input/CustomTextInput/CustomTextInput";
import CustomPasswordInput from "../../../components/ui/input/CustomPasswordInput/CustomPasswordInput";
import CustomSelectInput from "../../../components/ui/input/CustomSelectInput/CustomSelectInput";
import CustomButton from "../../../components/ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../components/ui/modal/SuccessModal/SuccessModal";
import ConfirmationOTPModal from "../../../components/ui/modal/ConfirmationOTPModal/ConfirmationOTPModal";
import CustomCheckInput from "../../../components/ui/input/CustomCheckInput/CustomCheckInput";
import ReCaptchaVerificationBox from "../local-components/ReCaptchaVerificationBox";
/* Import local pages and component ends */

/* Component starts */
const SignUpForm = (props) => {
  /* Props destructuring starts */
  const {
    setShowTerms,
    recaptchaVerified,
    adminSettings,
    setRecaptchaVerified,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRefs declaration starts */
  const [errorWhileSubmiting, setErrorWhileSubmiting] = useState(null);
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [registeredPassword, setRegisteredPassword] = useState("");

  // Custom input field values
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [terms, setTerms] = useState(false);
  // Modal state
  const [showSignupSuccessModal, setShowSignupSuccessModal] = useState(false);
  // const [showMobileVerificationModal, setShowMobileVerificationModal] =
  //   useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);
  const [visitedTerms, setVisitedTerms] = useState(false);

  const [tempToken, setTempToken] = useState("");
  const [accessToken, setAccessToken] = useState("");

  // useRefs declarations
  const customPhoneNum = useRef({ code: "", num: "" });
  const formikPasswordValidation = useRef(false);
  /* Component states and useRefs declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Redux selector
  const countries = useSelector(getCountries);
  const states = useSelector(getStates);
  const cities = useSelector(getCities);
  const userRoles = useSelector(getUserRoles);
  const authLoading = useSelector(getAuthLoading);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialValues = {
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    password: "",
    confirm_password: "",
    business_name: "",
    address_line1: "",
    address_line2: "",
    zipcode: "",
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleAgreeTerms = (v) => {
    if (visitedTerms) {
      setTerms(v);
    } else {
      dispatch(
        showErrorAlert({
          title: "Cannot Proceed",
          description:
            "Please go through terms and conditions before accepting.",
        })
      );
    }
  };

  // Handling On Country code change in Phone field
  const onCountryCodeChanged = (country) => {
    const defaultCountry = countries?.filter((c) => {
      return c.iso2.toLowerCase() == country.countryCode;
    })[0];
    setSelectedCountry(defaultCountry);
  };

  // Loading all countries
  const loadCountries = async () => {
    await dispatch(fetchCountries());
  };

  //Loading all states
  const loadStates = async () => {
    if (selectedCountry != null) {
      setSelectedState(null);
      setSelectedCity(null);
      await dispatch(
        fetchStatesByCountryId({ country_id: selectedCountry?.id })
      );
    }
  };

  // Loading all cities
  const loadCities = async () => {
    if (selectedState != null) {
      setSelectedCity(null);
      await dispatch(fetchCitiesByStateId({ state_id: selectedState?.id }));
    }
  };

  // Error text if the required input field is empty
  const errorMsgForEmptyFields = (fieldName) => {
    switch (fieldName) {
      case "first_name":
        return "First Name is required";
      case "last_name":
        return "Last Name is required";
      case "email":
        return "Email is required";
      case "password":
        return "Password is required";
      case "confirm_password":
        return "Confirm Password is required";
      case "business_name":
        return "Company Name is required";
      case "address_line1":
        return "Address is required";
      case "zipcode":
        return "ZIP code is required";
      case "mobile":
        return "Phone Number is required";
      default:
        return "Invalid Input";
    }
  };

  // Input fields that are not required are prevented to be validated
  const preventToBeValidated = (key) => {
    // Preventing Adress Line 2 to be validated
    return !(key === "address_line2");
  };

  // Input fields that are required are validated
  const requiredFieldsValidation = (errorFields, values) => {
    Object.keys(values).map((key) => {
      /* Preventing address line 2 field to be validated */
      if (values[key].trim() === "" && preventToBeValidated(key)) {
        errorFields[key] = errorMsgForEmptyFields(key);
      }
    });
    return errorFields;
  };

  // Other form validations logics
  const otherFieldValidation = (errorFields, values) => {
    /* Validation for first name starts */
    if (values.first_name && validateAtleastOneNumber(values.first_name)) {
      errorFields.first_name = "First Name Cannot Contains Numbers";
    }
    if (values.first_name && containsSpecialChar(values.first_name)) {
      errorFields.first_name = "First Name Cannot Contains Special Characters";
    }
    /* Validation for first name ends */
    /* *******************************************************************************  */
    /* Validation for last name starts */
    if (values.last_name && validateAtleastOneNumber(values.last_name)) {
      errorFields.last_name = "Last Name Cannot Contains Numbers";
    }
    if (values.last_name && containsSpecialChar(values.last_name)) {
      errorFields.last_name = "Last Name Cannot Contains Special Characters";
    }
    /* Validation for last name ends */
    /* *******************************************************************************  */
    /* Validation for email starts */
    if (values.email && !validEmail(values.email)) {
      errorFields.email = "Email is not valid";
    }
    /* Validation for email ends */
    /* *******************************************************************************  */
    /* Validation for phone number starts */
    if (customPhoneNum.current.num.length < 8) {
      errorFields.mobile = "Phone number is not valid";
    }
    /* Validation for phone number ends */
    /* *******************************************************************************  */
    /* Validation for Password starts */
    if (values.password && formikPasswordValidation.current) {
      errorFields.password = true;
    }
    /* Validation for Password ends */
    /* *******************************************************************************  */
    /* Validation for Confirm Password starts */
    if (
      values.confirm_password &&
      values.password !== values.confirm_password
    ) {
      errorFields.confirm_password =
        "Confirm Password doesn't match with the password";
    }
    /* Validation for Confirm Password ends */
  };

  // Form validations
  const formValidation = (values) => {
    const errorFields = {};
    requiredFieldsValidation(errorFields, values);
    otherFieldValidation(errorFields, values);
    return errorFields;
  };

  // On verifying OTP sent to registered mobile number
  // const onVerifyingMobile = async (otp) => {
  //   if (otp?.length != 6) {
  //     Toastify("error", "Enter valid OTP");
  //     return;
  //   }

  //   // Creating or gathering payload data to be sent
  //   const payload = {
  //     otp: otp,
  //     email: registeredEmail,
  //     password: registeredPassword,
  //   };

  //   // Backend Response. Try, Catch
  //   try {
  //     const result = await dispatch(verifyAccount(payload));
  //     // Handling success response
  //     switch (result.meta.requestStatus) {
  //       case "rejected":
  //         Toastify("error", result.payload);
  //         break;
  //       case "fulfilled":
  //         // setShowMobileVerificationModal(false);
  //         setShowEmailVerificationModal(false);
  //         setShowSignupSuccessModal(true);
  //         break;

  //         default:
  //     }
  //   } catch (error) {
  //     // Handling error response
  //     console.error(error.message);
  //   }
  // };

  // On verifying OTP sent to registered email
  const onVerifyEmail = async (otp) => {
    if (otp.length != 6) {
      Toastify("error", "Invalid OTP");
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      otp: otp,
      token: accessToken,
      jwt: tempToken,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(verifyEmailOtp(payload));
      setShowEmailVerificationModal(false);

      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          // Toastify("error", result.payload);
          // Toastify(
          //   "error",
          //   "Invalid email verification code provided, please try again"
          // );
          dispatch(
            showErrorAlert({
              title: "Error",
              description:
                "Invalid email verification code provided, please try again",
            })
          );

          break;
        case "fulfilled":
          // onSignIn({
          //   username: registeredEmail,
          //   password: registeredPassword,
          // });
          setShowSignupSuccessModal(true);
          // Toastify("success", result.payload.message);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
    }
  };

  // On resending verification otp
  const onResendAccountVerificationCode = async () => {
    // Creating or gathering payload data to be sent
    const payload = {
      email: registeredEmail,
    };
    try {
      const result = await dispatch(resendVerificationCode(payload));
      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          Toastify("error", result.payload);
          break;
        case "fulfilled":
          Toastify("success", result.payload.message);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On closing any modal
  const onClosingModal = () => {
    navigate("/signin");
  };

  // Handling on signing up
  const onSignUp = async (values, { setSubmitting }) => {
    setErrorWhileSubmiting(null);
    if (!terms) {
      dispatch(
        showErrorAlert({
          title: "Error",
          description: "Please confirm terms and conditions",
        })
      );
      return;
    }

    // Creating or gathering payload data to be sent
    const payload = {
      ...values,
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      business_name: values.business_name.trim(),
      zipcode: values.zipcode.trim(),
      city: selectedCity?.name || "",
      state: selectedState?.name || "",
      country: selectedCountry?.name || "",
      countrycode: customPhoneNum.current.code,
      mobile: customPhoneNum.current.num,
      email: values.email.toLowerCase(),
      role_id: userRoles.filter((d) => d.name === GLOBAL.USER_ROLE.CLIENT)[0]
        ._id,
      currency: selectedCountry?.currency,
    };

    // Backend Response. Try, Catch
    try {
      const result = await dispatch(signup(payload));
      setSubmitting(false);

      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          setErrorWhileSubmiting(result?.payload);
          break;
        case "fulfilled":
          if (result?.payload?.user?.email_verified) {
            navigate("/signin");
          } else {
            setRegisteredEmail(values?.email.toLowerCase());
            setRegisteredPassword(values?.password);
            setShowEmailVerificationModal(true);
            setAccessToken(result?.payload?.token);
            setTempToken(result?.payload?.token);
          }
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      setSubmitting(false);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Loading all countries and fetching user roles initially
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await loadCountries();
        await dispatch(fetchUserRoles());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Setting selected country after loading countries
  useEffect(() => {
    if (countries?.length > 0) {
      const defaultCountry = countries?.filter(
        (country) => country.iso3 === "USA"
      )[0];
      setSelectedCountry(defaultCountry);
    }
  }, [countries]);

  // Loading states accoring to country selected
  useEffect(() => {
    loadStates();
  }, [selectedCountry]);

  // Setting selected state after loading state
  useEffect(() => {
    if (states?.length > 0) {
      setSelectedState(states[0]);
    }
  }, [states]);

  // Loading cities accoring to state selected
  useEffect(() => {
    loadCities();
  }, [selectedState]);

  // Setting selected city after loading cities
  useEffect(() => {
    if (cities?.length > 0) {
      setSelectedCity(cities[0]);
    } else {
      dispatch(
        setCities([
          {
            name: "-",
          },
        ])
      );
    }
  }, [cities]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      {/* Sign up form */}
      <Formik
        onSubmit={onSignUp}
        initialValues={initialValues}
        validate={formValidation}
        validateOnBlur={true}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          setFieldValue,
          isSubmitting,
        }) => (
          <form className="form" onSubmit={handleSubmit}>
            {/* Input for First Name */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="First Name"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.first_name}
                name="first_name"
                errors={errors.first_name}
                touched={touched.first_name}
              />
            </div>

            {/* Input for Last Name */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Last Name"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.last_name}
                name="last_name"
                errors={errors.last_name}
                touched={touched.last_name}
              />
            </div>

            {/* Input for Email Address */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Email Address"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.email}
                name="email"
                errors={errors.email}
                touched={touched.email}
              />
            </div>

            {/* Input for Phone Number */}
            <div className="form-group">
              <CustomPhoneInput
                name="mobile"
                label={"Phone Number"}
                customPhoneNum={customPhoneNum}
                handleChange={handleChange}
                handleBlur={handleBlur}
                onCountryCodeChanged={onCountryCodeChanged}
                value={values.mobile}
                countryCodeEditable={true}
                errors={errors.mobile}
                touched={touched.mobile}
              />
            </div>

            {/* Input for Password */}
            <div className="form-group">
              <CustomPasswordInput
                name="password"
                label="Password"
                formikPasswordValidation={formikPasswordValidation}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.password}
                errors={errors.password}
                touched={touched.password}
              />
            </div>

            {/* Input for Confirm Password */}
            <div className="form-group">
              <CustomPasswordInput
                name="confirm_password"
                label="Confirm Password"
                showPasswordValidationErr={false}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.confirm_password}
                errors={errors.confirm_password}
                touched={touched.confirm_password}
              />
            </div>

            {/* Input for Business Name */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Company Name"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.business_name}
                name="business_name"
                errors={errors.business_name}
                touched={touched.business_name}
              />
            </div>

            {/* Input for Address Line 1 */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Address Line 1"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.address_line1}
                name="address_line1"
                errors={errors.address_line1}
                touched={touched.address_line1}
              />
            </div>

            {/* Input for Address Line 2 */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Address Line 2"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.address_line2}
                name="address_line2"
                required={false}
              />
            </div>

            {/* Select field for Cities */}
            <div className="form-group">
              <CustomSelectInput
                name="city"
                label="City"
                handleBlur={handleBlur}
                onChange={handleChange}
                valueExtractor={(item) => item?.name}
                setValue={setSelectedCity}
                options={cities}
                value={selectedCity}
                formikState={false}
                emptyOption={false}
              />
            </div>

            {/* Select field for State and Zip-code */}
            <div className="form-group form-group-flex">
              <CustomSelectInput
                name="state"
                label="State"
                handleBlur={handleBlur}
                onChange={handleChange}
                valueExtractor={(item) => item?.name}
                setValue={setSelectedState}
                options={states}
                value={selectedState}
                formikState={false}
                emptyOption={false}
              />

              {/* Input for Zip Code */}
              <CustomTextInput
                type="text"
                label="Zip Code"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.zipcode}
                name="zipcode"
                errors={errors.zipcode}
                touched={touched.zipcode}
                // required={false}
              />
            </div>

            {/* Select field for Countries */}
            <div className="form-group">
              <CustomSelectInput
                name="country"
                label="Country"
                handleBlur={handleBlur}
                onChange={handleChange}
                valueExtractor={(item) => item?.name}
                setValue={setSelectedCountry}
                options={countries}
                value={selectedCountry}
                formikState={false}
                emptyOption={false}
                disabled={true}
              />
            </div>

            {/* If any error occured from backend while submitting */}
            {errorWhileSubmiting && (
              <Link
                to="/sigin"
                className="error-while-submitting font_m font_red text-center w_100"
              >
                {errorWhileSubmiting}
              </Link>
            )}

            <div className="terms-conditions-wrapper">
              <CustomCheckInput
                value={terms}
                // setValue={setTerms}
                customOnChange={handleAgreeTerms}
              />
              <p className="font_m text">
                <span>By checking this box, I agree to the </span>
                <span
                  className="font_blue fw_6 hyperlink"
                  onClick={() => {
                    setVisitedTerms(true);
                    setShowTerms(true);
                  }}
                >
                  Terms and Conditions{" "}
                </span>
                <span>
                  and consent to receive SMS messages containing one-time
                  passwords (OTPs) from DGRNTE for authentication purposes at
                  the provided phone number. Standard message and data rates may
                  apply. Message frequency will vary based on your account
                  activity. I can opt out of these messages at any time by
                  contacting
                </span>{" "}
                <Link
                  className="font_blue fw_6 hyperlink"
                  to="https://www.dgrnte.com/contactUs.html"
                  target="_blank"
                >
                  DGRNTE support.{" "}
                </Link>
                <span>
                  This consent is not required to make a purchase. Please review
                  our
                </span>{" "}
                <Link
                  className="font_blue fw_6 hyperlink"
                  to="https://www.dgrnte.com/privacyPolicy.html"
                  target="_blank"
                  // onClick={() => {
                  //   setVisitedPolicy(true);
                  //   setShowPrivacy(true);
                  // }}
                >
                  Privacy Policy
                </Link>{" "}
                <span>for details on how we protect your information.</span>
              </p>
            </div>
            {/* 
            <div className="terms-conditions-wrapper">
              <CustomCheckInput
                value={terms}
                // setValue={setTerms}
              />
              <p className="font_m text">
                <span>Accept</span>{" "}
                <span
                className="font_blue fw_6"
                  onClick={() => {
                    setShowTerms(true);
                    setTerms(!terms);
                  }}
                >
                  Terms and Conditions
                </span>
              </p>
            </div> */}
            {adminSettings && adminSettings?.recaptchaSiteKey && (
              <>
                <ReCaptchaVerificationBox
                  siteKey={adminSettings?.recaptchaSiteKey}
                  recaptchaVerified={recaptchaVerified}
                  adminSettings={adminSettings}
                  setRecaptchaVerified={setRecaptchaVerified}
                />

                {/* Submit button and Sign up loading */}
                <div className="button-wrapper w_100">
                  {authLoading ? (
                    <p className="sigining-up font_m font_grey text-center">
                      Signing you up
                    </p>
                  ) : (
                    <CustomButton
                      disable={!recaptchaVerified}
                      disabledClass="sign-up-disabled"
                      size="l"
                      className="custom-btn-2"
                      type="submit"
                    >
                      Sign Up
                      <img
                        src={ArrowPointRightBlack}
                        className="arrow icon"
                        alt=""
                      />
                    </CustomButton>
                  )}
                </div>
              </>
            )}
          </form>
        )}
      </Formik>

      {/* Modals */}
      {/* Success Modal after sign up*/}
      <SuccessModal
        showModal={showSignupSuccessModal}
        title="Signed Up"
        description="You have successfully signed up"
        onClose={onClosingModal}
      />

      {/* Mobile Verification OTP Phone modal */}
      <ConfirmationOTPModal
        // showModal={showMobileVerificationModal}
        showModal={showEmailVerificationModal}
        title="Enter Confirmation Code"
        size="lg"
        description="Enter the OTP that has been sent to your registered email"
        onVerify={onVerifyEmail}
        onCancel={onClosingModal}
      />
    </div>
  );
};
/* Component ends */

export default SignUpForm;
