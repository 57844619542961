/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { validations, intialValues } from "./helper";
import GLOBAL from "../../../../setup/constants/global";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  getCountries,
  getCities,
  getStates,
  fetchCountries,
  fetchStatesByCountryId,
  fetchCitiesByStateId,
} from "../../../../setup/store/slices/countrySlice";
import { addCommunity } from "../../../../setup/store/slices/communitySlice";
import { getUser, fetchUser } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AddCommunity = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [modalDesc, setModalDesc] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  // useRef
  const image = useRef(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  // Redux Selector
  const user = useSelector(getUser);
  const countries = useSelector(getCountries);
  const states = useSelector(getStates);
  const cities = useSelector(getCities);
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Loading all countries
  const loadCountries = async () => {
    await dispatch(fetchCountries());
  };

  // Loading all states according to selected country
  const loadStates = async () => {
    if (selectedCountry != null) {
      setSelectedState(null);
      setSelectedCity(null);
      await dispatch(
        fetchStatesByCountryId({ country_id: selectedCountry.id })
      );
    }
  };

  // Loading all cities according to selected state
  const loadCities = async () => {
    if (selectedState != null) {
      setSelectedCity(null);
      await dispatch(fetchCitiesByStateId({ state_id: selectedState.id }));
    }
  };

  // Form validations
  const formValidation = (values) => {
    return validations(values, image);
  };

  // On adding community
  const onSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      ...values,
      city: selectedCity?.name || "",
      state: selectedState?.name || "",
      country: selectedCountry?.name || "",
    };
    payload["image"] = image.current;

    // Backend Response. Try, Catch
    try {
      dispatch(setLoading(true));
      const result = await dispatch(addCommunity(payload));

      // Handling success respose
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setErrorModal(true);
          // navigate("/");
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Community added successfully");
          setSuccessModal(true);
          break;

        default:
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      // Handling error respose
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On initial load
  useEffect(() => {
    loadCountries();
    dispatch(fetchUser());
  }, []);

  // Setting selected country after loading countries
  useEffect(() => {
    if (countries?.length > 0 && user) {
      const defaultCountry = countries?.filter(
        (country) => country?.name === user?.country
      )[0];
      setSelectedCountry(defaultCountry);
    }
  }, [countries, user]);

  // Loading states accoring to country selected
  useEffect(() => {
    loadStates();
  }, [selectedCountry]);

  // Setting selected state after loading state
  useEffect(() => {
    if (states?.length > 0) {
      if (states?.length > 0 && user) {
        let defaultState = states?.filter(
          (state) => state?.name === user?.state
        )[0];

        setSelectedState(defaultState || states[0]);
      }
    }
  }, [states, user]);

  // Loading cities accoring to state selected
  useEffect(() => {
    loadCities();
  }, [selectedState]);

  // Setting selected city after loading cities
  useEffect(() => {
    if (cities?.length > 0 && user) {
      let defaultCity = cities?.filter((city) => city?.name === user?.city)[0];

      setSelectedCity(defaultCity || cities[0]);
    }
  }, [cities, user]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-community-wrapper-main container_sm">
      <h2 className="heading text-center font_xxl mb-5">Add Community</h2>

      {/* Add Community Form */}
      <Formik
        onSubmit={onSubmit}
        initialValues={intialValues}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-wrapper bg_grey">
              {/* Input for Community Name */}
              <div className="form-group form-group-full">
                <CustomTextInput
                  name="community_name"
                  label="Community Name"
                  value={values.community_name}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.community_name}
                  errors={errors.community_name}
                />
              </div>

              {/* Input for Address Line 1 */}
              <div className="form-group">
                <CustomTextInput
                  name="address_line1"
                  label="Address Line 1"
                  value={values.address_line1}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.address_line1}
                  errors={errors.address_line1}
                />
              </div>

              {/* Input for Address Line 2 */}
              <div className="form-group">
                <CustomTextInput
                  name="address_line2"
                  label="Address Line 2"
                  value={values.address_line2}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.address_line2}
                  errors={errors.address_line2}
                  required={false}
                />
              </div>

              {/* Input for City */}
              <div className="form-group">
                <CustomSelectInput
                  name="city"
                  label="City"
                  handleBlur={handleBlur}
                  onChange={handleChange}
                  valueExtractor={(item) => item?.name}
                  setValue={setSelectedCity}
                  options={cities}
                  value={selectedCity}
                  formikState={false}
                  emptyOption={false}
                />
              </div>

              {/* Input for State */}
              <div className="form-group">
                <CustomSelectInput
                  name="state"
                  label="State"
                  handleBlur={handleBlur}
                  onChange={handleChange}
                  valueExtractor={(item) => item?.name}
                  setValue={setSelectedState}
                  options={states}
                  value={selectedState}
                  formikState={false}
                  emptyOption={false}
                />
              </div>

              {/* Input for Country */}
              <div className="form-group">
                <CustomSelectInput
                  name="country"
                  label="Country"
                  handleBlur={handleBlur}
                  onChange={handleChange}
                  valueExtractor={(item) => item?.name}
                  setValue={setSelectedCountry}
                  options={countries}
                  value={selectedCountry}
                  formikState={false}
                  emptyOption={false}
                />
              </div>

              {/* Input for Image */}
              <div className="form-group">
                <CustomFileInput
                  name="image"
                  label="Image"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors.image}
                  touched={touched.image}
                  files={image}
                  formikState={false}
                />
              </div>
            </div>

            {/* Submit and Cancel button wrapper */}
            <div className="button-wrapper">
              <CustomButton type="submit" size="l">
                Add
              </CustomButton>
              <TextButton
                type="button"
                onClick={onCancel}
                className="fw_6 w_100 font_m"
              >
                Cancel
              </TextButton>
            </div>
          </form>
        )}
      </Formik>

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={successModal}
        title="Community Added"
        description={modalDesc}
        onClose={onSuccess}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={errorModal}
        title="Error"
        description={modalDesc}
        onClose={() => {
          setErrorModal(false);
          navigate("/");
        }}
      />
    </div>
  );
};
/* Component ends */

export default AddCommunity;
