/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../setup/constants/global";
import { Formik } from "formik";
import useReCaptcha from "../../setup/global_utility_hooks/useReCaptcha";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../setup/store/slices/globalAlertSlice";
import { getUserTheme } from "../../setup/store/slices/authSlice";
import { fetchSetting } from "../../setup/store/slices/settingSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import HumanVerification from "../../components/module/Auth/HumanVerification/HumanVerification";
import ConfirmationOTPModal from "../../components/ui/modal/ConfirmationOTPModal/ConfirmationOTPModal";
/* Import local pages and component ends */
import "./style.scss";

/* Component starts */
const RnDPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [adminSettings, setAdminSettings] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const dummyTenantList = [
    {
      _id: "123",
      name: "Ethan Carter",
      email: "carter@gmail.com",
      phone: "+1 9088767656",
    },
    {
      _id: "321",
      name: "Desmond Miles",
      email: "miles@gmail.com",
      phone: "+1 998787455",
    },
    {
      _id: "456",
      name: "Edward Kenway",
      email: "kenway@gmail.com",
      phone: "+1 665557465",
    },
  ];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchAdminSetting = async () => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchSetting());

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          // dispatch(
          //   showErrorAlert({
          //     title: "Error!",
          //     description:
          //       typeof result?.payload === "string" ? result?.payload : "",
          //   })
          // );
          setAdminSettings(null);
          break;

        case GLOBAL.FULFILLED:
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description:
          //       typeof result?.payload?.message === "string"
          //         ? result?.payload?.message
          //         : "",
          //   })
          // );
          setAdminSettings(result.payload);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="rnd-wrapper">
      <HumanVerification
        version="v2"
        onReset={(a) => {
          console.log("this is onReset");
        }}
        onVerified={(a) => {
          console.log("this is onVerified");
        }}
        onFailed={(a) => {
          console.log("this is onFailed");
        }}
        onExpired={(a) => {
          console.log("this is onFailed");
        }}
        type="invisible"
        action="login"
      />
    </div>
  );
};
/* Component ends */

export default RnDPage;
