// Importing pages starts
import {
  Dashboard,
  SignIn,
  SignUp,
  Home,
  AddProperty,
  PropertyDetail,
  UnitList,
  UnitDetails,
  EditUnit,
  AddUnits,
  AssignLicense,
  PurchaseLicensePage,
  SettingsPage,
  ManageLicense,
  ManageTeams,
  AddPMCPage,
  AddOwnerPage,
  SignInTeamInvite,
  SignUpTeamInvite,
  EditPropertyPage,
  AddPMPage,
  AddAccountantPage,
  EditPMCPage,
  EditPMPage,
  CommunityDetail,
  AddCommunityPage,
  AddFMCPage,
  EditFMCPage,
  AddFMPage,
  EditFMPage,
  AddMTPage,
  EditMTPage,
  AddSMCPage,
  EditSMCPage,
  AddSMPage,
  EditSMPage,
  AddSOPage,
  EditSOPage,
  ProfilePage,
  EditProfilePage,
  SelectManagementModelPage,
  ClientInvoiceListPage,
  ClientInvoiceReceiptPage,
  AddTenantPage,
  TenantSignIn,
  SigninOptionsPage,
  HomePageController,
  HomeTenant,
  TenantUnitDetailsPage,
  TenantOnboardPage,

  // TEST
  AddTestForm,
  ClientSecurity,
  AddSCFPage,
  SecurityIncidentReport,
  SecurityCompliantReport,
  TenantSecurity,
  AddTenantCFPage,
  TenantSecurityDetail,
  TenantLostAndFound,
  TenantLostAndFoundDetail,
  ClientLostAndFound,
  AddFoundPage,
  ClientFoundDetail,
  ClientLostDetail,
  AddTenantLostPage,
  ClientVoilations,
  AddViolationPage,
  VisitorLogBook,
  AddVisitorPage,
  VisitorsLogDetail,
  ManageTenantsPage,
  ClientVisitorAccessPass,
  VisitorAccessPassDetail,
  ContractorAccessPassDetail,
  NewTenantPMReviewPage,
  PaymentSettingPage,
  AddPaymentSettingPage,
  PropertySettingsPage,
  TenantDetailPage,
  VisitorSignOut,
  ManageMaintenancePage,
  InternalMaintenancePage,
  AddInternalMaintenancePage,
  TenantGuestPass,
  AddTenantGuestPass,
  EditGuestPass,
  TenantViolations,
  TenantMaintenance,
  AddTenantMaintenancePage,
  ClientClaimedDetail,
  MaintnanceDetailPage,
  RnDPage,
  AddTPCPage,
  AddTPMPage,
  AddTPTPage,
  RoutineRequest,
  AddTaskPage,
  RoutineTaskDetail,
  InternalCommunication,
  AddCommunicationPage,
  InternalCommunicationDetail,
  Advertising,
  Annoucements,
  AddAdvertismentPage,
  AddAnnoucementsPage,
  BlueprintsPage,
  AddBlueprintsPage,
  TenantRequest,
  AddTenantRequestPage,
  RatingAndReviews,
  AdvertismentDetail,
  ManageFacilitiesPage,
  AddParkingPage,
  AddFacilityPage,
  ManageFacilityDetail,
  MoveOutDetailsPage,
  ChangePasswordPage,
  EditParkingPage,
  OccupationDashboard,
  ContractDashboard,
  FinancialDashboard,
  MaintenanceInUnitDashboard,
  SecurityDashboard,
  SparePartsDashboard,
  RoutineDashboard,
  SharedFacilityDashboard,
  RatingsDashboard,
  TeamsDashboard,
  ViolationsDashboard,
  MoveDashboard,
  StaffScheduling,
  CreateSchedulePage,
  AddVacationsPage,
  AddHolidaysPage,
  ContractListPage,
  TenantShareFacility,
  TenantSharedFacilityDetail,
  MoveOutRequestPage,
  TenantGuestPassDetail,
  SecurityComplaintDetailPage,
  SecurityComplaintPage,
  MoveOutPMReviewPage,
  TenantMoveOutClearencePage,
  ViolationDetailedPage,
  InvoicingPage,
  InvoicingDetailPage,
  AddInvoicingPage,
  ContractRenewalPage,
  TenantInvoicingPage,
  TenantInvoicingDetailPage,
  TenantContractRenewalPage,
  AnnoucementDetailedPage,
  TenantAnnoucementPage,
  AccountantMoveOutClearencePage,
  EditCommunityPage,
  MaintenanceOutUnitDashboard,
  TenantMaintenanceDetailPage,
  InternalMaintenanceDetailsPage,
  MoveOutDashboard,
  ChangeEmailPage,
  ChangeMobilePage,
  NotificationPage,
  TenantSignUpPage,
  SignInTenantInvite,
  TransitionedTenantInstalmentPage,
  AutoPaymentSettingsPage,
  ThemeSettingPage,
  SignatureTemplateEditPage,
  ManageCardsPage,
  TenantGroundRules,
  HelpAndSupportPage,
  FAQsPage,
  CreateQuoatationPage,
  TermAndConditionPage,
  AccountSettingPage,
  TenantMoveOutDetailPage,
  VisitorAccessPassInfoPage,
  TestPage_1,
  TestPage_2,
  RoutineTaskDetailPage,
  EditTask,
  AddRoleUnderPMPage,
  AddRoleUnderOwnerPage,
  TenantPropertyTerms,
  TenantProppertyTerms,
} from "../../pages";

// Importing pages ends

const propertyDetailRoute =
  "/:underCommunity/:communityId?/properties/:propertyId";
const clientUnitDetail = propertyDetailRoute + "/units/:unitId";
const tenantUnitDetails = "/tenant/unit/:unitId";
const tenantApplicationDetails =
  "/tenant/unit/:unitId/application/:applicationId";
const tenantContractDetails = "/tenant/unit/:unitId/contracts/:contractId";
const tenantContractApplicationID =
  tenantContractDetails + "/application/:applicationId";
// Setting routes starts
const ROUTES = {
  // Public Routes **********************************************************************
  public: [
    // This page is for testing purpose
    {
      path: "/test/me/one/:testParam",
      component: TestPage_1,
      title: "test-me-one",
    },

    // This page is for testing purpose
    {
      path: "/test/me/two",
      component: TestPage_2,
      title: "test-me-two",
    },

    // For Research, and Development
    {
      path: "/rnd",
      component: RnDPage,
      title: "r-n-d",
    },

    // Sign Up User/Client
    {
      path: "/signup",
      component: SignUp,
      title: "signup",
    },

    // Sign In Options
    {
      path: "/signin",
      component: SignIn,
      title: "signin-options",
    },

    // Sign In User
    {
      path: "/signin/team",
      component: SignIn,
      title: "signin",
    },

    // Sign-up team by invitaion
    {
      path: "/signup/team/invite/:encodedToken",
      component: SignUpTeamInvite,
      title: "signup-team-invite",
    },

    // Sign-in team by invitaion
    {
      path: "/signin/team/invite/:encodedToken",
      component: SignInTeamInvite,
      title: "signin-team-invite",
    },

    // Sign-in tenant by invitaion
    {
      path: "/signin/tenant/invite/:encodedToken",
      component: SignInTenantInvite,
      title: "signin-tenant-invite",
    },

    // Forgot Password
    {
      path: "/password/forgot",
      component: ChangePasswordPage,
      title: "forgot-password",
    },

    // Tenant Sign Up
    {
      path: "/signup/tenant/:encodedToken",
      component: TenantSignUpPage,
      title: "tenant-sign-up",
    },

    // Shared visitor pass
    {
      path: "/shared_visitor_pass/:visitorPassID",
      component: VisitorAccessPassInfoPage,
      title: "shared-visitor-pass-page",
    },
  ],

  // Private Routes **********************************************************************
  private: [
    // Payment Settings Page *
    {
      path: "/quoatation-test",
      component: CreateQuoatationPage,
      title: "create-quatation-page",
    },

    // Payment Settings Page *
    {
      path: "/settings/manageCard",
      component: ManageCardsPage,
      title: "manage-cards-page",
    },

    // Payment Settings Page (Tenant) *
    {
      path: propertyDetailRoute + "/settings/:type/template/edit",
      component: SignatureTemplateEditPage,
      title: "signature-template-edit-page",
    },

    // Payment Settings Page (Tenant) *
    {
      path: "/settings/auto_payment",
      component: AutoPaymentSettingsPage,
      title: "tenant-auto-payment-settings",
    },

    // Accountant Moveout Clearence Page (Provider) *
    {
      path:
        propertyDetailRoute + "/tenants/move-out/:moveoutId/review/accountant",
      component: AccountantMoveOutClearencePage,
      title: "accountant-moveout-clearence-page",
    },

    // Owner Moveout Clearence Page (Provider) *
    {
      path: propertyDetailRoute + "/tenants/move-out/:moveoutId/review/owner",
      component: AccountantMoveOutClearencePage,
      title: "owner-moveout-clearence-page",
    },

    // Tenant Contract Renew Page (Tenant) *
    {
      path: tenantContractApplicationID + "/renew",
      component: TenantContractRenewalPage,
      title: "tenant-contract-renewal-page",
    },

    // Tenant Moveout Clearence Page (Provider) *
    {
      // path: tenantContractDetails + "/moveout/:moveoutID/clearence",
      path: propertyDetailRoute + "/tenants/renewals/:applicationID",
      component: ContractRenewalPage,
      title: "contract-renewal-page",
    },

    // Tenant Move Out Details
    {
      path: "/tenant/unit/:unitId/contracts/:contractId/moveout/:moveOutId",
      component: TenantMoveOutDetailPage,
      title: "tenant-move-out-detail-page",
    },

    // Tenant Select Move Out
    {
      path: "/tenant/unit/:unitId/contracts/:contractId/moveout/select_date",
      component: TenantMoveOutDetailPage,
      title: "tenant-move-out-date-select-page",
    },

    // Tenant Moveout Clearence Page (Tenant) *
    {
      path: tenantContractDetails + "/moveout/:moveoutID/clearence",
      component: TenantMoveOutClearencePage,
      title: "pm-review-moveout",
    },

    // Move Out PM Review Page (Provider) *
    {
      path: propertyDetailRoute + "/tenants/move-out/:moveoutId",
      component: MoveOutPMReviewPage,
      title: "pm-review-moveout",
    },

    // Maintenance Detail From Move Out PM Review Page (Provider) *
    {
      path:
        propertyDetailRoute + "/tenants/move-out/:moveoutId/maintenance/:id",
      component: MaintnanceDetailPage,
      title: "maintenance-detail-from-pm-review-move-out",
    },

    // Tenant Move Out Request (Tenant) *
    {
      path: "/tenant/unit/:unitId/contracts/:contractId/moveout",
      component: MoveOutRequestPage,
      title: "tenant-contract-moveout",
    },

    // // Tenant Contract Renewal Decline (Tenant) *
    // {
    //   path: "/tenant/contracts/:contractId/moveout",
    //   component: MoveOutRequestPage,
    //   title: "tenant-contract-renewal-decline",
    // },

    // Contract List Page (Tenant) *
    {
      path: "/tenant/unit/:unitId/contracts",
      component: ContractListPage,
      title: "tenant-contract",
    },

    // Change Password
    {
      path: "/settings/password/change",
      component: ChangePasswordPage,
      title: "forgot-password",
    },

    // Tenant Move Out Detail Page (Provider)
    {
      path: "/property/manage/tenants/moveout/:tenantId",
      component: MoveOutDetailsPage,
      title: "moveout-detail-page",
    },

    // Add Third Party Company Page (Provider) *
    {
      path: propertyDetailRoute + "/teams/tpc/add",
      component: AddTPCPage,
      title: "tpc-add",
    },

    // Add Third Party Manager (Provider) *
    {
      path: propertyDetailRoute + "/teams/tpc/:parentUserId/tpm/add",
      component: AddTPMPage,
      title: "tpm-add",
    },

    // Add Third Party Technician (Provider) *
    {
      path: propertyDetailRoute + "/teams/tpc/:parentUserId/tpt/add",
      component: AddTPTPage,
      title: "tpt-add",
    },

    // Maintenance Datails Page (Provider) *
    {
      path: propertyDetailRoute + "/maintenances/:tab/:id",
      component: MaintnanceDetailPage,
      title: "maintenance-detail",
    },

    // Property Setting Page (Provider)
    {
      path: propertyDetailRoute + "/settings",
      component: PropertySettingsPage,
      title: "property-setting",
    },

    // Payment Setting Add/Edit Page (Provider)
    {
      path: "/settings/payment/edit",
      component: AddPaymentSettingPage,
      title: "settings-payment-edit",
    },

    // Payment Setting Detail Page (Provider)
    {
      path: "/settings/payment",
      component: PaymentSettingPage,
      title: "settings-payment",
    },

    // Theme Setting Detail Page (Provider)
    {
      path: "/settings/theme",
      component: ThemeSettingPage,
      title: "settings-theme",
    },

    // Theme Setting Detail Page (Provider)
    {
      path: "/settings/account-suspension",
      component: AccountSettingPage,
      title: "settings-account-suspension",
    },

    // Help And Support Detail Page (Provider)
    {
      path: "/settings/helpAndSupport",
      component: HelpAndSupportPage,
      title: "settings-helpAndSupport",
    },

    // FAQs Detail Page (Provider)
    {
      path: "/settings/FAQs",
      component: FAQsPage,
      title: "settings-FAQs",
    },

    // Tenant Onboard (Tenant) *
    {
      path: tenantApplicationDetails,
      component: TenantOnboardPage,
      title: "tenant-onboard",
    },

    // Tenant Onboard (Tenant) TERM & CONDITON PAGE *
    {
      path:
        tenantApplicationDetails + "/:stepKey/:redirectToHome?/termAndConditon",
      component: TermAndConditionPage,
      title: "tenant-onboard-TermAndCondition",
    },

    // Tenant Home Page (Tenant) *
    {
      path: "/tenant/home",
      component: HomeTenant,
      title: "home-page-tenant",
    },

    // Home Page Controller
    {
      path: "/",
      component: HomePageController,
      title: "home-page-controller",
    },

    // Invite Tenant (Provider)
    {
      path: clientUnitDetail + "/tenant/add/invite",
      component: AddTenantPage,
      title: "tenant-add-invite",
    },

    // Invite Tenant (Provider)
    {
      path: propertyDetailRoute + "/tenants/add/invite",
      component: AddTenantPage,
      title: "tenant-add-invite",
    },

    // License Invoice Receipt (Provider) *
    {
      path: "/settings/manageLicense/invoices/:invoiceId",
      component: ClientInvoiceReceiptPage,
      title: "client-invoices-receipt",
    },

    // License Invoice List (Provider) *
    {
      path: "/settings/manageLicense/invoices",
      component: ClientInvoiceListPage,
      title: "client-invoices-list",
    },

    // Select Management Model (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/management_type/add",
      component: SelectManagementModelPage,
      title: "management-type-add",
    },

    // Edit Profile *
    {
      path: "/profile/edit",
      component: EditProfilePage,
      title: "profile-edit",
    },

    // Update Email *
    {
      path: "/profile/update-email",
      component: ChangeEmailPage,
      title: "profile-update-email",
    },

    // Update mobile Number *
    {
      path: "/profile/update-mobile",
      component: ChangeMobilePage,
      title: "profile-update-mobile",
    },

    // Profile *
    {
      path: "/profile",
      component: ProfilePage,
      title: "profile-detail",
    },

    // Edit Security Officer (Provider)
    {
      path: "/property/so/edit",
      component: EditSOPage,
      title: "so-edit",
    },

    // Add Security Officer (Provider) *
    {
      path: propertyDetailRoute + "/teams/sm/:parentUserId/so/add",
      component: AddSOPage,
      title: "so-add",
    },

    // Edit Security Manager (Provider)
    {
      path: "/property/sm/edit",
      component: EditSMPage,
      title: "sm-edit",
    },

    // Add Security Manager (Provider) *
    {
      path: propertyDetailRoute + "/teams/smc/:parentUserId/sm/add",
      component: AddSMPage,
      title: "sm-add",
    },

    // Edit Security Management Company (Provider)
    {
      path: "/property/smc/edit",
      component: EditSMCPage,
      title: "smc-edit",
    },

    // Add Security Management Company (Provider) *
    {
      path: propertyDetailRoute + "/teams/smc/add",
      component: AddSMCPage,
      title: "smc-add",
    },

    // Edit Maintenance Technician (Provider)
    {
      path: "/property/mt/edit",
      component: EditMTPage,
      title: "mt-edit",
    },

    // Add Maintenance Technician (Provider) *
    {
      path: propertyDetailRoute + "/teams/fm/:parentUserId/mt/add",
      component: AddMTPage,
      title: "mt-add",
    },

    // Edit Facility Manager (Provider)
    {
      path: "/property/fm/edit",
      component: EditFMPage,
      title: "fm-edit",
    },

    // Add Facility Manager (Provider) *
    {
      path: propertyDetailRoute + "/teams/fmc/:parentUserId/fm/add",
      component: AddFMPage,
      title: "fm-add",
    },

    // Edit Facility Management Company (Provider)
    {
      path: "/property/fmc/edit",
      component: EditFMCPage,
      title: "fmc-edit",
    },

    // Add Facility Management Company (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams/fmc/add",
      component: AddFMCPage,
      title: "fmc-add",
    },

    // Add Community Page (Provider) *
    {
      path: "/communities/add",
      component: AddCommunityPage,
      title: "community-add",
    },

    // Community Details Page (Provider) *
    {
      path: "/communities/:communityId",
      component: CommunityDetail,
      title: "community-detail",
    },

    // Community Edit Page (Provider)
    {
      path: "/communities/:communityId/edit",
      component: EditCommunityPage,
      title: "communities-edit",
    },

    // Edit Property Manager (Provider)
    {
      path: "/property/pm/edit",
      component: EditPMPage,
      title: "pm-edit",
    },

    // Add Accountant (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams/pmc/:parentUserId/accountant/add",
      component: AddAccountantPage,
      title: "accountant-add",
    },

    // Add Accountant T2 (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams/fmc/:parentUserId/accountant/add",
      component: AddAccountantPage,
      title: "accountant-add",
    },

    // Add Property Manager (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams/pmc/:parentUserId/pm/add",
      component: AddPMPage,
      title: "pm-add",
    },

    // Property Assistant
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams/pmc/:parentUserId/pa/add",
      component: AddRoleUnderPMPage,
      title: "propertyAssistant-add",
    },

    // Lease Officer
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams/pmc/:parentUserId/lo/add",
      component: AddRoleUnderPMPage,
      title: "leaseOfficer-add",
    },

    // Property Officer
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams/pmc/:parentUserId/po/add",
      component: AddRoleUnderPMPage,
      title: "propertyOfficer-add",
    },

    // Maintenance Officer
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams/pmc/:parentUserId/mo/add",
      component: AddRoleUnderPMPage,
      title: "maintenanceOfficer-add",
    },

    // Add Owner (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams/owner/add",
      component: AddOwnerPage,
      title: "owner-add",
    },

    // Add Owner Representative (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams/owner/:ownerId/:ownerSubRole/add",
      component: AddRoleUnderOwnerPage,
      title: "role-under-owner-add",
    },

    // Edit Property Management Company (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams/pmc/edit",
      component: EditPMCPage,
      title: "pmc-edit",
    },

    // Add Property Management Company (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams/pmc/add",
      component: AddPMCPage,
      title: "pmc-add",
    },

    // Manage Teams (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/teams",
      component: ManageTeams,
      title: "team-manage",
    },

    // Manage License (Provider) *
    {
      path: "/settings/manageLicense",
      component: ManageLicense,
      title: "license-manage",
    },

    // Settings Page *
    {
      path: "/settings",
      component: SettingsPage,
      title: "settings",
    },

    // Purchase License Page (Provider) *
    {
      path: "/settings/manageLicense/purchase",
      component: PurchaseLicensePage,
      title: "license-purchase",
    },

    // Assigning License Page (Provider) *
    {
      path: propertyDetailRoute + "/license/assign",
      component: AssignLicense,
      title: "license-assign",
    },

    // Adding Units Page (Provider) *
    {
      path: propertyDetailRoute + "/units/add",
      component: AddUnits,
      title: "unit-add",
    },

    // Edit Unit Page (Provider) *
    {
      path: propertyDetailRoute + "/units/:unitId/edit",
      component: EditUnit,
      title: "unit-edit",
    },

    // Unit Detail Page (Provider) *
    {
      path: propertyDetailRoute + "/units/:unitId",
      component: UnitDetails,
      title: "unit-detail",
    },

    // Unit Table Page (Provider) *
    {
      path: propertyDetailRoute + "/units",
      component: UnitList,
      title: "unit-list",
    },

    // Add Property Wizard Page (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/add",
      component: AddProperty,
      title: "add-property",
    },

    // Add Property Wizard Page Resume (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/add/resume/:propertyId",
      component: AddProperty,
      title: "add-property-resume",
    },

    // Partner/Teams/Provider Home Page
    {
      path: "/provider/home",
      component: Home,
      title: "home",
    },

    // Dashboard Page - { CLIENT PROVIDER }
    {
      path: "provider/home/dashboard",
      component: Dashboard,
      title: "dashboard",
    },

    // Occupation Dashboard  Page
    {
      path: "provider/home/dashboard/occupancy",
      component: OccupationDashboard,
      title: "dashboard-occupancy",
    },

    // Contracts Dashboard  Page
    {
      path: "provider/home/dashboard/contracts",
      component: ContractDashboard,
      title: "dashboard-contracts",
    },

    // Financial Dashboard  Page
    {
      path: "provider/home/dashboard/financial",
      component: FinancialDashboard,
      title: "dashboard-financial",
    },

    // Maintenance In Unit Dashboard  Page
    {
      path: "provider/home/dashboard/tenant-maintenance",
      component: MaintenanceInUnitDashboard,
      title: "dashboard-tenant-maintenance",
    },

    // Maintenance Out Unit Dashboard  Page
    {
      path: "provider/home/dashboard/internal-maintenance",
      component: MaintenanceOutUnitDashboard,
      title: "dashboard-internal-maintenance",
    },

    // Security Dashboard  Page
    {
      path: "provider/home/dashboard/security",
      component: SecurityDashboard,
      title: "dashboard-security",
    },

    // Spare Parts Dashboard  Page
    {
      path: "provider/home/dashboard/spare-parts",
      component: SparePartsDashboard,
      title: "dashboard-spare-parts",
    },

    // Routine Dashboard  Page
    {
      path: "provider/home/dashboard/routine",
      component: RoutineDashboard,
      title: "dashboard-routine",
    },

    // Shared Facilities Dashboard  Page
    {
      path: "provider/home/dashboard/shared-facilities",
      component: SharedFacilityDashboard,
      title: "dashboard-shared-facilities",
    },

    // Ratings Dashboard  Page
    {
      path: "provider/home/dashboard/ratings",
      component: RatingsDashboard,
      title: "dashboard-ratings",
    },

    // Teams Dashboard  Page
    {
      path: "provider/home/dashboard/teams",
      component: TeamsDashboard,
      title: "dashboard-teams",
    },

    // Violations Dashboard  Page
    {
      path: "provider/home/dashboard/violations",
      component: ViolationsDashboard,
      title: "dashboard-violations",
    },

    // Move IN Dashboard  Page
    {
      path: "provider/home/dashboard/move-in",
      component: MoveDashboard,
      title: "dashboard-move-in",
    },

    // Move OUT Dashboard  Page
    {
      path: "provider/home/dashboard/move-out",
      component: MoveOutDashboard,
      title: "dashboard-move-out",
    },

    // Dashboard Page - { COMMUNITY }
    {
      path: "/:underCommunity/:communityId?/dashboard",
      component: Dashboard,
      title: "dashboard",
    },

    // Occupation Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/occupancy",
      component: OccupationDashboard,
      title: "dashboard-occupancy",
    },

    // Contracts Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/contracts",
      component: ContractDashboard,
      title: "dashboard-contracts",
    },

    // Financial Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/financial",
      component: FinancialDashboard,
      title: "dashboard-financial",
    },

    // Maintenance In Unit Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/tenant-maintenance",
      component: MaintenanceInUnitDashboard,
      title: "dashboard-tenant-maintenance",
    },

    // Maintenance Out Unit Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/internal-maintenance",
      component: MaintenanceOutUnitDashboard,
      title: "dashboard-internal-maintenance",
    },

    // Security Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/security",
      component: SecurityDashboard,
      title: "dashboard-security",
    },

    // Spare Parts Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/spare-parts",
      component: SparePartsDashboard,
      title: "dashboard-spare-parts",
    },

    // Routine Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/routine",
      component: RoutineDashboard,
      title: "dashboard-routine",
    },

    // Shared Facilities Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/shared-facilities",
      component: SharedFacilityDashboard,
      title: "dashboard-shared-facilities",
    },

    // Ratings Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/ratings",
      component: RatingsDashboard,
      title: "dashboard-ratings",
    },

    // Teams Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/teams",
      component: TeamsDashboard,
      title: "dashboard-teams",
    },

    // Violations Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/violations",
      component: ViolationsDashboard,
      title: "dashboard-violations",
    },

    // Move IN Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/move-in",
      component: MoveDashboard,
      title: "dashboard-move-in",
    },

    // Move OUT Dashboard  Page
    {
      path: "/:underCommunity/:communityId?/dashboard/move-out",
      component: MoveOutDashboard,
      title: "dashboard-move-out",
    },

    // Dashboard Page - { PROPERTY } /:underCommunity/:communityId?/annoucements
    {
      path: propertyDetailRoute + "/dashboard",
      component: Dashboard,
      title: "dashboard",
    },

    // Occupation Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/occupancy",
      component: OccupationDashboard,
      title: "dashboard-occupancy",
    },

    // Contracts Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/contracts",
      component: ContractDashboard,
      title: "dashboard-contracts",
    },

    // Financial Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/financial",
      component: FinancialDashboard,
      title: "dashboard-financial",
    },

    // Maintenance In Unit Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/tenant-maintenance",
      component: MaintenanceInUnitDashboard,
      title: "dashboard-tenant-maintenance",
    },

    // Maintenance Out Unit Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/internal-maintenance",
      component: MaintenanceOutUnitDashboard,
      title: "dashboard-internal-maintenance",
    },

    // Security Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/security",
      component: SecurityDashboard,
      title: "dashboard-security",
    },

    // Spare Parts Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/spare-parts",
      component: SparePartsDashboard,
      title: "dashboard-spare-parts",
    },

    // Routine Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/routine",
      component: RoutineDashboard,
      title: "dashboard-routine",
    },

    // Shared Facilities Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/shared-facilities",
      component: SharedFacilityDashboard,
      title: "dashboard-shared-facilities",
    },

    // Ratings Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/ratings",
      component: RatingsDashboard,
      title: "dashboard-ratings",
    },

    // Teams Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/teams",
      component: TeamsDashboard,
      title: "dashboard-teams",
    },

    // Violations Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/violations",
      component: ViolationsDashboard,
      title: "dashboard-violations",
    },

    // Move IN Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/move-in",
      component: MoveDashboard,
      title: "dashboard-move",
    },

    // Move OUT Dashboard  Page
    {
      path: propertyDetailRoute + "/dashboard/move-out",
      component: MoveOutDashboard,
      title: "dashboard-move",
    },

    // Property Edit Page (Provider)
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId/edit",
      component: EditPropertyPage,
      title: "property-edit",
    },

    // Property Detail Page (Provider) *
    {
      path: "/:underCommunity/:communityId?/properties/:propertyId",
      component: PropertyDetail,
      title: "property-detail",
    },

    // TESTING Route --> Add Test Form

    {
      path: "/provider/home/addTest",
      component: AddTestForm,
      title: "test-form",
    },

    // Manage Security (Provider) *
    {
      path:
        propertyDetailRoute + "/security/:securityListTab/:securityListSubTab",
      // /:securityListTab/:securityListSubTab",
      ///:securityListTab/:securityListSubTab
      component: ClientSecurity,
      title: "security-list",
    },

    // Add Security Incident (Provider) *
    {
      path:
        propertyDetailRoute +
        "/security/:securityListTab/:securityListSubTab/add",
      component: AddSCFPage,
      title: "security-incident-add",
    },

    // View Security Incident Report (Provider) *
    {
      path: propertyDetailRoute + "/security/incidents/:securityListSubTab/:id",
      component: SecurityIncidentReport,
      title: "security-incident-report-view",
    },

    // View Security Complaint Report (Provider) *
    // {
    //   path:
    //     propertyDetailRoute +
    //     "/securities/:securityListTab/:securityListSubTab/:id",
    //   component: SecurityCompliantReport,
    //   title: "security-complaint-report-view",
    // },

    // View Security Complaint Report (Provider) * TRY
    {
      path:
        propertyDetailRoute + "/security/complaints/:securityListSubTab/:id",
      component: SecurityComplaintPage,
      title: "security-complaint-report-view",
    },

    {
      path: propertyDetailRoute + "/security/closed/complaints/:id",
      component: SecurityComplaintPage,
      title: "security-closed-complaint-report-view",
    },

    {
      path: propertyDetailRoute + "/security/closed/incidents/:id",
      component: SecurityIncidentReport,
      title: "security-closed-incident-report-view",
    },

    // Manage Security By Tenant ( Tenant ) *
    {
      path: tenantUnitDetails + "/security_complains",
      component: TenantSecurity,
      title: "security-tenant-list",
    },

    // Manage Security By Tenant Add Complain FORM ( Tenant ) *
    {
      path: tenantUnitDetails + "/security_complains/add",
      component: AddTenantCFPage,
      title: "security-tenant-complain-form",
    },

    // View Security Complain Details ( Tenant ) *
    {
      path: tenantUnitDetails + "/security_complains/:complainId",
      component: TenantSecurityDetail,
      title: "tenant-security-report-view",
    },

    // Tenant Lost And Found Module ( Tenant ) *
    {
      path: tenantUnitDetails + "/lost_and_found/:lostAndFoundListTab?",
      component: TenantLostAndFound,
      title: "tenant-lost-and-found",
    },

    // Tenant Lost And Found Module --> Add Lost Form ( Tenant ) *
    {
      path: tenantUnitDetails + "/lost_and_found/lost/add",
      component: AddTenantLostPage,
      title: "tenant-lost-add",
    },

    // View Lost And Found Details ( Tenant ) *
    {
      path: tenantUnitDetails + "/lost_and_found/lost/:lostId",
      component: TenantLostAndFoundDetail,
      title: "tenant-lostAndFound-view",
    },

    // Client Lost And Found Module (Provider) *
    {
      path: propertyDetailRoute + "/lost_and_found/:tab?",
      component: ClientLostAndFound,
      title: "client-lost-and-found",
    },

    // Client Lost And Found Module --> Add Found (Provider) *
    {
      path: propertyDetailRoute + "/lost_and_found/:tab?/add",
      component: AddFoundPage,
      title: "client-found-add",
    },

    // View Client Found Details (Provider) *
    {
      path: propertyDetailRoute + "/lost_and_found/found/:id",
      component: ClientFoundDetail,
      title: "client-found-view",
    },

    // View Client Lost Details (Provider) *
    {
      path: propertyDetailRoute + "/lost_and_found/lost/:id",
      component: ClientLostDetail,
      title: "client-lost-view",
    },

    // View Client Claimed Details (Provider) *
    {
      path: propertyDetailRoute + "/lost_and_found/claimed/:id",
      component: ClientClaimedDetail,
      title: "client-claim-view",
    },

    // Client Voilations  Module (Provider) *
    {
      path: propertyDetailRoute + "/violations/:tab?",
      component: ClientVoilations,
      title: "client-voilations",
    },

    // Client Voilations  Module --> Add Voilation (Provider) *
    {
      path: propertyDetailRoute + "/violations/:tab?/add",
      component: AddViolationPage,
      title: "client-voilations-add",
    },

    // Client Voilations  Module --> GET Voilation (Provider) *
    {
      path: propertyDetailRoute + "/violations/:tab?/:id",
      component: ViolationDetailedPage,
      title: "client-voilations-get-id",
    },

    // Tenant Violation Module (Tenant) *
    {
      path: tenantUnitDetails + "/violations",
      component: TenantViolations,
      title: "tenant-violations-list",
    },

    // Tenant Voilations  Module --> GET Voilation (Provider) *
    {
      path: tenantUnitDetails + "/violations/:id",
      component: ViolationDetailedPage,
      title: "tenant-voilations-get-id",
    },

    // Client Visitor Log Book  Module (Provider) *
    {
      path: propertyDetailRoute + "/visitor-logs",
      component: VisitorLogBook,
      title: "client-visitor-log-book",
    },

    // Client Visitor Log Book  Module --> Add (Provider) *
    {
      path: propertyDetailRoute + "/visitor-logs/add",
      component: AddVisitorPage,
      title: "client-visitor-log-book-add",
    },

    // View Client Visitors Log Details (Provider) *
    {
      path: propertyDetailRoute + "/visitor-logs/:id",
      component: VisitorsLogDetail,
      title: "client-visitor-view",
    },

    // View Client Visitors Sign Out (Provider) *
    {
      path: propertyDetailRoute + "/visitor-logs/sign-out/:id",
      component: VisitorSignOut,
      title: "client-visitor-view-sign-out",
    },

    // Manage Tenants Page (Provider) *
    {
      path: propertyDetailRoute + "/tenants/:tab?",
      component: ManageTenantsPage,
      title: "manage-tenants-page",
    },

    // Manage Visitors Access Pass Client (Provider) *
    {
      path: propertyDetailRoute + "/access_pass/:tab?",
      component: ClientVisitorAccessPass,
      title: "manage-access-pass",
    },

    // View Client Visitor Access Pass Details (Provider) *
    {
      path: propertyDetailRoute + "/access_pass/visitors/:id",
      component: VisitorAccessPassDetail,
      title: "client-access-pass-Visitor-view",
    },

    // View Client Contractor Access Pass Details (Provider) *
    {
      path: propertyDetailRoute + "/access_pass/contractors/:id",
      component: ContractorAccessPassDetail,
      title: "client-access-pass-Contractor-view",
    },

    // New Tenants PM Review Page (Provider) *
    {
      path: propertyDetailRoute + "/tenants/new/:applicationId",
      component: NewTenantPMReviewPage,
      title: "new-tenant-pm-review",
    },

    // // Tenant Details Screen Page (Provider)
    // {
    //   path: "/property/manage/tenants/detail",
    //   component: TenantDetailPage,
    //   title: "tenant-detail",
    // },

    // Manage Tenants Page VIEW PAGE {TEST} (Provider) *
    {
      path: propertyDetailRoute + "/tenants/:tab/detail/:tenantId/unit/:unitId",
      component: TenantDetailPage,
      title: "manage-tenants-view-page",
    },

    // Transitioned Tenant Installment Create Page (Provider) *
    {
      path: propertyDetailRoute + "/tenants/transitioned/:applicationId",
      component: TransitionedTenantInstalmentPage,
      title: "transitioned-tenants-installment-view-page",
    },

    // New Tenants PM Review Cancelled Page (Provider) *
    {
      path: propertyDetailRoute + "/tenants/cancelled/:applicationId",
      component: NewTenantPMReviewPage,
      title: "new-tenant-pm-review",
    },

    // Manage Maintenance (Provider) *
    {
      path: propertyDetailRoute + "/maintenances/:tab?",
      component: ManageMaintenancePage,
      title: "manage-maintenance",
    },

    // Internal Maintenance Request (Provider)
    {
      path: propertyDetailRoute + "/internal/maintenances/:tab?",
      component: InternalMaintenancePage,
      title: "internal-maintenance",
    },

    // Internal Maintenance Request (Provider)
    {
      path: propertyDetailRoute + "/internal/maintenances/:tab?/:maintenanceId",
      component: InternalMaintenanceDetailsPage,
      title: "internal-maintenance-details",
    },

    // ADD Internal Maintenance Request (Provider)
    {
      path: propertyDetailRoute + "/internal/maintenances/:tab?/add",
      component: AddInternalMaintenancePage,
      title: "internal-maintenance-add",
    },

    // Tenant Guest Pass Module ( Tenant ) *
    {
      path: tenantUnitDetails + "/guest-passes",
      component: TenantGuestPass,
      title: "tenant-guest-pass-list",
    },

    // Tenant Guest Pass --> ADD GUEST PASS FORM ( Tenant ) *
    {
      path: tenantUnitDetails + "/guest-passes/add",
      component: AddTenantGuestPass,
      title: "tenant-guest-pass-add",
    },

    // Tenant Guest Pass View ( Tenant ) *
    {
      path: tenantUnitDetails + "/guest-passes/:passId",
      component: TenantGuestPassDetail,
      title: "tenant-guest-pass-add",
    },

    // Tenant Guest Pass --> EDIT GUEST PASS FORM ( Tenant )
    {
      path: tenantUnitDetails + "/guest-passes/:passId/edit",
      component: EditGuestPass,
      title: "tenant-guest-pass-edit",
    },

    // Tenant Maintenance Module ( Tenant ) *
    {
      path: tenantUnitDetails + "/maintenance_requests",
      component: TenantMaintenance,
      title: "tenant-maintenance-list",
    },

    // Tenant Maintenance Detail ( Tenant ) *
    {
      path: tenantUnitDetails + "/maintenance_requests/:maintenanceId",
      component: TenantMaintenanceDetailPage,
      title: "tenant-maintenance-details",
    },

    // Tenant Maintenance --> ADD Maintenance Request FORM (Tenant) *
    {
      path: tenantUnitDetails + "/maintenance_requests/add",
      component: AddTenantMaintenancePage,
      title: "tenant-maintenance-add",
    },

    // Tenant Shared Facility Module (Tenant)
    {
      path: "/tenant/facilities/list",
      component: TenantShareFacility,
      title: "tenant-facilities-list",
    },

    // Tenant Shared Facility Module (Tenant)
    {
      path: "/tenant/facilities/detail",
      component: TenantSharedFacilityDetail,
      title: "tenant-facilities-detail",
    },

    // Routine Request (Provider)
    {
      // path: propertyDetailRoute + "/internal/maintenances/:tab?",
      path: propertyDetailRoute + "/routine-request/:tab/:subTab",
      component: RoutineRequest,
      title: "routine-request",
    },

    // ADD Routine Request Task (Provider)
    {
      path: propertyDetailRoute + "/routine-request/:tab?/:subTab?/add",
      component: AddTaskPage,
      title: "routine-request-add",
    },

    // Edit Routine Request Task (Provider)
    {
      path:
        propertyDetailRoute + "/routine-request/:tab?/:subTab?/:taskId/edit",
      component: EditTask,
      title: "routine-request-edit",
    },

    // Routine Task Detail Page (Provider)
    // {
    //   path: propertyDetailRoute +  "/routine-request/:tab?/:subTab?/:taskId",
    //   component: RoutineTaskDetail,
    //   title: "routine-request-detail",
    // },

    {
      path: propertyDetailRoute + "/routine-request/:tab?/:subTab?/:taskId",
      component: RoutineTaskDetailPage,
      title: "routine-request-detail",
    },

    // Internal Communication Page (Provider)
    {
      path: "/property/internal-communication/list",
      component: InternalCommunication,
      title: "internal-communication",
    },

    // ADD Internal Communication (Provider)
    {
      path: "/property/internal-communication/add",
      component: AddCommunicationPage,
      title: "internal-communication-add",
    },

    // Internal Communication Detail Page (Provider)
    {
      path: "/property/internal-communication/detail",
      component: InternalCommunicationDetail,
      title: "internal-communication-detail",
    },

    // Advertisments Page (Provider)
    {
      path: propertyDetailRoute + "/advertisments",
      component: Advertising,
      title: "advertising",
    },

    // ADD Advertisment (Provider)
    {
      path: propertyDetailRoute + "/advertising/add",
      component: AddAdvertismentPage,
      title: "advertising-add",
    },

    // Advertisment Detail Page (Provider)
    {
      path: propertyDetailRoute + "/advertising/detail",
      component: AdvertismentDetail,
      title: "advertising-detail",
    },

    // Annoucements Page (Provider) *
    {
      path: propertyDetailRoute + "/annoucements",
      component: Annoucements,
      title: "annoucements",
    },

    // Annoucement  Module --> GET Annoucement (Provider) *
    {
      path: propertyDetailRoute + "/annoucements/:id",
      component: AnnoucementDetailedPage,
      title: "annoucement-property-get-id",
    },

    // Home Annoucements Page (Provider) *
    {
      path: "provider/home/annoucements",
      component: Annoucements,
      title: "home-annoucements",
    },

    // Home Annoucement  Module --> GET Annoucement (Home) *
    {
      path: "provider/home/annoucements/:id",
      component: AnnoucementDetailedPage,
      title: "annoucement-home-get-id",
    },

    // Community Annoucements Page (Provider) *
    {
      path: "/:underCommunity/:communityId?/annoucements",
      component: Annoucements,
      title: "community-annoucements",
    },

    // Home Annoucement  Module --> GET Annoucement (Home) *
    {
      path: "/:underCommunity/:communityId?/annoucements/:id",
      component: AnnoucementDetailedPage,
      title: "annoucement-community-get-id",
    },

    // ADD Annoucements (Provider) *
    {
      path: propertyDetailRoute + "/annoucements/add",
      component: AddAnnoucementsPage,
      title: "annoucements-add",
    },

    // ADD HOME Annoucements (Provider) *
    {
      path: "provider/home/annoucements/add",
      component: AddAnnoucementsPage,
      title: "home-annoucements-add",
    },

    // Community Annoucements Page (Provider) *
    {
      path: "/:underCommunity/:communityId?/annoucements/add",
      component: AddAnnoucementsPage,
      title: "community-annoucements-add",
    },

    // Annoucement  Module --> (Tenant) *
    {
      path: tenantUnitDetails + "/annoucements",
      component: TenantAnnoucementPage,
      title: "tenant-annoucement",
    },

    // Tenant Annoucement View ( Tenant ) *
    {
      path: tenantUnitDetails + "/annoucements/:id",
      component: AnnoucementDetailedPage,
      title: "tenant-annoucements-id",
    },

    // Blue Prints Page (Provider)
    {
      path: "/property/blueprints/list",
      component: BlueprintsPage,
      title: "blueprints",
    },

    // ADD Blue Prints (Provider)
    {
      path: "/property/blueprints/add",
      component: AddBlueprintsPage,
      title: "blueprints-add",
    },

    // Tenant Request Module (Tenant) *
    {
      path: tenantUnitDetails + "/requests",
      component: TenantRequest,
      title: "tenant-request-list",
    },

    // Tenant Request --> ADD Request FORM (Tenant) *
    {
      path: tenantUnitDetails + "/requests/add",
      component: AddTenantRequestPage,
      title: "tenant-request-add",
    },

    // Rating And Reviews Page (Provider)
    {
      path: propertyDetailRoute + "/ratingAndReviews/:tab",
      component: RatingAndReviews,
      title: "ratingAndReviews",
    },

    // Manage Facilities Page (Provider)
    {
      path: "/property/manage-facilities/list",
      component: ManageFacilitiesPage,
      title: "manage-facilities",
    },

    // ADD Parking Page (Provider)
    {
      path: "/property/manage-facilities/add-parking",
      component: AddParkingPage,
      title: "manage-facilities-add-parking",
    },

    // EDIT Parking Page (Provider)
    {
      path: "/property/manage-facilities/edit-parking",
      component: EditParkingPage,
      title: "manage-facilities-edit-parking",
    },

    // ADD Facility Page (Provider)
    {
      path: "/property/manage-facilities/add-facility",
      component: AddFacilityPage,
      title: "manage-facilities-add-facility",
    },

    // Advertisment Detail Page (Provider)
    {
      path: "/property/manage-facilities/detail",
      component: ManageFacilityDetail,
      title: "manage-facilities-detail",
    },

    // Staff Scheduling Page (Provider)
    {
      path: "/property/staff-scheduling",
      component: StaffScheduling,
      title: "staff-scheduling",
    },

    // Create Schedule - Staff Scheduling Page (Provider)
    {
      path: "/property/staff-scheduling/create-schedule",
      component: CreateSchedulePage,
      title: "staff-scheduling-create",
    },

    // Add Vacations - Staff Scheduling Page (Provider)
    {
      path: "/property/staff-scheduling/add-vacation",
      component: AddVacationsPage,
      title: "staff-scheduling-add-vacation",
    },

    // Add Holidays - Staff Scheduling Page (Provider)
    {
      path: "/property/staff-scheduling/add-holidays",
      component: AddHolidaysPage,
      title: "staff-scheduling-add-holidays",
    },

    // INVOICING MODULE LIST - CLIENT (Provider) *
    {
      path: propertyDetailRoute + "/invoicing/:tab/:subTab",
      component: InvoicingPage,
      title: "client-invoicing",
    },

    // View Invoicing Detail Report Based On ID (Provider) *
    {
      path:
        propertyDetailRoute +
        "/invoicing/:invoicingListTab?/:invoicingListSubTab?/:invoiceID",
      component: InvoicingDetailPage,
      title: "client-invoicing-detail-view",
    },

    // Tenant Invoice Details from Onboarding Application Review (Provider) *
    {
      path:
        propertyDetailRoute +
        "/tenants/new/:applicationId/:invoicingListTab?/:invoicingListSubTab?/:invoiceID",
      component: InvoicingDetailPage,
      title: "client-invoicing-detail-view-from-onboarding-application-review",
    },

    // Tenant Invoice Details from Accountant Clearence Review (Provider) *
    {
      path:
        propertyDetailRoute +
        "/tenants/move-out/:moveoutId/review/accountant/invoicing/:invoicingListTab?/:invoicingListSubTab?/:invoiceID",
      component: InvoicingDetailPage,
      title: "client-invoicing-detail-view-from-accountant-clearence-review",
    },

    // Tenant Invoice Details from Owner Clearence Review (Provider) *
    {
      path:
        propertyDetailRoute +
        "/tenants/move-out/:moveoutId/review/owner/invoicing/:invoicingListTab?/:invoicingListSubTab?/:invoiceID",
      component: InvoicingDetailPage,
      title: "client-invoicing-detail-view-from-accountant-clearence-review",
    },

    // Add Invoice From Tenants Details (Provider) *
    {
      path:
        propertyDetailRoute +
        "/tenants/:tab/detail/:tenantId/unit/:unitId/invoice/add",
      component: AddInvoicingPage,
      title: "create-invoice-from-tenant-detail",
    },

    // ADD INVOICING - CLIENT (Provider) *
    {
      path: propertyDetailRoute + "/invoicing/:tab/:subTab/add",
      component: AddInvoicingPage,
      title: "client-invoicing-add",
    },

    // ADD INVOICING from accountant clearence - CLIENT (Provider) *
    {
      path:
        propertyDetailRoute +
        "/tenants/move-out/:moveoutId/review/accountant/invoicing/add",
      component: AddInvoicingPage,
      title: "client-invoicing-add-from-accountant-clearence-review",
    },

    // ADD INVOICING from owner clearence - CLIENT (Provider) *
    {
      path:
        propertyDetailRoute +
        "/tenants/move-out/:moveoutId/review/owner/invoicing/add",
      component: AddInvoicingPage,
      title: "client-invoicing-add-from-owner-clearence-review",
    },

    // INVOICING MODULE LIST - TENANT (TENANT) *
    {
      path: tenantUnitDetails + "/invoicing/:tab?",
      component: TenantInvoicingPage,
      title: "tenant-invoicing",
    },

    // INVOICING VIEW BASED ON ID - TENANT (TENANT) *
    {
      path: tenantUnitDetails + "/invoicing/:tab?/:id",
      component: TenantInvoicingDetailPage,
      title: "tenant-invoicing-view",
    },

    // INVOICING VIEW BASED ON ID FROM CONTRACT LIST PAGE - TENANT (TENANT) *
    {
      path: "/tenant/unit/:unitId/contracts/invoicing/:id",
      component: TenantInvoicingDetailPage,
      title: "tenant-invoicing-view-from-contract-list-page",
    },

    // For Research, and Development
    {
      path: "/notification",
      component: NotificationPage,
      title: "notification",
    },

    // Tenant Ground Rules Of Unit Module (Tenant) *
    {
      path: tenantUnitDetails + "/ground_rules" + "/:property_id",
      component: TenantGroundRules,
      title: "tenant-ground-rules",
    },

    // Tenant Ground Rules Of Unit Module (Tenant) *
    {
      path: tenantUnitDetails + "/property_terms" + "/:property_id",
      component: TenantProppertyTerms,
      title: "property_terms",
    },
  ],
};
// Setting routes starts

export default ROUTES;
