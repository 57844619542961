/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomCheckInput from "../../../components/ui/input/CustomCheckInput/CustomCheckInput";
import HumanVerification from "../../../components/module/Auth/HumanVerification/HumanVerification";
/* Import local pages and component ends */

import "../style.scss";

/* Component starts */
const ReCaptchaVerificationBox = (props) => {
  /* Props destructuring starts */
  const { adminSettings, setRecaptchaVerified, siteKey, recaptchaVerified } =
    props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [checked, setChecked] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="sign-up-bot-verification">

      <HumanVerification
        version="v2"
        siteKey={adminSettings?.recaptchaSiteKey}
        type="invisible"
        action="login"
        initiateVerificationButton={
          <div className="verification-check-wrapper w_100">
            <CustomCheckInput
              checked={recaptchaVerified}
              disabled={recaptchaVerified}
            />
            <p className="font_m fw_4">I am not a robot.</p>
          </div>
        }
        onVerified={() => {
          setRecaptchaVerified(true);
        }}
      />
    </div>
  );
};
/* Component ends */

export default ReCaptchaVerificationBox;
