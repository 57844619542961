/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { initialSteps, ACTION_REQ_SECTION } from "./helper";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import BasicDetailForm from "../../Tenant/BasicDetailForm/BasicDetailForm";
import CommercialDetailForm from "../../Tenant/CommercialDetailForm/CommercialDetailForm";
import IDVerificationForm from "../../Tenant/IDVerificationForm/IDVerificationForm";
import HouseholdMembersForm from "../../Tenant/HouseholdMembersForm/HouseholdMembersForm";
import DisabilityForm from "../../Tenant/DisabilityForm/DisabilityForm";
import VehiclesForm from "../../Tenant/VehicleForm/VehiclesForm";
import PetsForm from "../../Tenant/PetsForm/PetsForm";
import RentalHistoryForm from "../../Tenant/RentalHistoryForm/RentalHistoryForm";
import ReviewTenantApplicaion from "../../Tenant/ReviewTenantApplicaion/ReviewTenantApplicaion";
import UnderReview from "./sub-component/UnderReview";
import ProgressSteps from "../../../ui/other/ProgressSteps/ProgressSteps";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantInfoUpdate = (props) => {
  /* Props destructuring starts */
  const {
    isUnitCommercial,
    application_id,
    application,
    fetchContractDetails,
    info_sequence,
    currentStepPrev,
    onCancelRenewal,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [updateInfo, setUpdateInfo] = useState(false);
  const [currentStep, setCurrentStep] = useState();
  const [applicationUnderReview, setApplicationUnderReview] = useState(false);
  const [infoSteps, setInfoSteps] = useState(null);

  const [sequences, setSequences] = useState([]);
  const [sequenceNumber, setSequenceNumber] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onStepSuccess = () => {
    let currStepIdx = application?.info_sequence.indexOf(currentStep);
    currStepIdx += 1;

    try {
      if (currStepIdx === application?.info_sequence?.length) {
        fetchContractDetails();
        setUpdateInfo(false);
      } else {
        setCurrentStep(application?.info_sequence[currStepIdx]);
      }
    } catch (e) {}
  };

  const onCancelStep = () => {
    fetchContractDetails();
    setUpdateInfo(false);
  };

  const initializeSequenceNumber = (data) => {
    let s = [];
    const isResidential = data?.is_unit_residential;
    const isCommercial = !data?.is_unit_residential;

    s.push("basic_details");
    if (isCommercial) {
      s.push("commercial_details");
    }
    if (data?.ids) {
      s.push("ids");
    }
    if (isResidential && data?.household_members) {
      s.push("household_members");
    }
    if (data?.disability) {
      s.push("disability");
    }
    if (data?.vehicles) {
      s.push("vehicles");
    }
    if (data?.pets) {
      s.push("pets");
    }
    if (data?.rental_history?.length > 0) {
      s.push("rental_history");
    }
    // if (data?.application_fee) {
    //   s.push("application_fee");
    // }

    setSequenceNumber(s);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (application) {
      switch (application.application.status) {
        case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_REVIEW:
          setApplicationUnderReview(true);
          break;

        case "reservor-data-rejected":
          if (application.info_sequence[0] === "review_again") {
            setApplicationUnderReview(false);
            setUpdateInfo(false);
          } else {
            setApplicationUnderReview(false);
            setUpdateInfo(true);
          }
          break;

          default:
      }
      setCurrentStep(application.info_sequence[0]);
    }
  }, [application]);

  useEffect(() => {
    if (application && info_sequence.length > 0) {
      const seq = {};
      info_sequence.forEach((item) => {
        switch (item) {
          case "basic_details":
            seq[item] = "Basic Details";
            break;

          case "commercial_details":
            seq[item] = "Commercial Details";
            break;

          case "ids":
            seq[item] = "ID";
            break;

          case "household_members":
            seq[item] = "Household Members";
            break;

          case "disability":
            seq[item] = "Disability";
            break;

          case "vehicles":
            seq[item] = "Vehicles";
            break;

          case "pets":
            seq[item] = "Pets";
            break;

          case "rental_history":
            seq[item] = "Rental History";
            break;

          default:
        }
      });
      setInfoSteps(seq);
      setSequences(info_sequence);
      initializeSequenceNumber(application);
    } else {
      setInfoSteps(null);
    }
  }, [application, info_sequence]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (applicationUnderReview) {
    return <UnderReview onCancelRenewal={onCancelRenewal} />;
  }

  if (!updateInfo && application && !applicationUnderReview) {
    return (
      <div className="container_md review_tenant_renewal_application">
        <ReviewTenantApplicaion
          application_id={application_id}
          onNext={onStepSuccess}
          data={application}
          isUnitCommercial={isUnitCommercial}
          tenantType={GLOBAL.TENANT_TYPE.NEW}
          acceptApplicationFee={false}
          canUpdate={!application.application_data_rejected}
          propertySetting={application?.property_settings}
          isContractRenewal={true}
          reviewAgain={application.application_data_rejected}
          onUpdate={() => {
            setCurrentStep("basic_details");
            setUpdateInfo(true);
          }}
          sequenceNumber={sequenceNumber}
          sequence={sequences}
          application={application}
        />

        <div className="cancel_renewal text-center">
          <TextButton
            onClick={onCancelRenewal}
            variant="red"
            className="font_m fw_5"
          >
            No Thanks, I want to move out.
          </TextButton>
        </div>
      </div>
    );
  }

  if (updateInfo && application && !applicationUnderReview) {
    return (
      <div className="container_sm tenant_renewal_info_update">
        {currentStepPrev !== "tenant_info" && (
          <div className="steps container_sm">
            <ProgressSteps
              steps={info_sequence}
              keyRender={infoSteps}
              currentStep={currentStep}
              version="3"
            />
          </div>
        )}

        {/* Basic Details */}
        {currentStep === "basic_details" && (
          <BasicDetailForm
            applicationId={application_id}
            data={application.basic_details}
            property={application.application.unit.property_id._id}
            onNext={onStepSuccess}
            onCancelStep={onCancelStep}
            // onUpdatingInfo={fetchContractDetails}
          />
        )}

        {/* Commercial Detail */}
        {currentStep === "commercial_details" && (
          <CommercialDetailForm
            applicationId={application_id}
            data={application?.commercial_details}
            onNext={onStepSuccess}
            validateDataUpdated={false}
            onCancelStep={onCancelStep}
            // onUpdatingInfo={fetchContractDetails}
          />
        )}

        {/* ID Verification */}
        {currentStep === "ids" && (
          <IDVerificationForm
            applicationId={application_id}
            data={application.ids}
            numIds={application.property_settings.tenant_id_limit}
            onNext={onStepSuccess}
            validateDataUpdated={false}
            onCancelStep={onCancelStep}
            // onUpdatingInfo={fetchContractDetails}
          />
        )}

        {/* Household Members */}
        {currentStep === "household_members" && (
          <HouseholdMembersForm
            applicationId={application_id}
            data={application.household_members}
            maxAllowed={application.property_settings.household_member - 1}
            onNext={onStepSuccess}
            validateDataUpdated={false}
            onCancelStep={onCancelStep}
            // onUpdatingInfo={fetchContractDetails}
          />
        )}

        {/* Disability */}
        {currentStep === "disability" && (
          <DisabilityForm
            applicationId={application_id}
            data={application.disability}
            onNext={onStepSuccess}
            validateDataUpdated={false}
            onCancelStep={onCancelStep}
            // onUpdatingInfo={fetchContractDetails}
          />
        )}

        {/* Vehicles */}
        {currentStep === "vehicles" && (
          <VehiclesForm
            applicationId={application_id}
            data={application.vehicles}
            onNext={onStepSuccess}
            maxAllowed={application.property_settings.vehicle_limit}
            validateDataUpdated={false}
            onCancelStep={onCancelStep}
            // onUpdatingInfo={fetchContractDetails}
          />
        )}

        {/* Pets */}
        {currentStep === "pets" && (
          <PetsForm
            applicationId={application_id}
            data={application.pets}
            onNext={onStepSuccess}
            maxAllowed={application.property_settings.pet_limit}
            validateDataUpdated={false}
            onCancelStep={onCancelStep}
            // onUpdatingInfo={fetchContractDetails}
          />
        )}

        {/* Rental History */}
        {/* {currentStep === "rental_history" && (
          <RentalHistoryForm
            applicationId={application_id}
            data={application.rental_history}
            onNext={() => {
              fetchContractDetails();
              setUpdateInfo(false);
            }}
            minLimit={application.property_settings.rent_history_limit}
            validateDataUpdated={false}
            onCancelStep={onCancelStep}
          />
        )} */}

        <div className="cancel_renewal text-center">
          <TextButton
            onClick={onCancelRenewal}
            variant="red"
            className="font_m fw_5"
          >
            No Thanks, I want to move out.
          </TextButton>
        </div>
      </div>
    );
  }
};
/* Component ends */

export default TenantInfoUpdate;
