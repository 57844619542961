/**
 * Slice to handle master data
 * @author Abdul Ahad <aabdul@dgrnte.com>
 *
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";

const initialState = {
  appInitialized: false,
  locales: [],
  unitTypes: [],
  userRoles: [],
  genderTypes: [],
  idTypes: [],
  relationTypes: [],
  vehicleMakeList: [],
  vehicleModelList: [],
  petTypes: [],
  emergencyContacts: [],
  quoteTypes: [],
  dateFormat: "DD/MM/YYYY",
  timeFormat: "hh:mm A",
  dateTimeFormat: "DD/MM/YYYY hh:mm A",
  browser: {},
};

export const fetchLocales = createAsyncThunk(
  "locale/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/locale/list");
      dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchGenders = createAsyncThunk(
  "master/genderTypes",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/gender_type/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchRelations = createAsyncThunk(
  "master/relationTypes",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/relationship_type/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchIdTypes = createAsyncThunk(
  "master/idTypes",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/id_type/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchUserRoles = createAsyncThunk(
  "master/user_roles",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(API_URL + "/user_role");
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchUnitTypes = createAsyncThunk(
  "unit_type/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(API_URL + "/unit_type/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchVehicleMake = createAsyncThunk(
  "vehicle_make/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/vehicle_make/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchPetTypes = createAsyncThunk(
  "pet_type/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/pet_type/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchVehicleModel = createAsyncThunk(
  "vehicle_model/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      // dispatch(setLoading(true));

      const { data } = await axios.get(
        API_URL + `/vehicle_model/list?make_id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      // dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      // dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchEmergencyContacts = createAsyncThunk(
  "emergency-contact/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + "/emergency-contact/list", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchQuoteTypes = createAsyncThunk(
  "quote_type/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const { data } = await axios.get(API_URL + `/quote_type/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    setAppInitialized: (state, action) => {
      state.appInitialized = true;
    },
    setDateFormat: (state, action) => {
      state.dateFormat = action.payload;
      state.dateTimeFormat = `${action.payload} ${state.timeFormat}`;
    },
    setTimeFormat: (state, action) => {
      state.timeFormat = action.payload;
      state.dateTimeFormat = `${state.dateFormat} ${action.payload}`;
    },
    clearMasterData: (state, action) => {
      state.locales = [];
      state.genderTypes = [];
      state.idTypes = [];
      state.relationTypes = [];
      state.unitTypes = [];
      state.vehicleMakeList = [];
      state.vehicleModelList = [];
      state.petTypes = [];
    },
    setBrowser: (state, action) => {
      state.browser = action.payload;
    },
    logout: (state) => {},
  },
  extraReducers: {
    [fetchLocales.fulfilled]: (state, action) => {
      state.locales = action.payload;
    },
    [fetchLocales.rejected]: (state, action) => {
      state.locales = [];
    },
    [fetchUnitTypes.fulfilled]: (state, action) => {
      state.unitTypes = action.payload;
    },
    [fetchUnitTypes.rejected]: (state, action) => {
      state.unitTypes = [];
    },
    [fetchUserRoles.fulfilled]: (state, action) => {
      state.userRoles = action.payload;
    },
    [fetchUserRoles.rejected]: (state, action) => {
      state.userRoles = [];
    },
    [fetchGenders.fulfilled]: (state, action) => {
      state.genderTypes = action.payload;
    },
    [fetchIdTypes.fulfilled]: (state, action) => {
      state.idTypes = action.payload;
    },
    [fetchRelations.fulfilled]: (state, action) => {
      state.relationTypes = action.payload;
    },
    [fetchVehicleMake.fulfilled]: (state, action) => {
      state.vehicleMakeList = action.payload;
    },
    [fetchVehicleModel.fulfilled]: (state, action) => {
      state.vehicleModelList = action.payload;
    },
    [fetchPetTypes.fulfilled]: (state, action) => {
      state.petTypes = action.payload;
    },
    [fetchEmergencyContacts.rejected]: (state, action) => {
      state.emergencyContacts = [];
    },
    [fetchEmergencyContacts.fulfilled]: (state, action) => {
      state.emergencyContacts = action.payload;
    },
    [fetchQuoteTypes.fulfilled]: (state, action) => {
      state.quoteTypes = action.payload;
    },
  },
});

export const {
  logout,
  clearMasterData,
  setAppInitialized,
  setDateFormat,
  setTimeFormat,
  setBrowser,
} = masterSlice.actions;

export const isAppInitialized = (state) => state.master.appInitialized;
export const availableLocales = (state) => state.master.locales;
export const getGenderTypes = (state) => state.master.genderTypes;
export const getUnitTypes = (state) => state.master.unitTypes;
export const getUserRoles = (state) => state.master.userRoles;
export const getQuoteTypes = (state) => state.master.quoteTypes;
export const getEmergencyContacts = (state) => state.master.emergencyContacts;
export const getDateFormat = (state) => state.master.dateFormat;
export const getTimeFormat = (state) => state.master.timeFormat;
export const getDateTimeFormat = (state) => state.master.dateTimeFormat;
export const getBrowser = (state) => state.master.browser;

export default masterSlice.reducer;
