/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchInfoTilesList,
  fetchInfoTilesListCommunity,
  fetchInfoTilesListProperty,
  getCommunityInfoTilesList,
  getInfoTilesList,
  getPropertyInfoTilesList,
} from "../../../../setup/store/slices/infoTileSlice";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import OverviewItem from "../OverviewItem/OverviewItem";
import CustomSlider from "../../../ui/other/CustomSlider/CustomSlider";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const OverviewSlider = (props) => {
  /* Props destructuring starts */
  const { type = "", propertyId, communityID } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [InfoTiles, setInfoTiles] = useState([]);
  const [isAggregated, setIsAggregated] = useState(false);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const allInfo = useSelector(getInfoTilesList);
  const propertyInfo = useSelector(getPropertyInfoTilesList);
  const communityInfo = useSelector(getCommunityInfoTilesList);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    if (isAggregated) {
      if (type === "property") {
        (async () => {
          try {
            await dispatch(setLoading(true));
            // await dispatch(fetchInfoTilesList({ isAggregated }));
            await dispatch(
              fetchInfoTilesListProperty({
                property_id: propertyId,
                isAggregated,
              })
            );
            await dispatch(setLoading(false));
          } catch (e) {}
        })();
      } else if (type === "community") {
        (async () => {
          try {
            await dispatch(setLoading(true));
            await dispatch(
              fetchInfoTilesListCommunity({
                community_id: communityID,
                isAggregated,
              })
            );
            await dispatch(setLoading(false));
          } catch (e) {}
        })();
      }
      // if (pathname?.split("/")[2] === "home")
      else {
        (async () => {
          try {
            await dispatch(setLoading(true));
            await dispatch(fetchInfoTilesList({ isAggregated }));
            await dispatch(setLoading(false));
          } catch (e) {}
        })();
      }
    } else {
      if (type === "property") {
        (async () => {
          try {
            await dispatch(setLoading(true));
            await dispatch(
              fetchInfoTilesListProperty({
                property_id: propertyId,
                isAggregated,
              })
            );
            await dispatch(setLoading(false));
          } catch (e) {}
        })();
      } else if (type === "community") {
        (async () => {
          try {
            await dispatch(setLoading(true));
            await dispatch(
              fetchInfoTilesListCommunity({
                community_id: communityID,
                isAggregated,
              })
            );
            await dispatch(setLoading(false));
          } catch (e) {}
        })();
      } else {
        (async () => {
          try {
            await dispatch(setLoading(true));
            await dispatch(fetchInfoTilesList({ isAggregated }));
            await dispatch(setLoading(false));
          } catch (e) {}
        })();
      }
    }
  }, [isAggregated, type]);

  useEffect(() => {
    const path = pathname.split("/")[2];

    if (path === "home" && allInfo) {
      const info = allInfo;
      setInfoTiles(info);
    } else if (type === "property" && propertyInfo) {
      setInfoTiles(propertyInfo);
    } else if (type === "community" && communityInfo) {
      setInfoTiles(communityInfo);
    }
  }, [pathname, type, propertyInfo, communityInfo, allInfo]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="overview">
      {InfoTiles?.length > 0 && (
        <CustomSlider type="overview">
          {InfoTiles?.map((info, idx) => (
            <div key={idx}>
              <OverviewItem
                info={info}
                isAggregated={isAggregated}
                setIsAggregated={setIsAggregated}
                type={type}
              />
            </div>
          ))}
        </CustomSlider>
      )}

      {InfoTiles?.length === 0 && type === "community" && (
        <p className="text-center font_l font_yellow fw_5">
          No Properties Added Under Community
        </p>
      )}
    </div>
  );
};
/* Component ends */

export default OverviewSlider;
