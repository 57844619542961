/**
 *
 */

import React from "react";

/* Import configuration starts */
import { missingModuleRender } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  LocationPinFilledGrey,
  WarningRoundedOutlineYellow,
  BlankImagePlaceholder,
  ExcalmatoryYellow,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import Image from "../../../ui/other/Image/Image";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const PropertySliderItem = (props) => {
  /* Props destructuring starts */
  const { info, onClick } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = info?.property_name;
  const city = info?.city;
  const state = info?.state;
  const country = info?.country;
  const image = info?.image?.url || BlankImagePlaceholder;
  const missingModules = info?.missing_items;
  const missingMgmt = info?.missing_items.includes("management_model");
  const missingLicense = info?.missing_items.includes("license");
  const missingUnit = info?.missing_items.includes("units");
  const missingTeam = info?.missing_items.includes("team");
  const missingPropertySetting =
    info?.missing_items.includes("property_setting");
  const type = info?.property_type[0].name;
  const managementModel = info?.mgmt_model;
  const owner =
    info?.owner && info?.owner?.first_name && info?.owner?.last_name
      ? `${info?.owner?.first_name} ${info?.owner?.last_name}`
      : "Not Assigned";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  console.log(managementModel, "[managementModel]");

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="property-item-component-wrapper" onClick={onClick}>
      <div className="image">
        <Image
          className="inner-img h_100 w_100"
          src={image}
          objectFit="cover"
          alt="Property Image"
        />
      </div>

      <div className="details">
        <p className="name font_s fw_6">{name}</p>
        <p className="address font_xs">
          <img
            className="location-pin"
            src={LocationPinFilledGrey}
            alt="Location Pin"
          />
          <span className="font_lightgrey fw_5 font_xs">
            {city}, {state}, {country}
          </span>
        </p>
        <p className="type font_xs font_lightgrey fw_5">
          Property Type : <span className="font_lightgrey">{type}</span>
        </p>
        <p className="owner font_xs font_lightgrey fw_5">
          Owner : <span className="font_lightgrey">{owner}</span>
        </p>

        {managementModel === 0 && (
          <p className="owner font_xs font_lightgrey fw_5">
            Management Model :{" "}
            <span className="font_lightgrey">Single User</span>
          </p>
        )}

        {managementModel === 1 && (
          <p className="owner font_xs font_lightgrey fw_5">
            Management Model :{" "}
            <span className="font_lightgrey">Facility Management Company</span>
          </p>
        )}

        {managementModel === 2 && (
          <p className="owner font_xs font_lightgrey fw_5">
            Management Model :{" "}
            <span className="font_lightgrey">Real Estate</span>
          </p>
        )}

        <div
          className={`missing-section ${
            missingModules?.length > 0 ? "missing-available" : ""
          }`}
        >
          {missingModules?.length > 0 && (
            <div className={`missing-header`}>
              <img
                className="warning v_middle"
                src={ExcalmatoryYellow}
                alt="Warning Icon"
              />
              &nbsp;
              <span className="v_middle fw_5 font_m font_yellow">Missing</span>
            </div>
          )}
          {missingModules?.map((item, index) => (
            <span className="v_middle fw_5 font_xs txt_nowrap font_yellow">
              {missingModuleRender(item, index)}
            </span>
          ))}

          {/* <p className={`missing font_xxs ${missingMgmt ? "" : "hide"}`}>
            <img
              className="warning"
              src={ExcalmatoryYellow}
              alt="Warning Icon"
            />{" "}
            <span className="font_xxs font_yellow">
              Missing Management Model
            </span>
          </p>
          <p className={`missing font_xxs ${missingTeam ? "" : "hide"}`}>
            <img
              className="warning"
              src={ExcalmatoryYellow}
              alt="Warning Icon"
            />{" "}
            <span className="font_xxs font_yellow">Missing Teams</span>
          </p>
          <p className={`missing font_xxs ${missingUnit ? "" : "hide"}`}>
            <img
              className="warning"
              src={ExcalmatoryYellow}
              alt="Warning Icon"
            />{" "}
            <span className="font_xxs font_yellow">Missing Units</span>
          </p>
          <p className={`missing font_xxs ${missingLicense ? "" : "hide"}`}>
            <img
              className="warning"
              src={ExcalmatoryYellow}
              alt="Warning Icon"
            />{" "}
            <span className="font_xxs font_yellow">Missing License</span>
          </p>
          <p
            className={`missing font_xxs ${
              missingPropertySetting ? "" : "hide"
            }`}
          >
            <img
              className="warning"
              src={ExcalmatoryYellow}
              alt="Warning Icon"
            />{" "}
            <span className="font_xxs font_yellow">
              Missing Property Settings
            </span>
          </p> */}
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default PropertySliderItem;
