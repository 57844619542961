import React, { useEffect, useState } from "react";
/* Import configuration starts */
import GLOBAL from "../../setup/constants/global";
import { Link, Navigate, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
/* Import configuration ends */

/* Import redux slices component starts */
import { getAuthLoading } from "../../setup/store/slices/unpersistedSlice";
import {
  fetchTenant,
  getUser,
  setUserRole,
  getLoggedinUserRole,
  validateUserInvitation,
} from "../../setup/store/slices/authSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../setup/store/slices/globalAlertSlice";
import { addTenantToUnit } from "../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import image and SVG starts */
import {
  LogoPrimary,
  WarningRoundedOutlineYellow,
} from "../../setup/constants/images";
import {
  getTokenExpiredModal,
  setTokenExpiredModal,
} from "../../setup/store/slices/unpersistedSlice";
import {
  LogoWhite,
  BgShadeSignUp,
  SignUpLogo,
  DGRNTEtext,
  ArrowPointRightBlack,
} from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import SignInForm from "./local-components/SignInForm";
import WarningModal from "../../components/ui/modal/WarningModal/WarningModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const SignInTenantInvite = () => {
  /* Component states declaration starts */
  const [loaded, setLoaded] = useState(false);
  const [userCopy, setUserCopy] = useState(null);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user_data"))
  );

  const [isInviteLoaded, setIsInviteLoaded] = useState(false);
  const [validateInvite, setValidateInvite] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [loggedInUserData, setLoggedInUserData] = useState(
    localStorage.getItem("user_data") &&
      JSON.parse(localStorage.getItem("user_data"))
  );
  const [loggedInUserRole, setLoggedInUserRole] = useState(
    localStorage.getItem("user_role")
  );
  const [loggedInUserToken, setLoggedInUserToken] = useState(
    localStorage.getItem("user_token")
  );
  const [userLoggedIn, setserLoggedIn] = useState(
    localStorage.getItem("user_data") &&
      JSON.parse(localStorage.getItem("user_data")) &&
      localStorage.getItem("user_role") &&
      localStorage.getItem("user_token")
  );
  /* Component states declaration ends */

  /* Other hooks declaration starts */
  // const { t } = useTranslation();
  const dispatch = useDispatch();

  // Redux Selector
  const authLoading = useSelector(getAuthLoading);
  const tokenExpiredModal = useSelector(getTokenExpiredModal);
  // const user = JSON.parse(localStorage.getItem("user_data"));
  const userRole = useSelector(getLoggedinUserRole);
  const { encodedToken } = useParams();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // const loggedInUserData =
  //   localStorage.getItem("user_data") &&
  //   JSON.parse(localStorage.getItem("user_data"));
  // const loggedInUserRole = localStorage.getItem("user_role");
  // const loggedInUserToken = localStorage.getItem("user_token");
  // const userLoggedIn =
  //   loggedInUserData && loggedInUserRole && loggedInUserToken;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On successful sign in
  const onSignInSuccess = async (user, token) => {
    if (encodedToken) {
      const result = await dispatch(addTenantToUnit({ token: encodedToken }));

      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload?.error?.message || "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    }
  };

  const handleFetchUser = async () => {
    const result = await dispatch(fetchTenant());
    setLoaded(true);
    console.log(result, "Result");
    switch (result.meta.requestStatus) {
      case GLOBAL.REJECTED:
        setUserCopy(null);
        break;

      case GLOBAL.FULFILLED:
        setUserCopy(result.payload);
        break;
    }
  };

  const checkInviteValidation = async (token) => {
    try {
      const result = await dispatch(validateUserInvitation({ token: token }));
      console.log(result, "RES");
      setIsInviteLoaded(true);
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setValidateInvite(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setValidateInvite(result.payload);
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // useEffect(() => {
  //   if (user) {
  //     if (user?.role_id === "640049b6c470ca8de6d964f5") {
  //       handleFetchUser();
  //     } else {
  //       console.log("Flag 1");
  //       console.log(user, "User");
  //       console.log(userRole, "User Role");
  //       setUserCopy({});
  //       dispatch(
  //         showErrorAlert({
  //           title: "Error!",
  //           description: "Please log out from the existing user first.",
  //         })
  //       );
  //       navigate("/");
  //     }
  //   } else {
  //     setLoaded(true);
  //     setUserCopy(null);
  //   }
  // }, [user]);

  useEffect(() => {
    (async () => {
      if (userLoggedIn) {
        const { tenant_id } = jwtDecode(encodedToken);
        if (loggedInUserData) {
          if (loggedInUserData?._id === tenant_id) {
            const result = await dispatch(
              addTenantToUnit({ token: encodedToken })
            );
            console.log("Existing User signed in with invitation link");
            console.log("Response", result);
            switch (result.meta.requestStatus) {
              case GLOBAL.REJECTED:
                dispatch(
                  showErrorAlert({
                    title: "Error!",
                    description: result?.payload?.error?.message || "",
                  })
                );
                break;

              case GLOBAL.FULFILLED:
                dispatch(
                  showSuccessAlert({
                    title: "Success",
                    description: result?.payload?.message || "",
                  })
                );
                break;
            }
            navigate("/");
          } else {
            console.log("Please log out from the existing user first.");
            dispatch(
              showErrorAlert({
                title: "Error!",
                description: "Please log out from the existing user first.",
              })
            );
            navigate("/");
          }
        } else {
        }
      }
    })();
  }, [userLoggedIn, loggedInUserData]);

  // useEffect(() => {
  //   (async () => {
  //     if (loaded) {
  //       const { tenant_id } = jwtDecode(encodedToken);
  //       if (userCopy) {
  //         if (userCopy?._id === tenant_id) {
  //           const result = await dispatch(
  //             addTenantToUnit({ token: encodedToken })
  //           );
  //           console.log("Existing User signed in with invitation link");
  //           console.log(result);
  //           switch (result.meta.requestStatus) {
  //             case GLOBAL.REJECTED:
  //               dispatch(
  //                 showErrorAlert({
  //                   title: "Error!",
  //                   description: result?.payload?.error?.message || "",
  //                 })
  //               );
  //               break;

  //             case GLOBAL.FULFILLED:
  //               dispatch(
  //                 showSuccessAlert({
  //                   title: "Success",
  //                   description: result?.payload?.message || "",
  //                 })
  //               );
  //               break;
  //           }
  //           navigate("/tenant/home");
  //         } else {
  //           console.log("Flag 2");
  //           dispatch(
  //             showErrorAlert({
  //               title: "Error!",
  //               description: "Please log out from the existing user first.",
  //             })
  //           );
  //           navigate("/tenant/home");
  //         }
  //       } else {
  //       }
  //     }
  //   })();
  // }, [userCopy]);

  useEffect(() => {
    if (encodedToken) {
      checkInviteValidation(encodedToken);
    }
  }, [encodedToken]);

  useEffect(() => {}, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  // If user is logged in then navigating back
  // if (user) {
  //   return <Navigate to="/" />;
  // }

  // if (
  //   isInviteLoaded &&
  //   loaded &&
  //   !userCopy &&
  //   validateInvite?.message === "Valid invitation."
  // )

  if (
    isInviteLoaded &&
    !userLoggedIn &&
    validateInvite?.message === "Valid invitation."
  ) {
    return (
      <div className="signin-tenant-invite-wrapper">
        <div className="bgShade">
          <img src={BgShadeSignUp} alt="" />
        </div>
        <div className="image">
          <div className="inner-wrapper">
            <div className="text-logo">
              <img src={SignUpLogo} className="logo-icon" alt="" />
              <img src={DGRNTEtext} alt="" />
            </div>
            <h2 className="heading font-darkgreen fw_6">
              Start your journey with us
            </h2>
            <h3 className="content font-darkgreen">
              Discover the power of collaboration and create lasting
              relationships that transcend boundaries
            </h3>
          </div>
        </div>

        <div className="form-wrapper">
          <div
            className="blur-blue"
            style={{ top: "7%", left: "35%", zIndex: "10" }}
          ></div>
          <div
            className="blur-cherry"
            style={{ top: "-3%", right: "5%", zIndex: "10" }}
          ></div>
          <div
            className="blur-blue"
            style={{ top: "75%", left: "97%", zIndex: "10" }}
          ></div>
          <div
            className="blur-cherry"
            style={{ top: "90%", right: "50%", zIndex: "10" }}
          ></div>
          <div className="logo-wrapper">
            {/* <img src={LogoWhite} alt="Logo" /> */}
            <h1 className="big-h1 font-darkgreen">Tenant Sign in</h1>
          </div>
          <SignInForm onSignInSuccess={onSignInSuccess} />
          {/* {!authLoading && (
          <div className="footer text-center">
            <Link className="font_m" to="/signin">
              Return back to Sign In
            </Link>
          </div>
        )} */}
        </div>

        {/* <WarningModal
          showModal={tokenExpiredModal}
          title="Session Expired"
          description="Your session has been expired. Please Log In again."
          onClose={async () => {
            await dispatch(setTokenExpiredModal(false));
          }}
        /> */}
      </div>
    );
  } else if (
    isInviteLoaded &&
    !userLoggedIn &&
    (validateInvite === "Invitation link expired." ||
      validateInvite ===
        "Your invitation has been expired. Please request new invitation.")
  ) {
    return (
      <div className="signin-outer-wrapper-valid">
        <div className="valid-wrapper container_sm">
          <img src={WarningRoundedOutlineYellow} alt="Alert" />
          <p className="font_xl fw_5 font_red">{validateInvite}</p>

          <div className="footer text-center">
            <Link className="font_m back-link" to="/signin">
              Return back to Sign In
            </Link>
          </div>
        </div>
      </div>
    );
  }
};
/* Component ends */

export default SignInTenantInvite;
