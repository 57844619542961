import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import axios from "../axios";

const initialState = {
  languageLoaded: false,
  loading: false,
  authLoading: false,
  otpVerifyLoading: false,
  tokenExpiredModal: false,
  selectedClientInvoice: {},
  showUploadProgress: false,
  uploadProgress: 0,
  propertyGroundORTerms: null,
};

export const addTenantToUnit = createAsyncThunk(
  "/tenant/add/unit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(API_URL + "/tenant", payload, {
        headers: {
          Authorization: `Bearer ${payload?.authToken}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchPropertyTerms = createAsyncThunk(
  "property/terms_and_conditions/fetch/for_tenant",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/property/rule?type=${payload.type}&property_id=${payload.property_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data?.data?.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

const unpersistedSlice = createSlice({
  name: "unpersisted",
  initialState,
  reducers: {
    setShowUploadProgress: (state, action) => {
      state.showUploadProgress = action.payload;
    },
    setUploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
      if (!action.payload) {
        state.showUploadProgress = false;
        state.uploadProgress = 0;
      }
    },
    setLanguageLoaded: (state, action) => {
      state.languageLoaded = action.payload;
    },
    setAuthLoading: (state, action) => {
      state.authLoading = action.payload;
    },
    setOtpVerifyLoading: (state, action) => {
      state.otpVerifyLoading = action.payload;
    },
    setTokenExpiredModal: (state, action) => {
      state.tokenExpiredModal = action.payload;
    },
    setSelectedClientInvoice: (state, action) => {
      state.selectedClientInvoice = action.payload;
    },
  },

  extraReducers: {
    [fetchPropertyTerms.rejected]: (state, action) => {
      state.propertyGroundORTerms = null;
    },
    [fetchPropertyTerms.fulfilled]: (state, action) => {
      state.propertyGroundORTerms = action.payload;
    },
  },
});

export const getLoading = (state) => state.unpersisted.loading;
export const getLanguageLoaded = (state) => state.unpersisted.languageLoaded;
export const getAuthLoading = (state) => state.unpersisted.authLoading;
export const getOtpVerifyLoading = (state) =>
  state.unpersisted.otpVerifyLoading;
export const getTokenExpiredModal = (state) =>
  state.unpersisted.tokenExpiredModal;
export const getSelectedClientInvoice = (state) =>
  state.unpersisted.selectedClientInvoice;
export const getShowUploadProgress = (state) =>
  state.unpersisted.showUploadProgress;
export const getUploadProgress = (state) => state.unpersisted.uploadProgress;

// To get the Property terms & Ground Rules
export const getPropertyTermsORGroundRules = (state) =>
  state.unpersisted.propertyGroundORTerms;

export const {
  setLoading,
  setLanguageLoaded,
  setAuthLoading,
  setOtpVerifyLoading,
  setTokenExpiredModal,
  setSelectedClientInvoice,
  setShowUploadProgress,
  setUploadProgress,
} = unpersistedSlice.actions;

export default unpersistedSlice.reducer;
