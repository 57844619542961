/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  logTitle,
  canOwnerStartMaintenance,
  canOwnerAddQuotation,
  canOwnerCompleteMaintenance,
  canFmReassignManagers,
  canFMAcceptReq,
  canFMAssignMT,
  canMTRespondMaintenance,
  canMTMarkOnTheWay,
  canMTCheckIn,
  canMTNoAccess,
  canMTStrtWrkReq4Parts,
  canFMCreateQuote,
  canMTHandover,
  canMTMarkJobComplete,
  canFMPlaceOrder,
  canTPMPlaceOrder,
  canFMRecieveSpareParts,
  canTPMReassignManagers,
  canTPMAcceptReq,
  canTPMAssignTPT,
  canTPTRespondMaintenance,
  canTPTMarkOnTheWay,
  canTPTNoAccess,
  canTPTCheckIn,
  canTPTStrtWrkReq4Parts,
  canTPTHandover,
  canTPTMarkJobComplete,
  canTPMRespondToTicket,
  canFMRespondToTicket,
  canPMRespondToTicket,
  canPMRateService,
  canOwnerActionOnQuotation,
  canOwnerPlaceOrder,
  canOwnerRecieveParts,
  canTechnicianAddInspectionReport,
  canFMReviewQuotation,
  canPMReviewQuotation,
} from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  ownerRespondToT1InternalMaintenance,
  fmRespondToT3Maintenance,
  reassignMaintenanceTicket,
  tpmRespondToT3Maintenance,
  tpmAssignTechnician,
  tpTechnicianTakeAction,
  tpTechnicianMarkOnTheWay,
  tpTechnicianCheckedIn,
  technicianAddSpareParts,
  tpTechnicianStartWork,
  changeOrderStatus,
  technicianMarkJobComplete,
  tpTechnicianMarkJobComplete,
  fmAssignTechnician,
  technicianTakeAction,
  markTechnicianOnTheWay,
  technicianCheckedIn,
  technicianStartWork,
  fmRespondToInternalMaintenance,
  t2FmRespondToJob,
  pmRespondToInternalMaintenance,
  fmRespondToT2InternalMaintenance,
  fmAssignT2Technician,
  t2TechnicianTakeAction,
  t2MarkTechnicianOnTheWay,
  t2TechnicianCheckedIn,
  t2TechnicianStartWork,
  t2tpTechnicianStartWork,
  t2tpTechnicianCheckedIn,
  t2tptMarkTechnicianOnTheWay,
  tpmAssignT2Technician,
  t2tpTechnicianTakeAction,
  tpmRespondToT2Maintenance,
  ownerGenerateCostInvoice,
  t2FmCancelMaintenanceWork,
  mtAddInspectionT2,
  tptAddInspectionT2,
  fmRespondToT2Quote,
  t3TechnicianCheckedIn,
} from "../../../../../setup/store/slices/internalMaintenanceSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import CustomRadioInput from "../../../../ui/input/CustomRadioInput/CustomRadioInput";
import SparePartQuoteModal from "../../../Maintenance/SparePartQuoteModal/SparePartQuoteModal";
import MaintenacneTaskCompleteModal from "../../../Maintenance/MaintenacneTaskCompleteModal/MaintenacneTaskCompleteModal";
import ReassignManagerModal from "../../../Maintenance/ReassignManagerModal/ReassignManagerModal";
import AssignTechnicianModal from "../../../Maintenance/AssignTechnicianModal/AssignTechnicianModal";
import NoteModal from "../../../Maintenance/NoteModal/NoteModal";
import SparePartRequestModal from "../../../Maintenance/SparePartRequestModal/SparePartRequestModal";
import HandOverItemModal from "../../../Maintenance/HandOverItemModal/HandOverItemModal";
import RateAndReviewModal from "../../../TenantMaintenance/RateAndReviewModal/RateAndReviewModal";
import { addInternalMaintenanceRating } from "../../../../../setup/store/slices/ratingSlice";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import CostCoveredByModal from "../../../Maintenance/CostCoveredByModal/CostCoveredByModal";
import AddMaintenanceInspectionReport from "../../../Maintenance/AddMaintenanceInspectionReport/AddMaintenanceInspectionReport";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import { EyeFilledBlue } from "../../../../../setup/constants/images";
import { pathNamePopped } from "../../../../../setup/utils/global-helper";
import CreateQuotationInvoiceModal from "../../../Maintenance/CreateQuotationInvoiceModal/CreateQuotationInvoiceModal";
/* Import local pages and component ends */

/* Component starts */
const SimpleLog = (props) => {
  /* Props destructuring starts */
  const {
    property,
    maintenance_id,
    maintenance,
    item,
    status,
    isT1,
    isT3,
    takeAction,
    user,
    managers,
    technicians,
    isT2,
    isTPM,
    isTPT,
    toRateCompany,
    toRateTech,
    tenants,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [addQuotation, setAddQuotation] = useState(false);
  const [addReport, setAddReport] = useState(false);
  const [addInspectionReport, setAddInspectionReport] = useState(false);
  const [addT2InspectionReport, setAddT2InspectionReport] = useState(false);
  const [addT3FinalReport, setAddT3FinalReport] = useState(false);
  const [reassignManager, setReassignManager] = useState(false);
  const [assignMT, setAssignMT] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [mtOnLocationAction, setMtOnLocationAction] = useState(0);
  const [requestSpareParts, setRequestSpareParts] = useState(false);
  // const [showHandover, setShowHandover] = useState(false);
  const [showRateReviewModal, setShowRateReviewModal] = useState(false);
  const [costCoveredByModal, setCostCoveredByModal] = useState(false);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  const [quotationInvModal, setQuotationInvModal] = useState(false);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item?.tenant?.first_name} ${item?.tenant?.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const comment = item?.comment;
  const reportData = item?.data;
  const maintenanceType = maintenance?.type || maintenance?.status;
  const unitCard = maintenance?.unit_card;
  const quote = maintenance?.quotes;
  const applicationID = maintenance?.application_id;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On owner adding inspection report T1
  const ownerAddingInspectionReport = async ({ description, document }) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.status,
      status: "Completed",
      description,
      document,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result = await dispatch(ownerRespondToT1InternalMaintenance(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On owner start maintenance
  const onOwnerStartMaintenance = async (action, comment = "") => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType || "",
      status: action,
      comment,
    };

    // Backend response. Try, Catch
    try {
      // let result = {};
      let result = await dispatch(ownerRespondToT1InternalMaintenance(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On adding report
  const onReportAdd = async (values) => {
    // On owner adding report
    isT1 &&
      canOwnerCompleteMaintenance(item, user) &&
      (await ownerAddingReport(values));
  };

  // On owner adding report
  const ownerAddingReport = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.status,
      status: "Completed",
      comment: values.description,
      document: values.images,
    };

    // Backend response. Try, Catch
    try {
      let result = await dispatch(ownerRespondToT1InternalMaintenance(payload));
      setAddReport(false);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On FM/TPM accept request
  const acceptReq = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      // type: maintenanceType,
      status: "accepted",
    };

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPM) {
        if (isT2) {
          result = await dispatch(tpmRespondToT2Maintenance(payload));
        } else {
          result = await dispatch(tpmRespondToT3Maintenance(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(fmRespondToT2InternalMaintenance(payload));
        } else {
          result = await dispatch(fmRespondToT3Maintenance(payload));
        }
      }

      // Handling success response
      switch (result?.meta?.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On reassigning managers
  const onReassignManager = (values) => {
    setReassignManager(false);
    // On FM reassign other managers
    (canTPMReassignManagers(item, user) || canFmReassignManagers(item, user)) &&
      onManagerReassign(values);
  };

  // On FM/TPM/PM reassign other managers
  const onManagerReassign = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      assigning_to: values?._id,
      assigning_role: values?.role_id,
      comment: values?.comment,
      type: maintenanceType,
      maintenance_id,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(reassignMaintenanceTicket(payload));

      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On Assigning Technician
  const onAssignTechnician = (t) => {
    setAssignMT(false);
    // On FM/TPM assigning technician
    (canTPMAssignTPT(item, user) || canFMAssignMT(item, user)) &&
      assignTechnician(t);
  };

  // On MT/TPT respond to maintenance request
  const techRespondToMaintenance = async (accept = true, comment = "") => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      status: accept ? "accepted" : "rejected",
      comment,
      // type: maintenanceType,
    };

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tpTechnicianTakeAction(payload));
        } else {
          result = await dispatch(tpTechnicianTakeAction(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2TechnicianTakeAction(payload));
        } else {
          result = await dispatch(technicianTakeAction(payload));
        }
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On FM/PM review quoatation
  const managerReviewQuotation = async (accept = true, note = "", v) => {
    // Creating or collecting payload data to be sent
    let payload = {
      maintenance_id,
      status: accept ? "Approved" : "Rejected",
      note,
      type: maintenanceType,
      quotation_id: item?.data?._id,
      isThirdParty: maintenance?.assigned_role?.name === "Third Party Manager",
      quotation_type: "quoatation",
    };

    if (accept) {
      payload = { ...payload, ...v };
    }

    // Backend response. Try, Catch
    try {
      let result;
      result = await dispatch(fmRespondToT2Quote(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On action with note
  const actionWithNote = (note) => {
    setOpenNote(false);
    // On MT/TPT respond to Maintenance Request
    (canTPTRespondMaintenance(item, user) ||
      canMTRespondMaintenance(item, user)) &&
      techRespondToMaintenance(false, note);

    // On PM respond to Maintenance
    canPMRespondToTicket(item, user) && pmRespondToMaintenance(false, note);

    (canFMReviewQuotation(item, user) || canPMReviewQuotation(item, user)) &&
      managerReviewQuotation(false, note);

    (canTPMRespondToTicket(item, user) || canFMRespondToTicket(item, user)) &&
      managersRespondToMaintenance(false, note);
  };

  // On FM/TPM assigning technician
  const assignTechnician = async (t) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      technician: t._id,
    };

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPM) {
        if (isT2) {
          result = await dispatch(tpmAssignT2Technician(payload));
        } else {
          result = await dispatch(tpmAssignTechnician(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(fmAssignT2Technician(payload));
        } else {
          result = await dispatch(fmAssignTechnician(payload));
        }
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT/TPT marking on the way
  const technicianOnTheWay = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      // type: maintenanceType,
    };

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tptMarkTechnicianOnTheWay(payload));
        } else {
          result = await dispatch(tpTechnicianMarkOnTheWay(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2MarkTechnicianOnTheWay(payload));
        } else {
          result = await dispatch(markTechnicianOnTheWay(payload));
        }
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT/TPT check In
  const technicianCheckIn = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      // type: maintenanceType,
    };

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tpTechnicianCheckedIn(payload));
        } else {
          result = await dispatch(tpTechnicianCheckedIn(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2TechnicianCheckedIn(payload));
        } else if (isT3) {
          result = await dispatch(t3TechnicianCheckedIn(payload));
        } else {
          result = await dispatch(technicianCheckedIn(payload));
        }
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT/TPT start work immediately
  const techStartWork = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.status,
      // status: "Completed",
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tpTechnicianStartWork(payload));
        } else {
          result = await dispatch(tpTechnicianStartWork(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2TechnicianStartWork(payload));
        } else {
          result = await dispatch(technicianStartWork(payload));
        }
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT request for spare parts
  const mtRequestSpareparts = async (parts) => {
    setRequestSpareParts(false);
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      part: JSON.stringify(parts),
    };

    // Backend response. Try, Catch
    try {
      let result = await dispatch(technicianAddSpareParts(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT start work immediately or rquest for spare parts
  const mtOnLocationSubmit = () => {
    if (mtOnLocationAction === 0) {
      techStartWork();
    } else {
      setRequestSpareParts(true);
    }
  };

  // On FM place order
  const fmPlacingOrder = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      quotation_id: maintenance?.quotes?._id,
      status: "Order Placed",
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(changeOrderStatus(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On Owner place order
  const ownerPlacingOrder = async () => {
    await fmPlacingOrder();
  };

  // On FM recieving spare parts
  const fmRecievingSpareParts = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      quotation_id: maintenance?.quotes?._id,
      status: "Parts Recieved",
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(changeOrderStatus(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On Owner recieving spare parts
  const ownerRecievingSpareParts = async () => {
    await fmRecievingSpareParts();
  };

  const techMarkJobComplete = async (v) => {
    setAddT3FinalReport(false);
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      ...v,
    };
    console.log("Payload", payload);
    // Backend response. Try, Catch
    try {
      let result;

      if (isTPT) {
        if (isT2) {
          // result = await dispatch(t2tpTechnicianMarkJobComplete(payload));
          result = await dispatch(tpTechnicianMarkJobComplete(payload));
        } else {
          result = await dispatch(tpTechnicianMarkJobComplete(payload));
        }
      } else {
        if (isT2) {
          // result = await dispatch(t2TechnicianMarkJobComplete(payload));
          result = await dispatch(technicianMarkJobComplete(payload));
        } else {
          result = await dispatch(technicianMarkJobComplete(payload));
        }
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // FM / TPM Approve / Reject the Ticket Completed JOB
  const managersRespondToMaintenance = async (
    accept = true,
    comment = "",
    technician
  ) => {
    // setRejectNReassignTech(false);
    let payload = {
      maintenance_id,
      // type,
      logType,
      status: accept ? "accepted" : "rejected",
    };
    if (!accept) {
      payload["comment"] = comment;
      // payload["technician"] = technician;
    }

    try {
      let result;
      if (isT2) {
        result = await dispatch(t2FmRespondToJob(payload));
      } else {
        result = await dispatch(fmRespondToInternalMaintenance(payload));
      }
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;

        default:
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // PM Approve / Reject the Ticket Completed JOB
  const pmRespondToMaintenance = async (accept = true, comment = "") => {
    let payload = {
      maintenance_id,
      logType,
      status: accept ? "accepted" : "rejected",
      comment,
    };
    // (false);

    try {
      const result = await dispatch(pmRespondToInternalMaintenance(payload));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // PM / FM / OWNER RATING & REVIEW
  const onRated = async (d) => {
    // setShowRating(false);
    const payload = {
      maintenance_id,
      logType,
      company: toRateCompany?._id,
      technician: toRateTech?._id,
      ...d,
    };

    try {
      let result = await dispatch(addInternalMaintenanceRating(payload));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;

        default:
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // REPORT DATA FOR INTERNAL MAINTENANVCE TICKET
  const renderReport = () => {
    return (
      <div>
        {reportData?.comment && (
          <div className="so-report-wrapper">
            <p className="font_m fw_4 font_grey">
              Description :{" "}
              <span className="font_xs fw_4 font_white">
                {reportData?.comment}
              </span>
            </p>
          </div>
        )}
        {reportData?.document?.length > 0 && (
          <div className="so-report-wrapper">
            <div className="report-doc-wrapper">
              {reportData?.document?.map((image, index) => (
                <div className="proof-img" key={index}>
                  <img
                    src={image?.url}
                    alt="ID-Proof"
                    onClick={() => {
                      setFile(image);
                      setShowFileViewer(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  // On PM/Owner taking action against quotation
  const onTakingActionOnQuotation = async (v) => {
    const tenant_covers_cost = v?.tenant_covers_cost;
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType || "",
      quotation_id: item?.data?._id,
    };

    if (tenant_covers_cost) {
      payload["description"] = v?.description;
      payload["amount"] = v?.amount;
      if (v?.tenant_id) {
        payload["tenant_id"] = v?.tenant_id;
        payload["unit_id"] = v?.unit_id;
      }
    }

    // Backend response. Try, Catch
    try {
      if (tenant_covers_cost) {
        const result = await dispatch(ownerGenerateCostInvoice(payload));
        switch (result.meta.requestStatus) {
          case GLOBAL.REJECTED:
            dispatch(
              showErrorAlert({
                title: "Error!",
                description:
                  typeof result?.payload === "string" ? result?.payload : "",
              })
            );
            break;

          case GLOBAL.FULFILLED:
            dispatch(
              showSuccessAlert({
                title: "Success!",
                description:
                  typeof result?.payload?.message === "string"
                    ? result?.payload?.message
                    : "",
              })
            );
            break;

          default:
        }
      } else {
        onOwnerStartMaintenance("started");
      }

      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // To View The Tenant Invoice
  const handleView = (item) => {
    const newPathname = pathNamePopped(pathname, 4);

    if (item?.tenant_invoice_id) {
      navigate(
        `${newPathname}/invoicing/Tenant/others/${item?.tenant_invoice_id}`,
        {
          state: { pathname: pathname },
        }
      );
    }

    if (item?.property_invoice_id) {
      navigate(
        `${newPathname}/invoicing/Property%20Invoice/others/${item?.property_invoice_id}`,
        {
          state: { pathname: pathname },
        }
      );
    }
  };

  // On adding T2 inspection report
  const onInspectionReportAddT2 = async (v) => {
    setAddT2InspectionReport(false);
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.status,
      ...v,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPT) {
        result = await dispatch(tptAddInspectionT2(payload));
      } else {
        result = await dispatch(mtAddInspectionT2(payload));
      }

      // const result = {};

      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line simple-log">
      {/* Dot */}

      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}

          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {/* Invoice Section */}
        {(item?.property_invoice_id || item?.tenant_invoice_id) &&
          (item?.current_status === "Invoice has been created for management" ||
            item?.current_status === "Invoice has been created for tenant") && (
            <div className="black-container-sm fw_5 font_s bg_black flex-between mt-2">
              <p className="left">
                <span className="font_grey">Invoice</span>
              </p>

              <p className="right font_grey">
                <IconButton onClick={() => handleView(item)}>
                  <img src={EyeFilledBlue} alt="Eye-Doc" className="viewImg" />
                </IconButton>
              </p>
            </div>
          )}

        {/* Log Footer */}
        <div className="log-footer">
          {/* Comments */}
          {comment && (
            <p className="comments font_s fw-5">
              <span className="font_grey fw_6">Comments: </span>
              <span className="fw_5 font_red"> {comment}</span>
            </p>
          )}

          {reportData && renderReport()}

          {/* Action Buttons */}
          {takeAction && (
            <div className="button-wrapper gap-2">
              {/* Owner choosing who can cover cost */}
              {/* {isT1 && canOwnerActionOnQuotation(item, user) && (
                <CustomButton
                  onClick={() => setCostCoveredByModal(true)}
                  size="m"
                  variant="yellow"
                >
                  Cost Cover By
                </CustomButton>
              )} */}

              {/* Owner starting maintenance */}
              {isT1 && canOwnerStartMaintenance(item, user) && (
                <CustomButton
                  onClick={() => onOwnerStartMaintenance("started")}
                  size="m"
                  variant="yellow"
                >
                  Start Maintenance
                </CustomButton>
              )}

              {/* Owner creating quotation */}
              {isT1 && canOwnerAddQuotation(item, user) && (
                <CustomButton
                  onClick={() => setAddQuotation(true)}
                  variant="yellow"
                  size="m"
                >
                  I need Parts or Service
                </CustomButton>
              )}

              {/* Owner placing order */}
              {isT1 && canOwnerPlaceOrder(item, user) && (
                <CustomButton
                  onClick={() => ownerPlacingOrder()}
                  variant="yellow"
                  size="m"
                >
                  Place The Order
                </CustomButton>
              )}

              {/* Owner recieving spare parts */}
              {isT1 && canOwnerRecieveParts(item, user) && (
                <CustomButton
                  onClick={() => ownerRecievingSpareParts()}
                  variant="yellow"
                  size="m"
                >
                  Recieve Order
                </CustomButton>
              )}

              {/* Owner completing maintenance */}
              {isT1 && canOwnerCompleteMaintenance(item, user) && (
                <CustomButton
                  onClick={() => setAddInspectionReport(true)}
                  variant="yellow"
                  size="m"
                >
                  Add Report
                </CustomButton>
              )}

              {/* FM reassigning ticket to other managers */}
              {!isT1 &&
                (canFmReassignManagers(item, user) ||
                  canTPMReassignManagers(item, user)) && (
                  <CustomButton
                    onClick={() => setReassignManager(true)}
                    variant={
                      managers && managers?.length > 0 ? "yellow" : "darkgrey"
                    }
                    size="m"
                  >
                    Reassign Ticket
                  </CustomButton>
                )}

              {/* FM accepting request */}
              {!isT1 &&
                (canTPMAcceptReq(item, user) || canFMAcceptReq(item, user)) && (
                  <CustomButton
                    onClick={() => acceptReq()}
                    variant="yellow"
                    size="m"
                  >
                    Accept Request
                  </CustomButton>
                )}

              {/* FM accepting request */}
              {!isT1 &&
                (canFMAssignMT(item, user) || canTPMAssignTPT(item, user)) && (
                  <CustomButton
                    onClick={() => setAssignMT(true)}
                    variant="yellow"
                    size="m"
                  >
                    Assign Technician
                  </CustomButton>
                )}

              {/* MT responding to maintenance request */}
              {(canTPTRespondMaintenance(item, user) ||
                canMTRespondMaintenance(item, user)) && (
                <>
                  <CustomButton
                    onClick={() => techRespondToMaintenance()}
                    size="m"
                    variant="yellow"
                  >
                    Accept
                  </CustomButton>
                  <CustomButton
                    variant="red"
                    onClick={() => setOpenNote(true)}
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </>
              )}

              {/* MT marking on the way */}
              {(canTPTMarkOnTheWay(item, user) ||
                canMTMarkOnTheWay(item, user)) && (
                <CustomButton
                  onClick={() => technicianOnTheWay()}
                  variant="yellow"
                  size="m"
                >
                  On The Way
                </CustomButton>
              )}

              {/* MT Checking In */}
              {(canTPTCheckIn(item, user) || canMTCheckIn(item, user)) && (
                <CustomButton
                  onClick={() => technicianCheckIn()}
                  variant="yellow"
                  size="m"
                >
                  Check In
                </CustomButton>
              )}

              {/* MT starting work or request for spare parts */}
              {!isT1 &&
                (canTPTStrtWrkReq4Parts(item, user) ||
                  canMTStrtWrkReq4Parts(item, user)) && (
                  <>
                    <div className="radio-container spare-parts-start-work-radio mt-4 w_100">
                      <CustomRadioInput
                        isSelected={mtOnLocationAction === 0}
                        onSelecting={() => setMtOnLocationAction(0)}
                        label="Start work immediately"
                      />
                      <CustomRadioInput
                        isSelected={mtOnLocationAction === 1}
                        onSelecting={() => setMtOnLocationAction(1)}
                        label="I need Parts or Service"
                      />
                    </div>
                    <CustomButton
                      onClick={() => mtOnLocationSubmit()}
                      variant="yellow"
                      size="m"
                    >
                      Submit
                    </CustomButton>
                  </>
                )}

              {/* FM Creating Quotes */}
              {canFMCreateQuote(item, user) && (
                <CustomButton
                  onClick={() => setAddQuotation(true)}
                  variant="yellow"
                  size="m"
                >
                  Create Quotation
                </CustomButton>
              )}

              {!isT1 &&
                (canFMReviewQuotation(item, user) ||
                  canPMReviewQuotation(item, user)) && (
                  <>
                    <CustomButton
                      onClick={() => setQuotationInvModal(true)}
                      variant="yellow"
                      size="m"
                    >
                      Accept
                    </CustomButton>
                    <CustomButton
                      variant="red"
                      onClick={() => setOpenNote(true)}
                      size="m"
                    >
                      Reject
                    </CustomButton>
                  </>
                )}

              {/* FM/TPM Placing Order */}
              {(canTPMPlaceOrder(item, user) ||
                canFMPlaceOrder(item, user)) && (
                <CustomButton
                  onClick={() => fmPlacingOrder()}
                  variant="yellow"
                  size="m"
                >
                  Place Order
                </CustomButton>
              )}

              {/* FM Creating Quotes */}
              {!isT1 && canFMRecieveSpareParts(item, user) && (
                <CustomButton
                  onClick={() => fmRecievingSpareParts()}
                  variant="yellow"
                  size="m"
                >
                  Recieve Order
                </CustomButton>
              )}

              {/* MT handover items */}
              {/* {(canTPTHandover(item, user) || canMTHandover(item, user)) && (
                <CustomButton
                  onClick={() => setShowHandover(true)}
                  variant="yellow"
                  size="m"
                >
                  Handover Old Items
                </CustomButton>
              )} */}

              {/* MT mark job complete */}
              {(canMTMarkJobComplete(item, user) ||
                canTPTMarkJobComplete(item, user)) && (
                <CustomButton
                  onClick={() => setAddT3FinalReport(true)}
                  variant="yellow"
                  size="m"
                >
                  Mark Job Complete
                </CustomButton>
              )}

              {/* Technician Add Inspection Report */}
              {!isT1 &&
                quote === null &&
                canTechnicianAddInspectionReport(item, user) && (
                  <CustomButton
                    onClick={() => setAddT2InspectionReport(true)}
                    variant="yellow"
                    size="m"
                  >
                    Upload Inspection Report
                  </CustomButton>
                )}

              {/* FM accepting request */}
              {!isT1 &&
                (canTPMRespondToTicket(item, user) ||
                  canFMRespondToTicket(item, user)) && (
                  <>
                    <CustomButton
                      onClick={() => managersRespondToMaintenance()}
                      variant="yellow"
                      size="m"
                    >
                      Approve
                    </CustomButton>
                    <CustomButton
                      onClick={() => setOpenNote(true)}
                      // onClick={() => managersRespondToMaintenance()}
                      variant="red"
                      size="m"
                    >
                      Reject
                    </CustomButton>
                  </>
                )}

              {/* PM ACCESPTING THE FM RAISED TICKET */}
              {!isT1 && canPMRespondToTicket(item, user) && (
                <>
                  <CustomButton
                    onClick={() => pmRespondToMaintenance()}
                    variant="yellow"
                    size="m"
                  >
                    Approve
                  </CustomButton>
                  <CustomButton
                    onClick={() => setOpenNote(true)}
                    variant="red"
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </>
              )}

              {/* PM rating service */}
              {canPMRateService(item, user) && !maintenance?.is_rated && (
                <CustomButton
                  onClick={() => setShowRateReviewModal(true)}
                  size="m"
                  variant="yellow"
                >
                  Rate and Review
                </CustomButton>
              )}
            </div>
          )}
        </div>
      </div>

      {takeAction && (
        <>
          <CreateQuotationInvoiceModal
            showModal={quotationInvModal}
            onClose={() => setQuotationInvModal(false)}
            onSubmit={(v) => managerReviewQuotation(true, "", v)}
            tenantList={tenants}
            canSelectTenant={true}
            currency={property?.currency}
          />

          <AddMaintenanceInspectionReport
            showModal={addT2InspectionReport}
            onClose={() => setAddT2InspectionReport(false)}
            onAddReport={onInspectionReportAddT2}
            title="Inspection Report"
          />

          <AddMaintenanceInspectionReport
            showModal={addT3FinalReport}
            onClose={() => setAddT3FinalReport(false)}
            onAddReport={techMarkJobComplete}
            title="Final Maintenance Report"
          />

          <AddMaintenanceInspectionReport
            showModal={addInspectionReport}
            onClose={() => setAddInspectionReport(false)}
            onAddReport={
              isT1 ? ownerAddingInspectionReport : isT2 ? techStartWork : ""
            }
            title="Internal Maintenance Report"
          />

          <CostCoveredByModal
            showModal={costCoveredByModal}
            onSubmit={onTakingActionOnQuotation}
            currency={property?.currency}
            onClose={() => setCostCoveredByModal(false)}
            totalQuoatationCost={item?.data?.total_cost}
            requestNumber={item?.data?.spare_part_request_number}
            quoteNumber={item?.data?.spare_part_quote_number}
            showTenantlist={true}
            isT1={isT1}
            tenants={tenants}
          />

          {/* Spare parts modal */}
          <SparePartQuoteModal
            showModal={addQuotation}
            maintenance_id={maintenance_id}
            maintenanceType={maintenanceType}
            user={user}
            type="internal"
            currentLog={item}
            onClose={() => setAddQuotation(false)}
            isT2={isT2}
            isT1={isT1}
            isT3={isT3}
            currency={property?.currency}
            showTenantlist={true}
            canSelectTenant={true}
            tenantList={tenants}
            isTPM={isTPM}
          />

          {/* Maintenance Task Complete Modal */}
          {/* <MaintenacneTaskCompleteModal
            showModal={addReport}
            callBack={onReportAdd}
            onClose={() => setAddReport(false)}
          /> */}

          {/* Reassigning other managers modal */}
          <ReassignManagerModal
            actionWithNote={onReassignManager}
            showModal={reassignManager}
            managers={managers}
            onCancel={() => setReassignManager(false)}
          />

          {/* Assigning Technician Modal */}
          <AssignTechnicianModal
            action={onAssignTechnician}
            showModal={assignMT}
            technicians={technicians}
            onCancel={() => setAssignMT(false)}
          />

          {/* Note Modal */}
          <NoteModal
            showModal={openNote}
            actionWithNote={actionWithNote}
            title="Reject"
            inputPlaceholder="Comment"
            btnText="Reject"
            showError={true}
            onCancel={() => setOpenNote(false)}
          />

          {/* Spare parts modal */}
          <SparePartRequestModal
            showModal={requestSpareParts}
            callback={mtRequestSpareparts}
            onClose={() => setRequestSpareParts(false)}
          />

          {/* RATING & REVIEW MODAL */}
          {!maintenance?.is_rated && (
            <RateAndReviewModal
              showModal={showRateReviewModal}
              btnText="Submit"
              onCancel={() => setShowRateReviewModal(false)}
              onSubmit={onRated}
              rateTechnician={!isT1}
            />
          )}
        </>
      )}

      {/* Handove Item Modal */}
      {/* <HandOverItemModal
        showModal={showHandover}
        onClose={() => setShowHandover(false)}
        callBack={techHandoverItems}
      /> */}

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file?.type}
        file={file?.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default SimpleLog;
