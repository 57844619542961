/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../setup/constants/global";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchPropertyTerms } from "../../setup/store/slices/unpersistedSlice";
import { showErrorAlert } from "../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TenantGroundRule from "../../components/module/TenantGroundRule/TenantGroundRules";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

/* Component starts */
const TenantGroundRules = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { property_id } = useParams();
  /* Other hooks declaration ends */

  // Redux Selectors

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchPropertyTerms = async () => {
    // Creating or collecting payload data to be sent
    const payload = { property_id, type: "ground_rules" };
    console.log("Payload", payload);
    setLoading(true);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchPropertyTerms(payload));
      console.log("Response", result);
      setLoading(false);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setData(null);
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setData(result?.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    handleFetchPropertyTerms();
  }, [property_id]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="request-wrapper">
      <div className="heading-wrapper">
        <div className="heading">
          <h2 className="font_xxl fw_5">Ground Rules Of Unit</h2>
        </div>
      </div>

      {data && <TenantGroundRule data={data} />}
    </div>
  );
};
/* Component End */

export default TenantGroundRules;
