/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  ExcalmatoryYellow,
  GroundRule,
  ManageMaintenance,
  TenantAnnoucments,
  TenantContracts,
  TenantEmergency,
  TenantGuestPass,
  TenantInvoices,
  TenantLostAndFound,
  TenantMaintenance,
  TenantNotifications,
  TenantRequests,
  TenantSecurity,
  TenantSharedFacility,
  TenantViolations,
  DashboardMove,
  MoveOutIcon,
} from "../../../../../setup/constants/images";
import { useDispatch, useSelector } from "react-redux";
import EmergencyContactModal from "../../../Tenant/EmergencyContactsModal/EmergencyContactsModal";
import ESignDocModal from "../../../Tenant/ESignDocModal/ESignDocModal";

/* Import image and SVG ends */

/* Import local pages and component starts */

/* Import local pages and component ends */

/* Component starts */
const Modules = (props) => {
  /* Props destructuring starts */
  const { unit, fetchTenantUnitsNew } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showModal, setShowModal] = useState(false);
  const [contactsDetail, setContactsDetail] = useState();
  const [finalAgreementModal, setFinalAgreementModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const guestPass = unit?.cards?.visitor_pass;
  const maintenance = unit?.cards?.maintenance;
  const contract = unit?.cards?.contract;
  const security = unit?.cards?.security;
  const sharedFacility = unit?.cards?.shared_facility;
  // const notification; const annoucements;
  const requests = unit?.cards?.requests;
  const invoices = unit?.cards?.invoices;
  const lostAndFound = unit?.cards?.lost_n_found;
  const violations = unit?.cards?.violations;

  const contractRenewal = unit?.cards?.contract_renewal;
  const moveOut = unit?.cards?.move_out;
  const groundRules = unit?.cards?.ground_rules;
  const terms = unit?.cards?.terms;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onClose = () => {
    setShowModal(!showModal);
  };

  const handleSelectUnit = async (unit) => {
    setShowModal(!showModal);
    setContactsDetail(unit?.emergency_contacts);
  };

  const onSignatureSuccess = () => {
    fetchTenantUnitsNew();
    setFinalAgreementModal(false);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="tenant-home-module-wrapper">
        {/* Guest Pass */}
        {guestPass && (
          <div
            className="box "
            onClick={() => {
              navigate(`/tenant/unit/${unit._id}/guest-passes`);
            }}
          >
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={TenantGuestPass}
                alt="Guest Pass"
              />
            </div>
            <p className="font_l font_white fw_6">Guest Pass</p>
          </div>
        )}

        {/* Maintenance */}
        {maintenance && (
          <div
            className="box "
            onClick={() => {
              navigate(`/tenant/unit/${unit._id}/maintenance_requests`);
            }}
          >
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={TenantMaintenance}
                alt="Maintenance"
              />
            </div>
            <p className="font_l font_white fw_6">Maintenance</p>
          </div>
        )}

        {/* Contract */}
        {contract && (
          <div
            className="box "
            onClick={() => {
              navigate(`/tenant/unit/${unit?._id}/contracts`);
            }}
          >
            <div className="img-wrapper-contract">
              <img
                className="module-icon h_100"
                src={TenantContracts}
                alt="Contract"
              />

              {unit?.alert?.contract && (
                <span className="missing d_block">
                  <img
                    src={ExcalmatoryYellow}
                    className="w_100"
                    alt="Missing Icon"
                  />
                </span>
              )}
            </div>
            <p className="font_l font_white fw_6">Contract</p>
          </div>
        )}

        {/* Security */}
        {security && (
          <div
            className="box "
            onClick={() => {
              navigate(`/tenant/unit/${unit._id}/security_complains`);
            }}
          >
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={TenantSecurity}
                alt="Security"
              />
            </div>
            <p className="font_l font_white fw_6">Security</p>
          </div>
        )}

        {/* Shared Facility */}
        {sharedFacility && (
          <div
            className="box "
            onClick={() => {
              navigate("/tenant/facilities/list");
            }}
          >
            {/* <div> */}
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={TenantSharedFacility}
                alt="Shared Facility"
              />
            </div>
            <p className="font_l font_white fw_6">Shared Facility</p>
            {/* </div>
          <span className="text font_m fw_6 font_yellow my-2">
            Comming Soon!
          </span> */}
          </div>
        )}

        {/* Notification */}
        {/* <div className="box ">
          {/* <div> *
          <div className="img-wrapper">
            <img
              className="module-icon h_100"
              src={TenantNotifications}
              alt="Notification"
            />
          </div>
          <p className="font_l font_white fw_6">Notification</p>
          {/* </div>
          <span className="text font_m fw_6 font_yellow my-2">
            Comming Soon!
          </span> *
        </div> */}

        {/* Requests */}
        {requests && (
          <div
            className="box "
            onClick={() => {
              navigate(`/tenant/unit/${unit._id}/requests`);
            }}
          >
            {/* <div> */}
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={TenantRequests}
                alt="Requests"
              />
            </div>
            <p className="font_l font_white fw_6">Requests</p>
            {/* </div>
          <span className="text font_m fw_6 font_yellow my-2">
            Comming Soon!
          </span> */}
          </div>
        )}

        {/* Announcement */}
        <div
          className="box "
          onClick={() =>
            navigate(`/tenant/unit/${unit._id}/annoucements`, {
              state: unit,
            })
          }
        >
          <div className="img-wrapper">
            <img
              className="module-icon h_100"
              src={TenantAnnoucments}
              alt="Acnnouncement"
            />
          </div>
          {/* <span className="fw_s mx-2 count">10</span> */}
          <p className="font_l font_white fw_6">Announcement</p>
        </div>

        {/* Invoices */}
        {invoices && (
          <div
            className="box "
            onClick={() =>
              navigate(`/tenant/unit/${unit._id}/invoicing/unpaid`)
            }
          >
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={TenantInvoices}
                alt="Invoices"
              />
            </div>
            <p className="font_l font_white fw_6">Invoices</p>
          </div>
        )}

        {/* Lost and Found */}
        {lostAndFound && (
          <div
            className="box "
            onClick={() => {
              navigate(`/tenant/unit/${unit._id}/lost_and_found/found`);
            }}
          >
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={TenantLostAndFound}
                alt="Lost and Found"
              />
            </div>
            <p className="font_l font_white fw_6">Lost and Found</p>
          </div>
        )}

        {/* Emergency Numbers */}
        <div className="box " onClick={() => handleSelectUnit(unit)}>
          <div className="img-wrapper">
            <img
              className="module-icon h_100"
              src={TenantEmergency}
              alt="Emergency Numbers"
            />
          </div>
          <p className="font_l font_white fw_6">Emergency Numbers</p>
        </div>

        {/* Violations */}
        {violations && (
          <div
            className="box "
            onClick={() => {
              navigate(`/tenant/unit/${unit._id}/violations`);
            }}
          >
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={TenantViolations}
                alt="Violations"
              />
            </div>
            <p className="font_l font_white fw_6">Violations</p>
          </div>
        )}

        {/* Ground rules of Unit */}
        {groundRules && (
          <div
            className="box "
            onClick={() => {
              navigate(
                `/tenant/unit/${unit?._id}/ground_rules/${unit?.property_id}`
              );
            }}
          >
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={GroundRule}
                alt="Ground Rules"
              />
            </div>
            <p className="font_l font_white fw_6">Property Ground Rules</p>
          </div>
        )}

        {/* Ground rules of Unit */}
        {terms && (
          <div
            className="box "
            onClick={() => {
              navigate(
                `/tenant/unit/${unit?._id}/property_terms/${unit?.property_id}`
              );
            }}
          >
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={GroundRule}
                alt="Ground Rules"
              />
            </div>
            <p className="font_l font_white fw_6">
              Property Terms And Conditions
            </p>
          </div>
        )}

        {/* Contract Renewal */}
        {contractRenewal &&
          unit?.contract_renewal?.status !== "pending_contract_dispatch" &&
          unit?.contract_renewal?.status !== "pending-contract-review" && (
            <div
              className="box box-orange"
              onClick={() => {
                if (
                  unit?.contract_renewal?.status === "pending_tenant_signature"
                ) {
                  setFinalAgreementModal(true);
                } else {
                  navigate(
                    `/tenant/unit/${unit?._id}/contracts/${unit?.contract_renewal?._id}/application/${unit?.contract_renewal?.tenant_application}/renew`
                  );
                }
              }}
            >
              <div className="img-wrapper-contract">
                <img
                  className="module-icon h_100"
                  src={TenantContracts}
                  alt="Contract Renewal"
                />
              </div>
              <p className="font_l font_white fw_6">Contract Renewal</p>
            </div>
          )}

        {/* Move Out */}
        {moveOut && (
          <div
            className="box box-red"
            onClick={() => {
              navigate(
                // `/tenant/unit/${unit?._id}/contracts/${unit?.contract_renewal?._id}/application/${unit?.contract_renewal?.tenant_application}/renew`
                `/tenant/unit/${unit?._id}/contracts/${unit?.move_out?.contract_id}/moveout/${unit?.move_out?._id}`
              );
            }}
          >
            <div className="img-wrapper-contract">
              <img
                className="module-icon h_100"
                src={MoveOutIcon}
                alt="Contract Renewal"
              />
            </div>
            <p className="font_l font_white fw_6">Move Out</p>
          </div>
        )}
      </div>

      {/* <hr />

      <div className="future-module">
        
        <div
          className="box "
          onClick={() => {
            navigate("/tenant/facilities/list");
          }}
        >
          <div>
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={TenantSharedFacility}
                alt="Shared Facility"
              />
            </div>
            <p className="font_s font_grey fw_5">Shared Facility</p>
          </div>
          <span className="text font_m fw_6 font_yellow my-2">
            Comming Soon!
          </span>
        </div>

       
        <div className="box ">
          <div>
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={TenantNotifications}
                alt="Notification"
              />
            </div>
            <p className="font_s font_grey fw_5">Notification</p>
          </div>
          <span className="text font_m fw_6 font_yellow my-2">
            Comming Soon!
          </span>
        </div>

       
        <div
          className="box "
          onClick={() => {
            navigate(`/tenant/unit/${unit._id}/requests`);
          }}
        >
          <div>
            <div className="img-wrapper">
              <img
                className="module-icon h_100"
                src={TenantRequests}
                alt="Requests"
              />
            </div>
            <p className="font_s font_grey fw_5">Requests</p>
          </div>
          <span className="text font_m fw_6 font_yellow my-2">
            Comming Soon!
          </span>
        </div>
      </div> */}

      {/* EMERGENCY CONTACTS MODAL */}
      <EmergencyContactModal
        unit={unit}
        contactsDetail={contactsDetail}
        showModal={showModal}
        onClose={onClose}
      />

      <ESignDocModal
        onCompleted={onSignatureSuccess}
        application_id={unit?.contract_renewal?.tenant_application}
        type={"final_agreement"}
        showModal={finalAgreementModal}
        onClose={() => setFinalAgreementModal(false)}
      />
    </>
  );
};
/* Component ends */

export default Modules;
