/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getIsACC,
  getIsOwner,
} from "../../../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocumentGreen,
  EditGreen,
  EyeFilledBlue,
} from "../../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../../../ui/button/IconButton/IconButton";
import PaymentModal from "../../../PaymentModal/PaymentModal";
import FileViewerModal from "../../../../../../ui/modal/FileViewerModal/FileViewerModal";
import Pagination from "../../../../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

/* Component starts */
const UtilitiesInvoicing = (props) => {
  /* Props destructuring starts */
  const {
    propertyInvoices,
    currentKey,
    property,
    currentPage,
    setCurrentPage,
    totalCount,
    invoiceAggregation,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showModal, setShowModal] = useState(false);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  const [invoiceData, setInvoiceData] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const isAccountant = useSelector(getIsACC);
  const isOwner = useSelector(getIsOwner);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onClose = () => {
    setShowModal(!showModal);
  };

  const onSuccess = () => {
    setShowModal(!showModal);
    navigate(-1);
  };

  const handleReportView = (item) => {
    navigate(`${item?._id}`);
  };

  const handlePaymentModal = (item) => {
    setInvoiceData(item);
    setShowModal(!showModal);
  };

  // const handleViewReport = async (id) => {
  //   const itemData = await dispatch(fetchAccessPassBasedOnId(id));
  //   navigate(`${id}`, {
  //     state: itemData.payload,
  //   });
  // };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper properties-invoice-table">
        {propertyInvoices?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Service Name</th>
                <th>Attachment</th>
                <th>Total</th>
                <th>Method</th>
                <th>Date & Time</th>
                <th>Status</th>
                <th className="text-start">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {propertyInvoices?.map((item) => (
                <tr key={item._id}>
                  {/* ID */}
                  <td>{item?.invoice_number}</td>

                  {/* Service Name */}
                  <td>
                    {item?.service_name !== undefined
                      ? item?.service_name
                      : "-"}
                  </td>

                  {/* Attachments */}
                  <td className="doc">
                    {item?.document && (
                      <>
                        <img src={DocumentGreen} alt="Doc" />
                        Untitled -{" "}
                        <span
                          className="font_xs fw_3 font_blue"
                          onClick={() => {
                            setFile(item?.document);
                            setShowFileViewer(true);
                          }}
                        >
                          View
                        </span>
                      </>
                    )}
                  </td>

                  {/* Total */}
                  <td>
                    {item?.total?.toLocaleString("en-US")} {property?.currency}
                  </td>

                  {/* Remarks */}
                  <td>
                    {" "}
                    {item?.method !== undefined
                      ? item?.method?.charAt(0)?.toUpperCase() +
                        item?.method?.slice(1)
                      : "-"}
                  </td>

                  {/*  Date */}
                  <td>{moment(item?.due_date).format(DATE_FORMAT)}</td>

                  {/* Status */}
                  <td
                    className={`${
                      item?.status === "Paid"
                        ? "font_green"
                        : item?.status === "Unpaid"
                        ? "font_yellow"
                        : "font_red"
                    }`}
                  >
                    {item?.status?.charAt(0).toUpperCase() +
                      item?.status?.slice(1)}
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={() => handleReportView(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                    {
                      //isAccountant &&

                      ((isOwner && property?.mgmt_model === 0) ||
                        (isAccountant && property?.mgmt_model === 2)) &&
                        (item?.status === "Unpaid" ||
                          item?.status === "Partial Paid") && (
                          <IconButton
                            onClick={() => handlePaymentModal(item)}
                            className="action-button "
                          >
                            <img
                              // src={EditWhite}
                              src={EditGreen}
                              className="h_100 mx-2"
                              alt="View Details"
                            />
                          </IconButton>
                        )
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {propertyInvoices?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}

        <PaymentModal
          showModal={showModal}
          onClose={onClose}
          onSuccess={onSuccess}
          invoiceData={invoiceData}
          property_id={property?._id}
          currency={property?.currency}
        />

        {/* File viewer modal */}
        <FileViewerModal
          show={showFileViewer}
          type={file.type}
          file={file.url}
          onClose={() => {
            setShowFileViewer(false);
            setFile({});
          }}
        />
      </div>

      {/* PAGINATION */}
      {propertyInvoices?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          type="invoice"
          currency={property?.currency}
          paid={invoiceAggregation?.paid_Other_amount}
          unPaid={invoiceAggregation?.unpaid_Other_amount}
          overdue={invoiceAggregation?.overDue_Other_amount}
          totalAmount={invoiceAggregation?.total_amount}
          voidAmount={invoiceAggregation?.void_Other_amount}
        />
      )}
    </>
  );
};
/* Component ends */

export default UtilitiesInvoicing;
