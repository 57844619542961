/**
 * This conmponent is a Modal for Confirmation OTP
 */

import React, { useState } from "react";

/* Import configuration starts */
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices starts */
import { getOtpVerifyLoading } from "../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { OTPOutlinedBlueIcon } from "../../../../setup/constants/images";
/* Import image and SVG ends */

import "./style.scss";

/* Import local components starts */
import TextButton from "../../button/TextButton/TextButton";
import CustomButton from "../../button/CustomButton/CustomButton";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { Toastify } from "../../../../setup/utils/toast";
/* Import local components ends */

/* Component starts */
const ConfirmationOTPModal = (props) => {
  /* Props destructuring starts */
  const {
    onVerify,
    onResend = null,
    onCancel,
    showModal,
    title,
    description,
    digits = 6,
    isInputNum = true,
    isInputSecure = false,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [otp, setOtp] = useState("");
  /* Component states and useRef declaration ends */
  const checkSpaces = (v = "") => {
    if (v === "" || v.length < 6) {
      return false;
    }
    for (let i = 0; i < v.length; i++) {
      const c = v.charAt(i);
      if (c === " ") {
        return false;
      }
    }
    return true;
  };

  const handleVerifyOTP = () => {
    setOtp("");
    if (checkSpaces(otp)) {
      onVerify(otp);
    } else {
      Toastify("error", "Enter valid OTP");
    }
  };

  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration starts */
  // Redux selector
  const OtpVerifyLoading = useSelector(getOtpVerifyLoading);
  /* Other hooks declaration ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size="lg"
      centered
      show={showModal}
      className="success-modal-wrapper-main"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <div className="icon-wrapper text-center">
            <div className="circle">
              <img src={OTPOutlinedBlueIcon} alt="Otp Icon" />
            </div>
          </div>
          <h2 className="text-center font_blue">{title}</h2>
          <p className="description text-center font_yellow font_m">
            {description}
          </p>
        </div>
        <div className="modal-body-custom">
          <div className="otp-wrapper">
            <OtpInput
              numInputs={digits}
              onChange={(otp) => setOtp(otp)}
              value={otp}
              isInputNum={isInputNum}
              isInputSecure={isInputSecure}
              // className="otp-digit"
              className={`${userTheme === "dark" ? "otp-digit" : "otherTheme"}`}
              inputStyle="font_xl"
              containerStyle="otp"
              separator={<span className="seperator"></span>}
            />
          </div>
        </div>
        <div className="modal-footer-custom">
          {onResend && (
            <div className="resend-wrapper text-end">
              <TextButton onClick={onResend} className="font_m">
                Resend
              </TextButton>
            </div>
          )}

          {/* Verify loader and buttons */}
          {OtpVerifyLoading ? (
            <p className="verify-loader text-center font_m font_yellow">
              Verifying
            </p>
          ) : (
            <div className="button-wrapper">
              <CustomButton
                disabledClass="btn_grey_custom"
                disable={!checkSpaces(otp)}
                onClick={handleVerifyOTP}
                size="m"
              >
                Verify
              </CustomButton>
              <CustomButton
                onClick={() => {
                  setOtp("");
                  onCancel();
                }}
                size="m"
                variant="red"
              >
                Cancel
              </CustomButton>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default ConfirmationOTPModal;
