import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices starts */
import {
  fetchUser,
  getUser,
  privacyPolicyContent,
} from "../../setup/store/slices/authSlice";
import {
  fetchDGRNTEterms,
  getSetting,
} from "../../setup/store/slices/settingSlice";
/* Import redux slices ends */

/* Import local pages and component starts */
import SignUpForm from "./local-components/SignUpForm";
import TermsConditionSignupModal from "../../components/module/Auth/TermsConditionSignupModal/TermsConditionSignupModal";
import PrivacyPolicySignupModal from "../../components/module/Auth/PrivacyPolicySignupModal/PrivacyPolicySignupModal";
/* Import local pages and component ends */

/* Import image and SVG starts */
import {
  BgShadeSignUp,
  SignUpLogo,
  DGRNTEtext,
} from "../../setup/constants/images";
/* Import image and SVG ends */

import "./style.scss";

/* Component starts */
const SignUp = () => {
  /* Component states declaration starts */
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [privacyContent, setPrivacyContent] = useState(null);
  const [setting, setSetting] = useState({ terms: "", about: "" });
  /* Component states declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Redux Selector
  const user = useSelector(getUser);
  // const setting = useSelector(getSetting);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchPrivayPolicy = async () => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(privacyPolicyContent());

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setPrivacyContent(null);
          break;

        case GLOBAL.FULFILLED:
          setPrivacyContent(result.payload);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleFetchTermsConditions = async () => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchDGRNTEterms());

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.FULFILLED:
          setSetting(result.payload);
          break;

        default:
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      await dispatch(fetchUser());
      await handleFetchTermsConditions();
      await handleFetchPrivayPolicy();
    })();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  // If user is logged in then navigating back
  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="sign-up-wrapper">
      <div className="bgShade">
        <img src={BgShadeSignUp} alt="" />
      </div>
      <div className="image">
        <div className="inner-wrapper">
          <div className="text-logo">
            <img src={SignUpLogo} className="logo-icon" alt="" />
            <img src={DGRNTEtext} alt="" />
          </div>
          <h2 className="heading font-darkgreen fw_6">
            Start your journey with us
          </h2>
          <h3 className="content font-darkgreen">
            Discover the power of collaboration and create lasting relationships
            that transcend boundaries
          </h3>
        </div>
      </div>
      <div className="form-wrapper">
        <div
          className="blur-blue"
          style={{ top: "7%", left: "35%", zIndex: "10" }}
        ></div>
        <div
          className="blur-cherry"
          style={{ top: "-3%", right: "5%", zIndex: "10" }}
        ></div>
        <div
          className="blur-blue"
          style={{ top: "75%", left: "97%", zIndex: "10" }}
        ></div>
        <div
          className="blur-cherry"
          style={{ top: "90%", right: "50%", zIndex: "10" }}
        ></div>
        <div className="logo-wrapper">
          {/* <img src={LogoWhite} alt="Logo" /> */}
          <h1 className="big-h1 font-darkgreen">Sign Up</h1>

          <p className="font_xl font-darkgreen">
            Have an account ?{" "}
            <span
              className="font-darkgreen fw_6 login"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/signin")}
            >
              Login
            </span>
          </p>
        </div>

        <SignUpForm
          setShowTerms={setShowTerms}
          setShowPrivacy={setShowPrivacy}
        />
        {/* {!authLoading && (
          <div className="footer text-center">
            <Link className="font_m" to="/signin">
              Return back to Sign In
            </Link>
          </div>
        )} */}
      </div>
      <PrivacyPolicySignupModal
        showModal={showPrivacy}
        onClose={() => setShowPrivacy(false)}
        data={privacyContent}
      />
      <TermsConditionSignupModal
        title="Terms And Conditions"
        showModal={showTerms}
        onClose={() => setShowTerms(false)}
        data={setting?.terms}
      />
    </div>
  );
};
/* Component ends */

export default SignUp;
