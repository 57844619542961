/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { customMoment } from "../../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EditGreen } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../../ui/other/Image/Image";
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const DetailCard = (props) => {
  /* Props destructuring starts */
  const { taskDetail, taskTab, user } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});

  const [nextDate, setNextDate] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const { tab } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  const startDate = taskDetail?.startdate || taskDetail?.routine_id?.startdate;
  const endDate = taskDetail?.enddate || taskDetail?.routine_id?.enddate;

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getDayFromTheDay = (date) => {
    let givenDate = new Date(date);
    let dayOfMonth = givenDate.getDate();

    return dayOfMonth;
  };

  function getDayOfWeek(day) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    return daysOfWeek[day]; // Get the day of the week as a string
  }

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    // function getNextQuarterDate() {
    //   let requestDate = new Date(taskDetail?.firstRequest_date);
    //   let EndDate = new Date(taskDetail?.enddate);
    //   // Ensure the input date is valid and the end date is after the start date
    //   if (requestDate >= EndDate) {
    //     console.log("End date should be greater than the request date.");
    //     return null;
    //   }

    //   // Clone the request date to avoid modifying the original date
    //   let currentDate = new Date(requestDate);

    //   // Add 3 months (next quarter) to the current date
    //   currentDate.setMonth(currentDate.getMonth() + 3);

    //   // Check if the next quarter date is greater than or equal to the end date
    //   if (currentDate >= EndDate) {
    //     return currentDate; // Return the next quarter date
    //   }

    //   setNextDate(currentDate);

    //   return null; // If the next quarter date doesn't match or surpass the end date, return null
    // }

    function getNextDate() {
      let requestDate = new Date(taskDetail?.firstRequest_date);
      let EndDate = new Date(taskDetail?.enddate);
      let intervalType = taskDetail?.frequency;

      // Check if the request date is valid and if the end date is after the request date
      if (requestDate >= EndDate) {
        console.log("End date should be greater than the request date.");
        return null;
      }

      // Clone the request date to avoid modifying the original date
      let currentDate = new Date(requestDate);

      // Set the interval in months based on the interval type
      let intervalInMonths;
      switch (intervalType?.toLowerCase()) {
        case "quarterly":
          intervalInMonths = 3; // 3 months for quarterly
          break;
        case "semi annual":
          intervalInMonths = 6; // 6 months for semi-annual
          break;
        case "annual":
          intervalInMonths = 12; // 12 months for annual
          break;
        default:
          console.log("Invalid interval type");
          return null;
      }

      // Add the interval to the current date
      currentDate.setMonth(currentDate.getMonth() + intervalInMonths);

      // Check if the new date is greater than or equal to the end date
      if (currentDate >= EndDate) {
        return currentDate; // Return the calculated next date
      }

      setNextDate(currentDate);
      // return null;  // If the next calculated date doesn't match or surpass the end date, return null
    }

    getNextDate();
  }, [taskDetail]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="detail-card">
      <div className="main-wrapper">
        <h3 className="num fw_6 font_l font_grey">
          {taskTab ? "Task No." : "Ticket No."}{" "}
          <span className="font_blue">
            {taskTab ? taskDetail?.task_id : taskDetail?.ticket_id}
          </span>
        </h3>

        {taskDetail?.status === "Active" &&
          taskDetail?.requester?._id === user?._id && (
            <div className="d-flex justify-content-between gap-2">
              <div
                className="d-flex justify-content-between gap-2 pe-auto"
                onClick={() => navigate(`edit`)}
              >
                <IconButton className="action-button">
                  <img src={EditGreen} alt="Edit" className="h_100" />
                </IconButton>
                <span className="font_red fw_6 font_l">Edit</span>
              </div>
            </div>
          )}
      </div>

      <p className="summary font_m font_grey fw_4 my-2">
        Subject
        <br />
        <span className="fw_4 font_l">
          {taskTab ? taskDetail?.subject : taskDetail?.routine_id?.subject}
        </span>
      </p>

      <p className="summary font_m font_grey fw_5 my-2">
        Description
        <br />
        <span className="fw_6 font_l">
          {taskTab
            ? taskDetail?.description
            : taskDetail?.routine_id?.description}
        </span>
      </p>

      {tab === "task" && (
        <>
          <p className="summary font_m font_grey fw_4 my-2">
            Number Requests Generated:
            <br />
            <span className="fw_6 font_l">
              {taskTab
                ? taskDetail?.numberOfTickets
                : taskDetail?.routine_id?.numberOfTickets}
            </span>
          </p>

          <hr />
        </>
      )}
      {tab === "task" && (
        <>
          <div className="date-wrapper">
            <div>
              <span className="font_m fw_3 font_grey">Start Date</span>
              <br />
              <span className="font_m fw_3">
                {taskTab
                  ? customMoment(
                      startDate,
                      DATE_FORMAT,
                      true,
                      taskDetail?.property_offset
                    )
                  : customMoment(
                      startDate,
                      DATE_FORMAT,
                      true,
                      taskDetail?.property_offset
                    )}
              </span>
            </div>

            <div>
              <span className="font_m fw_3 font_grey">End Date</span>
              <br />
              <span className="font_m fw_3">
                {taskTab
                  ? customMoment(
                      endDate,
                      DATE_FORMAT,
                      true,
                      taskDetail?.property_offset
                    )
                  : customMoment(
                      endDate,
                      DATE_FORMAT,
                      true,
                      taskDetail?.property_offset
                    )}
              </span>
            </div>

            <div>
              <span className="font_m fw_3 font_grey">Type</span>
              <br />
              <span className="font_m fw_3">
                {taskTab ? taskDetail?.type : taskDetail?.routine_id?.type}
              </span>
            </div>
          </div>
          <hr />
        </>
      )}
      {tab === "task" && (
        <div className="location-wrapper">
          {tab === "task" && (
            <div>
              <span className="font_m fw_3 font_grey">Frequency</span>
              <br />{" "}
              <span className="font_m fw_6 font_blue">
                {taskTab
                  ? taskDetail?.frequency
                  : taskDetail?.routine_id?.frequency}
              </span>
            </div>
          )}

          {taskTab && tab === "task" && (
            <div>
              <span className="font_m fw_3 font_grey">Time Of Day</span>
              <br />{" "}
              <span className={`font_m fw_6 font_blue`}>
                {taskTab
                  ? taskDetail?.daily_time
                  : taskDetail?.routine_id?.daily_time}
              </span>
            </div>
          )}

          {!taskTab && tab === "task" && (
            <div>
              <span className="font_m fw_3 font_grey">Task Id</span>
              <br />{" "}
              <span className={`font_m fw_6 font_blue`}>
                {taskDetail?.routine_id?.task_id}
              </span>
            </div>
          )}

          {taskDetail?.weekly_days && tab === "task" && (
            <div>
              <span className="font_m fw_3 font_grey">Weekly At</span>
              <br />{" "}
              <span className={`font_m fw_6 font_blue`}>
                {getDayOfWeek(taskDetail?.weekly_days)}
              </span>
            </div>
          )}

          {taskDetail?.monthly_date && tab === "task" && (
            <div>
              <span className="font_m fw_3 font_grey">Monthly At</span>
              <br />{" "}
              <span className={`font_m fw_6 font_blue`}>
                {getDayFromTheDay(taskDetail?.monthly_date)}
              </span>
            </div>
          )}

          {taskDetail?.firstRequest_date && tab === "task" && (
            <>
              <div>
                <span className="font_m fw_3 font_grey">First Request</span>
                <br />{" "}
                <span className={`font_m fw_6 font_blue`}>
                  {customMoment(
                    taskDetail?.firstRequest_date,
                    DATE_FORMAT,
                    true,
                    taskDetail?.property_offset
                  )}
                </span>
              </div>

              <div>
                <span className="font_m fw_3 font_grey">Next Request</span>
                <br />{" "}
                <span className={`font_m fw_6 font_blue`}>
                  {/* {getNextCronDate(
                  taskDetail?.firstRequest_date,
                  taskDetail?.frequency
                )} */}
                  {moment(nextDate).format(DATE_FORMAT)}
                </span>
              </div>
            </>
          )}
        </div>
      )}
      <hr />
      <div className="location-wrapper">
        <div>
          <span className="font_m fw_3 font_grey">Status</span>
          <br />{" "}
          <span
            className={`font_m fw_5 ${
              taskDetail?.status === "Active"
                ? "font_green"
                : taskDetail?.status === "Open"
                ? "font_yellow"
                : "font_red"
            } `}
          >
            {taskDetail?.status}
          </span>
        </div>
      </div>

      <hr />

      {taskTab && taskDetail?.documents.length > 0 && (
        <>
          <div className="image-wrapper">
            {taskDetail?.documents.map((image, index) => (
              <div className="proof-img" key={index}>
                <Image
                  key={index}
                  className="media"
                  imgClass="thumbnail"
                  src={image?.url}
                  videoThumbnail={image?.type2 === "video"}
                  alt="Attatched Media"
                  objectFit="cover"
                  onClick={() => {
                    setFile(image);
                    setShowFileViewer(true);
                  }}
                />
              </div>
            ))}
          </div>

          <hr />
        </>
      )}

      {!taskTab && taskDetail?.routine_id?.documents.length > 0 && (
        <>
          <div className="image-wrapper">
            {taskDetail?.routine_id?.documents.map((image, index) => (
              <div className="proof-img" key={index}>
                <Image
                  key={index}
                  className="media"
                  imgClass="thumbnail"
                  src={image?.url}
                  videoThumbnail={image?.type2 === "video"}
                  alt="Attatched Media"
                  objectFit="cover"
                  onClick={() => {
                    setFile(image);
                    setShowFileViewer(true);
                  }}
                />
              </div>
            ))}
          </div>

          <hr />
        </>
      )}

      <div className="ticket-wrapper">
        <p className="font_m fw_4 font_grey">
          Ticket Requester{" "}
          <span className="font_m fw_3">
            {taskDetail?.assigned !== null
              ? `${taskDetail?.assigned?.first_name}${" "}
            ${taskDetail?.assigned?.last_name} `
              : "NA"}
          </span>
        </p>
      </div>
      <div className="last-update bg_black">
        <p className="font_m fw_3 font_white">
          Created Date{" "}
          <span className="font_s fw_4 font_white">
            {moment(taskDetail?.createdAt).format(DATE_TIME_FORMAT)}
          </span>
        </p>
      </div>

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default DetailCard;
