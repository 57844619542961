/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useNavigate } from "react-router-dom";
import { navigateToInvoiceByType } from "../helper";
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getIsACC,
  getIsOwner,
} from "../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  EyeFilledBlue,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import PaymentModal from "../../../Invoicing/ClientInvoicing/PaymentModal/PaymentModal";
/* Import local pages and component ends */

/* Component starts */
const ApplicationFee = (props) => {
  /* Props destructuring starts */
  const {
    index,
    invoiceDetails,
    propertySettings,
    property,
    getApplication,
    fromExisiting = false,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentLocation, setCurrentLocation] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [showModal, setShowModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const location = useLocation();
  const isAccountant = useSelector(getIsACC);
  const isOwner = useSelector(getIsOwner);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handlePaymentModal = (item) => {
    setInvoiceData(item);
    setShowModal(!showModal);
  };

  const onClose = () => {
    setShowModal(!showModal);
  };

  const onSuccess = () => {
    setShowModal(!showModal);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (location) {
      let path = location?.pathname?.split("/");
      console.log("Splitted pathname -> ", path)
      setCurrentLocation(path[5]);
    }
  }, [location]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="application-wrapper">
        <div className="application-heading">
          <p className="font_l label">
            {" "}
            {index !== undefined ? `${index} ${"-"}` : ""} Application Fee
            Status
          </p>
        </div>

        {/* ID Verfication Table */}
        <div className="custom-table-wrapper">
          {invoiceDetails !== "NA" ? (
            <Table className="custom-table font_s" borderless responsive>
              {/* Table Heading */}
              <thead>
                <tr>
                  {((propertySettings?.collect_tax_enabled &&
                    invoiceDetails?.tax_amount > 0) ||
                    (propertySettings?.collect_vat_enabled &&
                      invoiceDetails?.vat_amount > 0)) && <th></th>}
                  <th>Amount ({invoiceDetails?.currency}) </th>
                  <th>Status</th>
                  <th>View</th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                <tr>
                  {((propertySettings?.collect_tax_enabled &&
                    invoiceDetails?.tax_amount > 0) ||
                    (propertySettings?.collect_vat_enabled &&
                      invoiceDetails?.vat_amount > 0)) && (
                    <td>
                      <p className="font_xs">Amount</p>
                      {propertySettings?.collect_tax_enabled &&
                        invoiceDetails?.tax_amount > 0 && (
                          <p className="font_xs">
                            TAX ({propertySettings?.collect_tax_value}%)
                          </p>
                        )}
                      {propertySettings?.collect_vat_enabled &&
                        invoiceDetails?.vat_amount > 0 && (
                          <p className="font_xs">
                            VAT ({propertySettings?.collect_vat_value}%)
                          </p>
                        )}
                      <p>Total</p>
                    </td>
                  )}

                  {/* Amount */}
                  <td>
                    {invoiceDetails?.amount}
                    {((propertySettings?.collect_tax_enabled &&
                      invoiceDetails?.tax_amount > 0) ||
                      (propertySettings?.collect_vat_enabled &&
                        invoiceDetails?.vat_amount > 0)) && (
                      <>
                        {propertySettings?.collect_tax_enabled &&
                          invoiceDetails?.tax_amount > 0 && (
                            <p className="font_xs">
                              {invoiceDetails?.tax_amount?.toFixed(2)}
                            </p>
                          )}
                        {propertySettings?.collect_vat_enabled &&
                          invoiceDetails?.vat_amount > 0 && (
                            <p className="font_xs">
                              {invoiceDetails?.vat_amount?.toFixed(2)}
                            </p>
                          )}
                        <p className="font_xs">
                          {invoiceDetails?.total_amount?.toFixed(2)}
                        </p>
                      </>
                    )}
                  </td>
                  {/* Status */}
                  <td
                    className={`${
                      invoiceDetails?.status === "paid"
                        ? "font_green"
                        : invoiceDetails?.status === "adjusted"
                        ? "font_yellow"
                        : "font_red"
                    }`}
                  >
                    {invoiceDetails?.status === "paid" && "Paid"}
                    {invoiceDetails?.status === "partial paid" &&
                      "Partial Paid"}
                    {invoiceDetails?.status === "unpaid" && "Unpaid"}
                    {invoiceDetails?.status === "void" && "Void"}
                    {invoiceDetails?.status === "adjusted" && "Adjusted"}
                  </td>

                  <td>
                    <IconButton
                      onClick={() =>
                        navigateToInvoiceByType(
                          navigate,
                          invoiceDetails?.type,
                          invoiceDetails,
                          currentLocation,
                          location,
                          fromExisiting
                        )
                      }
                    >
                      <img src={EyeFilledBlue} className="icon-m" alt="View" />
                    </IconButton>

                    {((isOwner && property?.mgmt_model === 0) ||
                      (isAccountant && property?.mgmt_model === 2)) &&
                      (invoiceDetails?.status === "unpaid" ||
                        invoiceDetails?.status === "partial paid") &&
                      !invoiceDetails?.is_payable_by_cheque && (
                        <IconButton
                          onClick={() => handlePaymentModal(invoiceDetails)}
                        >
                          <img
                            // src={EditWhite}
                            src={EditGreen}
                            className="icon-m mx-1"
                            alt="View Details"
                          />
                        </IconButton>
                      )}
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <div className=" text-center">
              <h3 className=" font_m font_yellow fw_6">No Data</h3>
            </div>
          )}
        </div>
      </div>

      {/* MODALS */}
      <PaymentModal
        showModal={showModal}
        onClose={onClose}
        onSuccess={onSuccess}
        invoiceData={invoiceData}
        property_id={property?._id}
        afterPayment={() => {
          getApplication();
        }}
      />
    </>
  );
};
/* Component ends */

export default ApplicationFee;
